// import React from 'react'

// function ForeignWorkerPremiumAmount() {
//   return (
//     <div>ForeignWorkerPremiumAmount</div>
//   )
// }

// export default ForeignWorkerPremiumAmount

import ReusableCard from 'components/cards/ResuableCard';
import React from 'react';
import { Grid, Button, TextField, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

const ForeignWorkerPremiumAmount = ({ handleNextButton, handlePreviousButton }) => {
  const navigate = useNavigate();
  const keysforplan = Object.keys(planDetail);
  const valuesforplan = Object.values(planDetail);

  return (
    <div>
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'1%'}
        boxShadow={' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <Grid container p={2}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '500' }}>Premium Amount</span>
              {/* <Button
                size="small"
                variant="contained"
                style={{ backgroundColor: '#002D8C', width: 'auto' }}
                onClick={() => {
                  navigate('/DomesticHelper');
                }}
              >
                Change Plan
              </Button> */}
            </Grid>

            <Grid container spacing={2} xs={12} mt={5}>
              <Grid item xs={11}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#002d8c', fontWeight: '600' }}>Add-on total (S$)</span>
              </Grid>
              <Grid item xs={1}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#002d8c', fontWeight: '600' }}>S$425.10</span>
              </Grid>
              <Grid item xs={12}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#9fa1a9' }}>
                  Total Payable Amount (S$) (after 10% online discount)
                </span>
              </Grid>
              <Grid item xs={2}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#9fa1a9' }}>S$824.04</span>
              </Grid>
            </Grid>
            <hr />

            <Grid container spacing={1} xs={12} style={{ border: '2px solid  #002d8c', marginTop: '5%', borderRadius: '10px' }}>
              <Grid item xs={11}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600' }}>Premium W/O GST</span>
              </Grid>
              <Grid item xs={1}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600' }}>$425.10</span>
              </Grid>
              <Grid item xs={11}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600' }}>Add-on total</span>
              </Grid>
              <Grid item xs={1}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600' }}>$25.10</span>
              </Grid>
              <Grid item xs={11}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600' }}>GST</span>
              </Grid>
              <Grid item xs={1}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600' }}>$5.10</span>
              </Grid>
              <Grid item xs={11}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600' }}>Premium with GST</span>
              </Grid>
              <Grid item xs={1}>
                <span style={{ fontFamily: 'Medium', fontSize: '14px', fontWeight: '600' }}>$555.30</span>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#e4e4e4', width: '100%', padding: '3%', color: '#000000' }}
                    onClick={handlePreviousButton}
                  >
                    <IconButton style={{ marginRight: '5px' }}>
                      <WestIcon />
                    </IconButton>
                    Previous
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff' }}
                    onClick={handleNextButton}
                  >
                    Next
                    <IconButton style={{ marginLeft: '5px', color: '#ffffff' }}>
                      <EastIcon />
                    </IconButton>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default ForeignWorkerPremiumAmount;

const planDetail = { 'Plan Premium': 'Basic Plan (With Co-Payment)', '26 months': '$490.50', '14 Months': '$367.88' };
