import React from 'react';
import { Grid, Typography } from '@mui/material';
import { HorizontalContainer } from './HorizontalContainer';

export default function CalculateContainer() {
    const ContainerData = [
        { heading1: 'Heading 1', heading2: 'STANDARD', text1: 'Text 1', text2: '$183.12 - With 25% Co-Payment above $15000' },
        { heading1: 'Heading 1', heading2: 'ULTIMATE', text1: 'Text 1', text2: '$281.22 - Without Co-Payment' }
    ];

    return (
        <Grid container justifyContent="center" alignItems="center" mt={2}>
            <Grid item xs={11.5} md={9} lg={9}>
                <Grid container style={{ width: '100%', backgroundColor: '#F2F9FF', borderRadius: 20, padding: '20px', boxShadow: "2px 2px 2px 2px #F7F7F8" }}>
                    <Typography variant="h5" style={{ fontFamily: 'Bold', fontSize: 20, padding: 5 }}>
                        Get your quote
                    </Typography>
                    {ContainerData.map((data, index) => {
                        let obj = { 'Choice of plan': data.heading2, 'Premium inclusive of GST(One Year)': data.text2 };
                        return <HorizontalContainer key={index} data={obj} sizes={[3, 6]} />;
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}
