import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DomesticHelperPlansTable from 'modules/customer/domestic helper policy view/components/DomesticHelperPlansTable';

const faqsData = [
  {
    heading: 'What should I do immediately after a motor accident?',
    description: 'The Motor Claims Framework (MCF) outlines clear steps to follow:',
    point1: '1. Exchange particulars of parties involved and note vehicle numbers',
    point2: '2. Take photos of the accident scene and vehicle damages.',
    point3: "3. Call your insurer's hotline for a tow-truck or further device"
  },
  {
    heading: 'When Should I involve authorities?',
    description: 'Call the police immediately if there are injuries,fatalities, or specific circumstances like hit-and-run'
  },
  {
    heading: "What happens if I don't report the accident to my insurer?",
    description: 'Consequences include liabilities issues,loss or No-Claims Discount, or policy cancellation.'
  },
  {
    heading: 'What actions should I would after an accident?',
    description: "Don't move vehicles before photos,attempt to move injured persons, or engage way unauthorized tow-truck operators."
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3, display: 'flex' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export default function ProductDetailVerticalTab() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ minWidth: { xs: 0, sm: '250px' } }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab
          label="Coverages"
          sx={{
            bgcolor: value === 0 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 0 ? '#05318e' : '#86878c'
          }}
          {...a11yProps(0)}
        />
        <Tab
          label="Product Details"
          {...a11yProps(1)}
          sx={{
            bgcolor: value === 1 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 1 ? '#05318e' : '#86878c'
          }}
        />
        <Tab
          label="FAQs"
          {...a11yProps(3)}
          sx={{
            bgcolor: value === 3 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            color: value === 3 ? '#05318e' : '#86878c'
          }}
        />
      </Tabs>
      <Box sx={{ flex: '1', overflowY: 'auto', padding: { xs: '0', sm: '10px' } }}>
        <TabPanel value={value} index={0}>
          <div>
            <DomesticHelperPlansTable width={'100%'} />
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#002D8C', width: '35%', height: '30px', padding: '3%', color: '#ffffff', marginTop: '5%' }}
              // onClick={handlePreviousButton}
              // onClick={handleNextButton}
            >
              <FileDownloadIcon />
              <Typography style={{ padding: '3%', color: '#ffffff' }}>Download Coverages</Typography>
            </Button>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>Foreign Workers</Typography>
          <Typography variant="body1">
            We offer comprehensive and competitive travel insurance packages to suit your needs and budgets as an individual or family.
          </Typography>
        </TabPanel>

        <TabPanel value={value} index={2}>
          {faqsData.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>{item.heading}</Typography>
              <Typography style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.description}</Typography>
              <ol style={{ paddingLeft: '20px', marginTop: '5px', marginBottom: '5px', listStyleType: 'decimal' }}>
                {index === 0 && (
                  <>
                    <li style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.point1}</li>
                    <li style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.point2}</li>
                    <li style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.point3}</li>
                  </>
                )}
              </ol>
            </div>
          ))}
        </TabPanel>
      </Box>
    </Box>
  );
}
