import PropTypes from 'prop-types';
import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { useTheme } from 'components/Theme/ThemeProvider';
import { getUserDetails } from 'helper/utils';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const { currentTheme } = useTheme();
  const [userDetails, setUserDetails] = React.useState({});

  React.useEffect(() => {
    (async () => {
      let d = await getUserDetails();
      setUserDetails(d);
    })();
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
        <Avatar alt={userDetails?.fullName} src={userDetails?.profileUrl} sx={{ width: 32, height: 32 }} />
        <Typography variant="subtitle1" sx={{ color: currentTheme.textColor }}>
          {userDetails?.fullName}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Profile;
