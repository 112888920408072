

import ReusableCard from 'components/cards/ResuableCard';
import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { useSelector } from 'react-redux';
import { DomesticCustomerEmployeeInformation } from 'modules/packages & premium/static';
import { store } from 'store/index';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import CustomerLoginModal from 'modules/customer/corporate/customerlandingpage/components/CustomerLoginModal';
import CustomerResetPassword from 'modules/customer/customerpasswordreset/CustomerResetPassword';
import CustomerOtpModal from 'modules/customer/customerOTP/CustomerOtpModal';
import OTPResetModal from 'components/modals/contentmodal/OTPResetModal';

const RenderFunction = ({ item, index, otherState, verificationMessage, dropdownData }) => {

  const [value, setValue] = useState(otherState[item?.value]);

 

  const onChangeText = (txt, value) => {

    try {
      store.dispatch(addOtherIndividualState([value, txt]));
      setValue(txt);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid item xs={12} md={4} key={index}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <InputLabel className="inputs_styless_">{item.label}</InputLabel>
        {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-10px' }}>*</div>}
      </div>

      {item.type === 'textField' ? (
        <TextField
          type={item?.texttype}
          disabled={item?.value == 'uen' && otherState['navigateFrom'] == 'renewal' ? true : item?.isEnable}
          onChange={(txt) => onChangeText(txt.target.value, item?.value)}
          required={item?.isRequired}
          size="small"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          className="txtinputs_stylingg"
          value={value}
          style={{ marginTop: '13px' }}
          inputProps={{
            pattern: /^\d{4}$/,
            min: new Date().toISOString().split('T')[0]
          }}
        />
      ) : (
        <></>
      )}
    </Grid>
  );
};

const DomesticHelperPolicyAddOnForm = React.memo(({ handleNextButton, handlePreviousButton }) => {
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [getUpdatedCountriesListData, setUpdatedCountriesListData] = useState([]);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [resetShow, setResetShow] = useState(false);
  const [otpshow, setOtpShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [emailId, setEmailId] = useState('');
  // const [value, setValue] = useState(otherState[item?.value]);

  const navigate = useNavigate();
  const { makeRequestWithoutLogin } = useApiCall();
  const network = useNetworkStatus();

  const handleCancelButton = () => {
    navigate('/DomesticHelper');
  };

  const handleProceedPayment = () => {
    // navigate('/DomesticHelper');
    let userInformation = localStorage.getItem('@user');
    if (userInformation === null) {
      setShow(true);
    } else {
      setShow(false);
      alert('Redirected To Payment Page!');
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleResetPasswordModalOpen = () => {
    setResetShow(true);
    setShow(false);
  };

  const handleCloseResetModal = () => {
    setIsOpen(false);
    setShow(true);
  };

  const handleOTP = ({ OTP, UserId, emailId }) => {
    setEmailId(emailId);
    setUserId(UserId);
    setResetShow(false);
    setOtpShow(true);
  };

  const handleVerifyOtp = () => {
    setOtpShow(false);
    setIsOpen(true);
  };

  const handleClickOnResendOTP = async () => {
    if (network) {
      try {
        let params = {
          UserId: userId,
          emailId: emailId
        };
        let res = await makeRequestWithoutLogin({ method: 'POST', url: API.PasswordChangeOTPSent, params });
        if (res?.code == 200) {
          showSuccess('OTP sent successfully');
        } else {
          showWarning(res?.message);
        }
      } catch (e) {
        showDanger(`${e}`);
      }
    } else {
      showDanger('No internet');
    }
  };
  useEffect(() => {
    setData(DomesticCustomerEmployeeInformation);
  }, []);

  return (
    <>
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'5%'}
        boxShadow={'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <Grid container spacing={2}>
            {data.map((item, index) => (
              <RenderFunction
                item={item}
                index={index}
                setVerificationMessage={setVerificationMessage}
                verificationMessage={verificationMessage}
                otherState={otherState}
                dropdownData={getUpdatedCountriesListData}
              />
            ))}
            <hr />

            <Grid item xs={12}>
              <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
                <Grid item xs={6} sm={6} md={6} lg={5}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#e4e4e4', width: '100%', color: '#000000' }}
                    onClick={handlePreviousButton}
                  >
                    <IconButton style={{ marginRight: '5px', color: '#000000' }}>
                      <WestIcon />
                    </IconButton>
                    cancel
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={7}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#002D8C', width: '100%', padding: '7%', color: '#ffffff' }}
                    // onClick={handlePreviousButton}
                    onClick={handleProceedPayment}
                  >
                    Proceed To Payment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />

      <CustomerLoginModal
        isOpen={show}
        setShow={setShow}
        onClose={handleClose}
        handleResetPasswordModalOpen={handleResetPasswordModalOpen}
      />
      <CustomerResetPassword isOpen={resetShow} onClose={() => setResetShow(false)} handleOTP={handleOTP} />
      <CustomerOtpModal
        isOpen={otpshow}
        onClose={() => setOtpShow(false)}
        handleVerifyOtp={handleVerifyOtp}
        handleClickOnResendOTP={handleClickOnResendOTP}
      />
      <OTPResetModal isOpen={isOpen} handleCloseModal={handleCloseResetModal} />
    </>
  );
});

export default DomesticHelperPolicyAddOnForm;
