import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const AutoCompleteDropdown = (props) => {
  const { options, value, label, width, height, borderRadius, onChange, marginTop } = props;

  // useEffect(() => {
  //   if (value && options && options.length > 0) {
  //     const selectedItem = options.find(option => option.id === value);
  //     if (selectedItem) {
  //       // Do something if selectedItem is found
  //     } else {
  //       // Do something else if selectedItem is not found
  //     }
  //   }
  // }, [value, options]);
  useEffect(() => {
    // If you need to perform any actions based on value or options, add the logic here
  }, [value, options]);

  const handleOnChange = (event, selectedValue) => {
    onChange(selectedValue);
  };

  const getOptionLabel = (option) => option.label || '';

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 40, marginTop: marginTop, cursor: 'pointer' }}>
      <Autocomplete
        size="small"
        popupIcon={<KeyboardArrowDownIcon sx={{ color: '#32385C' }} />}
        onChange={handleOnChange}
        options={options}
        getOptionLabel={getOptionLabel}
        style={{ width: width || 220, color: '#A79FA4' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || 'Select an option'}
            fullWidth
            sx={{
              '& .css-1q60rmi-MuiAutocomplete-endAdornment': {
                marginTop: -0.5
              },
              '& .css-1hfulzr-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: 12.5,
                fontFamily: 'Medium',
                fontWeight: 600,
                color: '#A79FA4',
                marginTop: 0.4
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.Mui-focused.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot': {
                border: 'none'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important'
              }
            }}
            InputProps={{
              ...params.InputProps,
              focused: false,
              style: {
                height: height || '33px',
                borderRadius: borderRadius || '10px',
                backgroundColor: '#f8f8f8',
                color: '#32385C',
                marginTop: 0
              }
            }}
          />
        )}
        value={options.find(option => option.id === value) || null}
      />
    </div>
  );
};

AutoCompleteDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AutoCompleteDropdown;
