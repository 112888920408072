import PropTypes from 'prop-types';
import React, { forwardRef} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemButton, ListItemIcon, ListItemText,  Typography } from '@mui/material';
import { activeItem } from 'store/reducers/menu';
import { showDanger, showSuccess } from 'components/nudges/Alert';
import { updateAgendAuth } from 'store/reducers/auth';
import { setFlag } from 'store/reducers/premiumPolicy';

const NavItem = ({ item, level }) => {
  const dispatch = useDispatch();
  const { drawerOpen, openItem } = useSelector((state) => state.persistedReducer.menu);

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = async (id) => {
    if (id == 'logout') {
      try {
        localStorage.removeItem('@user');
        localStorage.removeItem('@login');
        localStorage.removeItem('AppToken');
        showSuccess('Logout successfully');
        dispatch(updateAgendAuth({ status: false, data: {} }));
        dispatch(activeItem({ openItem: ['dashboard1'] }));
        dispatch(setFlag(false));
      } catch (e) {
        showDanger(e);
      }
    } else {
      dispatch(activeItem({ openItem: [id] }));
    }
  };

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;
  const isSelected = Array.isArray(openItem) && openItem.findIndex((id) => id === item.id) > -1;
  const textColor = '#B3B4B7';
  const iconSelectedColor = '#FFF';

  return (
    <>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        onClick={() => {
          itemHandler(item.id);
        }}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          marginLeft: 1.4,
          pl: drawerOpen ? `${level * 28}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: '#FFF'
            },
            '&.Mui-selected': {
              bgcolor: '#00C7B1',
              borderRadius: '10px',
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor,
                bgcolor: '#00C7B1'
              }
            }
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent'
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent'
              },
              bgcolor: 'transparent'
            }
          })
        }}
      >
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: isSelected ? iconSelectedColor : textColor,
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: 'secondary.lighter'
                }
              }),
              ...(!drawerOpen &&
                isSelected && {
                  bgcolor: 'primary.lighter',
                  '&:hover': {
                    bgcolor: 'primary.lighter'
                  }
                })
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{ color: isSelected ? iconSelectedColor : textColor }}
                style={{ fontSize: 14, fontFamily: 'Medium' }}
              >
                {item.title}
              </Typography>
            }
          />
        )}
      </ListItemButton>
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;