import React, { useEffect, useState } from 'react';
import ResponsiveAppBar from '../../corporate/customerlandingpage/components/NavBar';
import { Grid } from '@mui/material';
import domestic_helper from '../../../../assets/images/OneDrive_2024-05-10/png file/ANDA_ProductBanner_DomesticWorker.jpg';
import ProductDetailVerticalTab from '../components/ProductDetailVerticalTab';
import ImageCard from '../../corporate/customerlandingpage/components/ImageCard';
import Footer from '../../corporate/customerlandingpage/components/Footer';
import DomesticCalculateContainer from '../components/DomesticCalculateContainer';
import useProductPlansData from 'modules/customer/corporate/customerlandingpage/hooks/useProductPlansData';
import DomesticHelperPolicyCoverage from 'modules/customer/domestic helper policy view/pages/DomesticHelperPolicyCoverage';
import { useNavigate } from 'react-router-dom';

const DomesticHelper = () => {
  const tsaTypeParam = 'Domestic Helper';
  const { data, isLoading, error } = useProductPlansData(tsaTypeParam);
  const [showDomesticHelperForm, setShowDomesticHelperForm] = useState(false);
  const [addOnList, setAddOnList] = useState([]);
  const navigate = useNavigate();

  const handleCalculatePremium = () => {
    try {
      setShowDomesticHelperForm(true);

      
    } catch (error) {
      console.error(error);

    }
  };

  const handleGoBack = () => {
    setShowDomesticHelperForm(false);
   
  };

  useEffect(() => {
    data?.map((item, index) => {
      setAddOnList(item?.customerProductList);
    });
  }, [data]);

  return (
    <Grid container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ResponsiveAppBar />
      <div style={{ position: 'relative', width: '100%', height: '35vh' }}>
        <img src={domestic_helper} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '35%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: 1
          }}
        >
          <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium', marginBottom: '5px' }}>Domestic Helper</p>
          <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>eProduct: Helper Guardian Assurance</p>
        </div>
      </div>

      <div style={{ position: 'relative', zIndex: 2, marginTop: '-60px', width: '100%' }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={9} lg={10} mb={3}>
            {showDomesticHelperForm ? (
              <DomesticHelperPolicyCoverage addOnList={addOnList} handleGoBack={handleGoBack} />
            ) : (
              <DomesticCalculateContainer data={data} handleCalculatePremium={handleCalculatePremium} />
            )}
          </Grid>
        </Grid>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60%', marginTop: '5%', height: 'auto' }}>
        <ProductDetailVerticalTab />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '5%' }}>
        <ImageCard />
      </div>
      <Grid item xs={12} md={7} lg={12} mt={4} sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default DomesticHelper;
