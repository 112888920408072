import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, FormHelperText, Grid, Link, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'components/@extended/AnimateButton';
import { useNavigate } from 'react-router-dom';
import { API } from 'api/API';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';
import useApiCall from 'api/useApiCall';
import logo from '../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';
import OverlayLoader from 'components/loader/OverlayLoader';

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const network = useNetworkStatus();
  const { makeRequest } = useApiCall();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <OverlayLoader show={loading} />

      <Formik
        initialValues={{
          UserId: '',
          emailId: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          UserId: Yup.string().max(255).required('User Id is required'),
          emailId: Yup.string().email('Must be a valid Email adress').max(255).required('email is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {


          try {
            if (!network) {
              showDanger('No internet connection.');
              return;
            } else {
              setLoading(true);

              let params = {
                UserId: values.UserId,
                emailId: values.emailId
              };
              let res = await makeRequest({ method: 'POST', url: API.PasswordChangeOTPSent, params });
              if (res?.code == 200) {
                showSuccess('OTP sent successfully');
                setLoading(false);
                navigate('/verifyotp', { state: { data: { OTP: res, UserId: values?.UserId, emailId: values?.emailId } } });
              } else {
                setLoading(false);
                showWarning(res?.message);
              }
            }
          } catch (e) {
            setLoading(false);
            showDanger(`${e}`);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} xs={12} alignItems="center" justifyContent="center">
              <Grid item xs={12} justifyContent={'end'}>
                <img
                  src={logo}
                  alt="Anda Logo"
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: 'auto',
                    maxWidth: '12rem',
                    marginBottom: '2%',
                    alignItems: 'left'
                  }}
                />
                <p style={{ fontFamily: 'Thin', fontSize: 15, color: '#CACACB', fontWeight: 700, marginBottom: '3px' }}>Reset Password </p>

                <Grid item xs={12} sx={{ marginTop: '8px' }}>
                  <Stack spacing={1}>
                    <OutlinedInput
                      sx={{
                        width: '18rem'
                      }}
                      id="email-login"
                      name="UserId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Registered User ID"
                      error={Boolean(touched.UserId && errors.UserId)}
                    />
                    {touched.UserId && errors.UserId && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.UserId}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '-10px' }}>
                <Stack spacing={1}>
                  <OutlinedInput
                    sx={{
                      width: '18rem'
                    }}
                    id="email-login"
                    name="emailId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Registered Email ID"
                    error={Boolean(touched.emailId && errors.emailId)}
                  />
                  {touched.emailId && errors.emailId && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.emailId}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12} sx={{ width: '100%', alignItems: 'center', display: 'flex' }}>
                <AnimateButton>
                  <Button
                    sx={{
                      width: '18rem'
                    }}
                    style={{ backgroundColor: '#002d8c', fontFamily: 'Medium' }}
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send OTP
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
