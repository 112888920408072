// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
 isAgendLogin:false,
 agent:{},
 isUserLogin:false,
 user:{},
};

// ==============================|| SLICE - MENU ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAgendAuth(state, action) {
      state.isAgendLogin = action.payload.status;
      state.agent = action.payload.data
    },
    updateUserAuth(state, action) {
      state.isUserLogin = action.payload.status;
      state.user = action.payload.data
    },
  }
});

export default auth.reducer;

export const { updateAgendAuth ,updateUserAuth} = auth.actions;
