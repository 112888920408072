import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { downloadPdf, getUserId, handleDownload } from 'helper/utils';
import { store } from 'store/index';
import { addActiveParams, removeAllRenewalData, removeOtherState } from 'store/reducers/premiumPolicy';
import { showDanger, showWarning } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';

export default function useGetPremiumTableData(onDownload) {
  const { makeRequest } = useApiCall();
  const navigate = useNavigate();
  const network = useNetworkStatus();
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (!network) {
          showDanger('No internet connection.');
          return;
        }
        let UserId = await getUserId();
        let params = { UserId };
        let res = await makeRequest({ url: API.PackagesAndPremiumGet, params });
        if (res?.code == 200) {
          let arr = res?.data?.map((item) => ({
            sr: `${item?.srNo}`,
            Pn: `${item?.productName}`,
            Action: (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    backgroundColor: '#4cb748',
                    color: '#fff',
                    borderRadius: 20,
                    fontSize: 12,
                    cursor: 'pointer',
                    width: '140px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '3px',
                    fontFamily: 'Medium'
                  }}
                  onClick={() => {
                    store.dispatch(removeAllRenewalData());
                    store.dispatch(removeOtherState());
                    store.dispatch(addActiveParams(item));
                    navigate('/dashboard/PremiumCalculateView', { state: { data: { item } } });
                  }}
                >
                  <VisibilityIcon style={{ fontSize: '15px' }} />
                  Calculate Premium
                </div>
                <div
                  style={{
                    backgroundColor: '#0174BF',
                    color: '#fff',
                    borderRadius: 20,
                    fontSize: 12,
                    marginLeft: '10px',
                    cursor: 'pointer',
                    width: '90px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '3px',
                    fontFamily: 'Medium'
                  }}
                  onClick={() =>
                       downloadPdf({ url: item?.displayUrl, key: item?.productID })
                      // downloadPdf({ url: 'https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf', key: item?.productID })
                  }
                >
                  <SimCardDownloadOutlinedIcon style={{ fontSize: '15px' }} />
                  Download
                </div>
              </div>
            )
          }));
          setData(arr);
          setIsLoading(false);
        } else if (res?.code == 404) {
          setIsLoading(false);
          showWarning('No Data found');
        }
      } catch (e) {
        setIsLoading(false);
      }
    })();
  }, [network]);

  return [data, loading];
}
