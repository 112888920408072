export const successModalStyles = {
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '92%',
    padding: '5px',
    marginTop: 5
  },
  image: {
    width: '100%',
    maxWidth: '150px',
    maxHeight: '140px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '6%'
  },
  textContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '270px',
    margin: '15px 0px 15px 0px'
  },
  title: {
    fontFamily: 'Medium',
    fontSize: 15
  },
  subtitle: {
    marginTop: 5,
    fontFamily: 'Thin',
    fontSize: 13,
    color: '#b2bec3',
    fontWeight: '600'
  },
  button: {
    borderRadius: "5px",
    backgroundColor: '#002d8c',
    fontFamily:'Medium'
  }
};
