import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';
import { showDanger } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';

const DataChart = ({ colors, filterByProductSales }) => {
  const network = useNetworkStatus();
  const { makeRequest } = useApiCall();
  const [chartData, setChartData] = useState({ categories: [], series: [] });
  const [seriesNames, setSeriesNames] = useState([]);
  const [fullCategoryNames, setFullCategoryNames] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchChartData = useCallback(async () => {
    const loginData = localStorage.getItem('@login');

    const shortenName = (name, maxLength = 10) => {
      return name.length > maxLength ? `${name?.substring(0, maxLength)}...` : name;
    };

    if (loginData) {
      const loginDataObj = JSON.parse(loginData);
      const userMasterId = loginDataObj.userMasterId;
      try {
        const response = await makeRequest({
          method: 'GET',
          url: `${API.PolicyConversionDetailsGet}?UserId=${userMasterId}&filterBy=${filterByProductSales}`
        });
        if (response?.data) {
          const categories = response.data.categories;
          const processedData = {
            ...response.data,
            categories: response?.data?.categories?.map((category) => shortenName(category))
          };
          setChartData(processedData);
          setFullCategoryNames(categories);
          const names = response?.data?.series?.map((s) => s?.name);
          setSeriesNames(names);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [filterByProductSales]);

  useEffect(() => {
    if (!network) {
      showDanger('No internet connection.');
      setLoading(false);
      return;
    }
    fetchChartData();
  }, [fetchChartData, filterByProductSales]);

  const formatNumber = (number) => {
    if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + 'M';
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + 'k';
    } else {
      return number;
    }
  };

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: 365,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '55px',
        borderRadius: 4,
        distributed: false
      }
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#2f3640']
      },
      formatter: function (val) {
        return formatNumber(val);
      }
    },
    xaxis: {
      categories: chartData?.categories,
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false
      },
      scrollbar: {
        show: chartData?.categories?.length > 3,
        height: 10,
        borderColor: '#90A4AE',
        barBackgroundColor: '#90A4AE',
        barBorderColor: '#90A4AE',
        position: 'bottom',
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: seriesNames?.map((name, index) => ({
      show: true,
      title: {
        text: name
      },
      labels: {
        style: {
          fontFamily: 'Medium',
          fontSize: 13,
          color: '#76777D'
        }
      },
      opposite: index % 2 !== 0,
      min: 0
    })),
    grid: {
      show: true
    },
    tooltip: {
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex }) {
          return fullCategoryNames[dataPointIndex];
        }
      }
    },
    legend: {
      show: false
    }
  };

  const series = chartData?.series;

  return (
    <>
      {loading ? (
        <Grid item xs={12} sm={6} md={12} lg={12}>
          <Skeleton variant="rectangular" width="100%" height={350} animation="wave" style={{ borderRadius: '10px' }} />
        </Grid>
      ) : (
        <div
          id="chart"
          style={{
            overflowX: chartData?.categories?.length > 3 ? 'auto' : 'hidden',
            width: '100%',
            whiteSpace: 'nowrap',
            scrollbarColor: '#26A0FC #f0f0f0',
            scrollbarWidth: chartData?.categories?.length > 3 ? 'thin' : 'none'
          }}
        >
          <style>
            {`
            #chart::-webkit-scrollbar {
              height: 8px;
            }
            #chart::-webkit-scrollbar-track {
              background: #f0f0f0;
            }
            #chart::-webkit-scrollbar-thumb {
              background-color: #26A0FC;
              border-radius: 10px;
            }
          `}
          </style>
          <div style={{ width: chartData?.categories?.length > 3 ? chartData?.categories?.length * 150 + 'px' : '100%' }}>
            <ReactApexChart options={barChartOptions} series={series} type="bar" height={chartData?.categories?.length > 3 ? 340 : 350} />
          </div>
        </div>
      )}
    </>
  );
};

DataChart.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  filterByProductSales: PropTypes.string.isRequired
};

DataChart.defaultProps = {
  colors: ['#008FFB', '#00E396']
};

export default DataChart;
