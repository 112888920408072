import React from 'react';
import { Grid } from '@mui/material';
import '../styles/InformationForm.css';
import PremiumCard from './PremiumCard';
import MotorAmountCard from './motar/MotorAmountCard';


const MotorVehicleCalculationView = React.memo(() => {
  return (
    <Grid container xs={12} width="100%">
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <PremiumCard />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <MotorAmountCard />
      </Grid>
    </Grid>
  );
});

export default MotorVehicleCalculationView;
