import { useState, useEffect } from 'react';
import { API } from 'api/API';
import useApiCall from '../../../api/useApiCall';
import { usePackagePremiumState } from '../provider/PackagePremiumStateProvider';

const useFwDhCalculationGet = () => {
  const { makeRequest } = useApiCall();
  const [data, setData] = useState(null);
  const { params } = usePackagePremiumState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!params) return;
        let Params = { UserId: params.userId || params?.portalUserId, productMasterID: params?.productID || params?.productId };
        let response = await makeRequest({ method: 'GET', url: API.FWDHCalculationGet, params: Params });

        if (response.code === 200) {
          setData(response.data);
        } else {
        }
      } catch (error) {
        console.error("Error in fetchData"+error);

      }
    };
    fetchData();
  }, [params]);

  return { data };
};

export default useFwDhCalculationGet;
