import React from 'react';
import PlanPremiumTable from './PlanPremiumTable';
import { Grid, Button, TextField, IconButton, Typography, Link } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
const MotorVehiclePlansPremium = ({ handlePreviousButton }) => {
  return (
    <>
      <PlanPremiumTable width={'100%'} />
      <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
        <Grid item xs={8} sm={6} md={6} lg={5}>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: '#F7F7F7', width: '80%', padding: '2%', color: '#000000', height: '100%', border: '1px solid #ddd' }}
            onClick={handlePreviousButton}
          >
            <IconButton style={{}}>
              <WestIcon />
            </IconButton>
            Previous
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MotorVehiclePlansPremium;
