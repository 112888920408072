import React from 'react';
import { IconButton, Typography } from '../../../../../../node_modules/@mui/material/index';
import '../styles/NavBarSidebar.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navRoutesOptions } from 'store/reducers/menu';
import HomeIcon from '@mui/icons-material/Home';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import andaLOGO from '../../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.png';

const NavBarSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { navRoutesOption } = useSelector((state) => state.persistedReducer.menu);

  const handleOptionClick = (route) => {
    dispatch(navRoutesOptions({ navRoutesOption: route }));
    navigate(route);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%' }}>
        <img src={andaLOGO} alt="LOGO" style={{ width: '40%' }} />
      </div>
      <div className="text-containerrrr-navbarrrrSideBar">
        <Typography
          className={`optionsss-styling-navbarrrSideBar ${navRoutesOption === '/' ? 'selectedddd-route-text_sideBar' : ''}`}
          onClick={() => handleOptionClick('/')}
        >
          <IconButton className={`iconStyle-NavSideBar-NavBarrr ${navRoutesOption === '/' ? 'selectedddd-route-text_sideBar' : ''}`}>
            <HomeIcon />
          </IconButton>
          Home
        </Typography>
        <Typography
          className={`optionsss-styling-navbarrrSideBar ${navRoutesOption === '/DomesticHelper' ? 'selectedddd-route-text_sideBar' : ''}`}
          onClick={() => handleOptionClick('/DomesticHelper')}
        >
          <IconButton
            className={`iconStyle-NavSideBar-NavBarrr ${navRoutesOption === '/DomesticHelper' ? 'selectedddd-route-text_sideBar' : ''}`}
          >
            <WheelchairPickupIcon />
          </IconButton>
          Domestic Helper
        </Typography>

        <Typography
          className={`optionsss-styling-navbarrrSideBar ${navRoutesOption === '/MotorVehicle' ? 'selectedddd-route-text_sideBar' : ''}`}
          onClick={() => handleOptionClick('/MotorVehicle')}
        >
          <IconButton
            className={`iconStyle-NavSideBar-NavBarrr ${navRoutesOption === '/MotorVehicle' ? 'selectedddd-route-text_sideBar' : ''}`}
          >
            <DirectionsCarIcon />
          </IconButton>
          Motor Vehicle
        </Typography>

        <Typography
          className={`optionsss-styling-navbarrrSideBar ${navRoutesOption === '/ForeignWorkers' ? 'selectedddd-route-text_sideBar' : ''}`}
          onClick={() => handleOptionClick('/ForeignWorkers')}
        >
          <IconButton
            className={`iconStyle-NavSideBar-NavBarrr ${navRoutesOption === '/ForeignWorkers' ? 'selectedddd-route-text_sideBar' : ''}`}
          >
            <EngineeringIcon />
          </IconButton>
          Foreign Workers
        </Typography>

        <Typography
          className={`optionsss-styling-navbarrrSideBar ${navRoutesOption === '/Corporate' ? 'selectedddd-route-text_sideBar' : ''}`}
          // onClick={() => handleOptionClick('/Corporate')}
        >
          <IconButton
            className={`iconStyle-NavSideBar-NavBarrr ${navRoutesOption === '/Corporate' ? 'selectedddd-route-text_sideBar' : ''}`}
          >
            <CorporateFareIcon />
          </IconButton>
          Life Style
        </Typography>
      </div>
    </div>
  );
};

export default NavBarSidebar;
