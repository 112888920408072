  import React, { useEffect, useState } from 'react';
import CardLayout from '../CardLayout';
import RiderSectionTable from '../RiderSectionTable';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { AddaddOnsData,removeAddOnData } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';

const ForeignWorkerAddOns = React.memo((props) => {
  const { addOn } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const [isOpen, setIsOpen] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (item, checked) => {
    let obj = {
      itemCode: item?.fwdhProductPricingListId,
      salesPrice: item?.addOnAmount,
      quantity: 1
    };
    if (checked) {
      store.dispatch(AddaddOnsData([item.fwdhProductPricingListId, obj]));
    } else {
      store.dispatch(removeAddOnData(item.fwdhProductPricingListId));
    }
  };

  const ForeignWorkersAddons = [
    { id: 'sr', label: 'Sr.', minWidth: 50 },
    { id: 'productName', label: 'Product Name', minWidth: 150,align: 'center' },
    { id: 'Prices', label: 'Price', minWidth: 90, align: 'center' },
    { id: 'price', label: 'Action', minWidth: 60, }
  ];
  const ForeignWorkersAddonRows = props?.data?.foreignWorkerCalculation?.map((item, index) => {
    return {
      sr: index + 1,
      productName: item.productName,
      Prices: item.addOnAmount,
      price: (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={addOn[item?.fwdhProductPricingListId] ? true : false}
                onChange={(e) => handleCheckboxChange(item, e.target.checked)}
              />
            }
          />
        </div>
      )
    };
  });

 
  return (
    <>
      <CardLayout isOpen={isOpen} setIsOpen={setIsOpen} toggleForm={toggleForm} heading="Add-Ons">
        <RiderSectionTable columns={ForeignWorkersAddons} rows={ForeignWorkersAddonRows} />
      </CardLayout>
    </>
  );
});

export default ForeignWorkerAddOns;
