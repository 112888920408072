import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const productDetails = [
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Comprehensive',
    description:
      'This policy provides extensive third-party liability coverage,including death,injury and property damage.It also safeguards your vehicle from accidents,fire,and theft,with additional benefits.'
  },
  {
    img: require('../../../../assets/images/motortab1.png'),
    heading: 'Third Party Fire and Theft',
    description:
      'This covers your liability for death or injury to third parties and passengers,damage to third party property and loss or damage to your vehicle due to fire or theft.'
  },
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Third Party Only',
    description:
      'This covers your liability for death or injury to third parties and passengers,including damage to third party property.It does not cover loss or damage to your own vehicle.'
  }
];
const pointData1 = [
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Private Car',
    description: 'The ANDA from Singapore provides insurance for legal liabilitites and optional coverage for vehicle damage.'
  },
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Commercial Vehicle',
    description: 'Insurance from the ANDA in Singapore covers legal liabilitites and offers optional vehicle damage protection.'
  },
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Motorcycle',
    description: 'The ANDA from Singapore offers insurance covering legal liabilities and optional motorcycle damage protection .'
  },
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Rental Car',
    description:
      'Insurance through the ANDA in Singapore includes coverage for legal liabilities and optional rental vehicle damage protection. .'
  },
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Classic Car',
    description: 'The ANDA from Singapore provides insurance for legal liabilitites and optional coverage for vehicle damage.'
  },
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Coaches',
    description:
      'Insurance through the ANDA in Singapore includes coverage for legal liabilities and offers optional damage protection for coaches.'
  }
];
const pointData = [
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Comprehensive',
    description:
      'This policy provides extensive third-party liability coverage,including death,injury and property damage.It also safeguards your vehicle from accidents,fire,and theft,with additional benefits.'
  },
  {
    img: require('../../../../assets/images/motortab1.png'),
    heading: 'Third Party Fire and Theft',
    description:
      'This covers your liability for death or injury to third parties and passengers,damage to third party property and loss or damage to your vehicle due to fire or theft.'
  },
  {
    img: require('../../../../assets/images/motortab2.png'),
    heading: 'Third Party Only',
    description:
      'This covers your liability for death or injury to third parties and passengers,including damage to third party property.It does not cover loss or damage to your own vehicle.'
  }
];
const faqsData = [
  {
    heading: 'What should I do immediately after a motor accident?',
    description: 'The Motor Claims Framework (MCF) outlines clear steps to follow:',
    point1: '1. Exchange particulars of parties involved and note vehicle numbers',
    point2: '2. Take photos of the accident scene and vehicle damages.',
    point3: "3. Call your insurer's hotline for a tow-truck or further device"
  },
  {
    heading: 'When Should I involve authorities?',
    description: 'Call the police immediately if there are injuries,fatalities, or specific circumstances like hit-and-run'
  },
  {
    heading: "What happens if I don't report the accident to my insurer?",
    description: 'Consequences include liabilities issues,loss or No-Claims Discount, or policy cancellation.'
  },
  {
    heading: 'What actions should I would after an accident?',
    description: "Don't move vehicles before photos,attempt to move injured persons, or engage way unauthorized tow-truck operators."
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3, display: 'flex' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export default function ProductMotorVehicleTab() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ minWidth: { xs: 0, sm: '250px' } }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab
          label="Product details"
          {...a11yProps(0)}
          sx={{
            bgcolor: value === 0 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 0 ? '#05318e' : '#86878c'
          }}
        />
        <Tab
          label="Insurance types"
          {...a11yProps(1)}
          sx={{
            bgcolor: value === 1 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 1 ? '#05318e' : '#86878c'
          }}
        />
        <Tab
          label="Types of vehicle insured"
          {...a11yProps(2)}
          sx={{
            bgcolor: value === 2 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 2 ? '#05318e' : '#86878c'
          }}
        />
        <Tab
          label="FAQs"
          {...a11yProps(3)}
          sx={{
            bgcolor: value === 3 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 3 ? '#05318e' : '#86878c'
          }}
        />
      </Tabs>
      <Box sx={{ flex: '1', overflowY: 'auto', padding: { xs: '0', sm: '10px' } }}>
        <TabPanel value={value} index={0}>
          <div
            style={{
              width: '100%',
              maxWidth: '100%',
              padding: '0 16px',
              margin: 'auto'
            }}
          >
            <Typography sx={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium', mb: 2 }}>
              Motor Insurance
            </Typography>
            <Typography sx={{ color: '#000', fontSize: '12px', fontWeight: '400', fontFamily: 'Medium', mb: 2 }}>
              Every registered motor vehicle must have a valid insurance policy to cover legal liabilities for third party bodily injury or
              death or damage to third party property. This is the minimum insurance requirement. Cover can be enhanced to include loss or
              damage to your own vehicle. It is an offence to drive a motor vehicle without insurance coverage.
            </Typography>
            <Typography sx={{ color: '#002d8c', fontSize: '16px',  fontFamily: 'Medium', mb: 2 }}>
              Insurance Types
            </Typography>
            {productDetails.map((item, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
                <img
                  src={item.img}
                  alt=""
                  style={{
                    width: '150px',
                    height: 'auto',
                    marginRight: '16px'
                  }}
                />
                <div style={{ flex: 1 }}>
                  <Typography sx={{ color: '#000', fontSize: '16px',  fontFamily: 'Medium', mb: 1 }}>
                    {item.heading}
                  </Typography>
                  <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium', width: '100%' }}>
                    {item.description}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div
            style={{
              width: '100%',
              maxWidth: '600px',
              padding: '0 16px',
              margin: 'auto'
            }}
          >
            <Typography sx={{ color: '#002d8c', fontSize: '16px',  fontFamily: 'Medium', mb: 2 }}>
              Insurance Types
            </Typography>
            {pointData.map((item, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
                <img
                  src={item.img}
                  alt=""
                  style={{
                    width: '150px',
                    height: 'auto',
                    marginRight: '16px'
                  }}
                />
                <div style={{ flex: 1 }}>
                  <Typography sx={{ color: '#000', fontSize: '16px',  fontFamily: 'Medium', mb: 1 }}>
                    {item.heading}
                  </Typography>
                  <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium', width: '100%' }}>
                    {item.description}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
              gap: '20px',
              padding: '0 16px',
              margin: 'auto',
              width: '70vw'
            }}
          >
            {pointData1.map((item, index) => (
              <div
                key={index}
                style={{
                  display: 'grid',
                  gridTemplateRows: 'auto auto',
                  gap: '8px',
                  alignItems: 'center',
                  marginBottom: '5px',
                  width: '100%'
                }}
              >
                <div style={{ flex: 1 }}>
                  <img
                    src={item.img}
                    alt=""
                    style={{
                      width: '70%',
                      height: '70%'
                      // maxWidth: '100%'
                      // marginTop: '4px'
                    }}
                  />
                  {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p
                      style={{
                        color: '#002d8c',
                        fontSize: '16px',
                        fontWeight: '800',
                        fontFamily: 'Medium',
                        marginBottom: '8px'
                      }}
                    >
                      {item.heading}
                    </p>
                  </div> */}
                </div>
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      color: '#002d8c',
                      fontSize: '16px',
                      fontFamily: 'Medium',
                      marginBottom: '8px'
                    }}
                  >
                    {item.heading}
                  </p>
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '500',
                      fontFamily: 'Medium',
                      maxWidth: '370px'
                    }}
                  >
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </TabPanel>

        <TabPanel value={value} index={3}>
          {faqsData.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Typography style={{ color: '#002d8c', fontSize: '16px',  fontFamily: 'Medium' }}>
                {item.heading}
              </Typography>
              <Typography style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.description}</Typography>
              <ol style={{ paddingLeft: '20px', marginTop: '5px', marginBottom: '5px', listStyleType: 'decimal' }}>
                {index === 0 && (
                  <>
                    <li style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.point1}</li>
                    <li style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.point2}</li>
                    <li style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.point3}</li>
                  </>
                )}
              </ol>
            </div>
          ))}
        </TabPanel>
      </Box>
    </Box>
  );
}
