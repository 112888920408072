import React from 'react';
import { Grid, Typography } from '@mui/material';
import { HorizontalContainer } from '../corporate/components/HorizontalContainer';


export default function CalculateMotorVehicleContainer({ data, handleCalculatePremium }) {
  return (
    <div>
      <Grid container justifyContent="center" alignItems="center" mt={2}>
        <Grid item xs={12} md={11} lg={5}>
          <Grid
            container
            style={{
              width: '100%',
              backgroundColor: '#F2F9FF',
              borderRadius: 20,
              padding: '20px',
              maxHeight: '425px',
            }}
          >
            <Typography style={{ fontFamily: 'Bold', fontSize: 20, padding: 5 }}>Get your quote</Typography>
            {data.map((item, index) => {
              let obj = { Plan: item.productName };
              return (
                <HorizontalContainer
                  data={obj}
                  sizes={[7, 1]}
                  key={index}
                  onClick={handleCalculatePremium}
                  style={{ marginBottom: '10px' }}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
