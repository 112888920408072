const Orderplaced_style = {
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '310px'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },
  image: {
    width: '100%',
    maxWidth: '210px',
    maxHeight: '160px'
  },
  textContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '15px 10px'
  },
  title: {
    fontFamily: 'GilroyBold',
    fontSize: 18,
    color: '#101010',
    width: '100%'
  },
  subtitle: {
    marginTop: 4,
    // fontFamily: 'SemiBold',
    fontSize: 14,
    color: '#B6B6B6',
    fontWeight: '600',
    width: '100%'
  },
  button: {
    borderRadius: 2,

    backgroundColor: '#002D8C'
  }
};
export { Orderplaced_style };
