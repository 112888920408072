import * as React from 'react';
import { Box, TextField, Select, MenuItem, Grid, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../../assets/fonts/Fonts.css';
import CustomPagination from 'components/pagination/Pagination';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from 'components/Theme/ThemeProvider';
const BasicTable = ({
  rows,
  searchOn,
  columns,
  statusKey,
  onSearch,
  showSearchBar = true,
  showDatePicker = true,
  // actionButton,
  handleVerifyClick,
  amountKey,
  statusColorFun,
  borderRadius,
  rowHeight,
  verticalAlign,
  getStatusBackgroundColor,
  getStatusColor,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}) => {
  const [page, setPage] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState();
  const [order, setOrder] = React.useState('asc');
  const [searchText, setSearchText] = React.useState('');
  const [showRecords, setShowRecords] = React.useState(10);
  const [newData, setNewData] = React.useState([]);
  const [newDataTemp, setNewDataTemp] = React.useState([]);

  const { currentTheme } = useTheme();

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (e) => {
    try {
      setSearchText(e);

      let tempData = newDataTemp?.filter((i) => {
        const searchFields = searchOn?.map((field) => {
          const fieldValue = i?.[field];

          const lowerCaseFieldValue = typeof fieldValue === 'string' ? fieldValue?.toLowerCase() : fieldValue;
          return typeof lowerCaseFieldValue === 'string'
            ? lowerCaseFieldValue?.includes(e.toLowerCase())
            : fieldValue?.toString()?.includes(e);
          // return lowerCaseFieldValue?.includes(e.toLowerCase());
        });
        return searchFields.some((fieldResult) => fieldResult);
      });

      setNewData(tempData);
      setPage(1);
    } catch (e) {
      console.error("Error in handleSearch"+e);

    }
  };

  const handleChangeRowsPerPage = (event) => {
    setShowRecords(event.target.value);
    setPage(1);
  };
  const itemsPerPage = showRecords;
  const startIndex = (page - 1) * showRecords;
  const endIndex = Math.min(startIndex + itemsPerPage, newData.length);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    const isNumeric = !isNaN(a[orderBy]) && !isNaN(b[orderBy]);
    if (isNumeric) {
      return b[orderBy] - a[orderBy];
    } else {
      const aValue = a[orderBy] || '';
      const bValue = b[orderBy] || '';
      return bValue.localeCompare(aValue);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      alert('handleKeyPress');
    }
  };

  React.useEffect(() => {
    if (rows) {
      setNewData(rows);
      setNewDataTemp(rows);
    }
  }, [rows]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        style={{
          backgroundColor: currentTheme.bodyColor,
          borderRadius: borderRadius,
          width: '100%',
          // top: '10%',
          border: '1px solid #ddd',
          height: 'auto',
          padding: '10px'
        }}
      >
        <Grid sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Grid
            item
            xs={3}
            sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Medium', fontSize: '13px', color: currentTheme.textColor }}
          >
            Shows
            <Select
              value={showRecords}
              onChange={handleChangeRowsPerPage}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '150px',
                    width: '130px',
                    backgroundColor: '#f8f8f8',
                    borderRadius: '9px'
                  }
                }
              }}
              size="small"
              style={{
                backgroundColor: '#f8f8f8',
                marginLeft: '2%',
                minWidth: '30%',
                borderRadius: '9px',
                height: '33px'
              }}
            >
              <MenuItem value={10}>10 Records</MenuItem>
              <MenuItem value={25}>25 Records</MenuItem>
              <MenuItem value={50}>50 Records</MenuItem>
            </Select>
          </Grid>

          {showDatePicker && (
            <Grid
              container
              justifyContent="flex-end"
              sx={{ display: 'flex', alignItems: 'center', fontFamily: 'Medium', fontSize: '13px', color: currentTheme.textColor }}
            >
              <Grid item sx={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
                Start Date
                <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  style={{
                    backgroundColor: '#F8F8F8',
                    width: '120px',
                    margin: '0 8px',
                    padding: '5px',
                    borderRadius: '8px'
                  }}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                  onClick={(e) => e.stopPropagation()}
                />
              </Grid>
              <Grid item sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                End Date
                <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  style={{
                    backgroundColor: '#F8F8F8',
                    width: '120px',
                    margin: '0 8px',
                    padding: '5px',
                    borderRadius: '8px'
                  }}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                  onClick={(e) => e.stopPropagation()}
                />
              </Grid>
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  size="small"
                  disabled={!startDate || !endDate}
                  style={{
                    backgroundColor: !startDate || !endDate ? 'grey' : '#4CB748',
                    color: '#fff',
                    height: '33px'
                  }}
                  onClick={() => handleVerifyClick()}
                >
                  Verify
                </Button>
              </Grid>
            </Grid>
          )}
          {showSearchBar && (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Grid
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#f8f8f8',
                    borderRadius: '9px',
                    height: '30px',
                    paddingRight: '10px'
                  }}
                >
                  <TextField
                    placeholder="Search here ..."
                    style={{
                      width: '100%',
                      height: '75%',
                      borderRadius: '9px',
                      border: 'none',
                      outline: 'none',
                      fontFamily: 'Medium'
                    }}
                    variant="standard"
                    size="small"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: <SearchIcon color="action" style={{ fontSize: '20px', marginRight: '10px' }} />
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          minWidth: '100%',
          backgroundColor: currentTheme.bodyColor,
          color: currentTheme.textColor,
          borderRight: '1px solid #ddd',
          borderLeft: '1px solid #ddd'
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || 'left'}
                  style={{
                    minWidth: column.minWidth || column.label.length * 10,
                    maxWidth: column.maxWidth || 'none',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Medium',
                    fontSize: '14px',
                    color: '#000000',
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    backgroundColor: '#ffffff'
                  }}
                >
                  {column.label}
                  {column.id !== 'Action' && (
                    <span
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => handleKeyPress(e)}
                      onClick={() => handleRequestSort(column.id)}
                      style={{ cursor: 'pointer', fontSize: 11 }}
                    >
                      {orderBy === column.id ? (order === 'asc' ? '▲' : '▼') : '↕'}
                    </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(newData, getComparator(order, orderBy))
              .slice(startIndex, endIndex)
              .map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  sx={{ position: 'relative', height: rowHeight, verticalAlign: verticalAlign }}
                  style={{
                    backgroundColor: row[statusKey] === 'Failed' ? '#FDECEC' : 'white',
                    borderRadius: '7px'
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    const cellStyle = column.id === statusKey ? { ...statusColorFun(value) } : {};
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align || 'left'}
                        style={{
                          ...cellStyle,
                          fontFamily: 'Medium',
                          height: row.offer ? '75px' : '50px',
                          verticalAlign: row.offer ? 'top' : 'middle',
                          backgroundColor: column.id === 'policystatus' ? getStatusBackgroundColor(row[column.id]) : '',
                          color: column.id === 'policystatus' ? getStatusColor(row[column.id]) : '#616471',
                          minWidth: column.minWidth || column.label.length * 10,
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: '200',
                          position: 'relative'
                        }}
                      >
                        {column.id === amountKey ? (
                          <>₹{value}</>
                        ) : column.id === row.actionButton?.key ? (
                          <>{row.actionButton?.value}</>
                        ) : column.format && typeof value === 'number' ? (
                          column.format(value)
                        ) : (
                          value
                        )}
                        {column.id === 'instock' && row.instock < 15 && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: row.offer ? '40px' : '13px',
                              right: '20px'
                            }}
                          >
                            <ErrorIcon style={{ width: '15px', color: 'red' }} />
                          </div>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        page={page}
        onChange={handleChangePage}
        count={Math.ceil(newData.length / itemsPerPage)}
        totalRecord={newData.length}
        startIndex={startIndex}
        endIndex={endIndex}
        backgroundColor={currentTheme.bodyColor}
        color={currentTheme.textColor}
      />
    </Box>
  );
};

const getStatusStyle = (status) => {
  switch (status) {
    case 'Order Placed':
      return { color: '#8260e0' };
    case 'Order Accepted':
      return { color: '#55ba51' };
    case 'GRN Pending':
      return { color: '#f4bc6a' };
    case 'Order Completed':
      return { color: '#0a79c1' };
    default:
      return { color: 'black' };
  }
};
export default BasicTable;

BasicTable.defaultProps = {
  rowHeight: '50px',
  verticalAlign: 'middle',
  rows: [],
  columns: [
    { id: 'OrderId', label: 'Order Id', minWidth: 140 },
    { id: 'WareHouse', label: 'WareHouse', minWidth: 150 },
    {
      id: 'CreaatAt',
      label: 'Order date & time',
      maxWidth: 170,
      align: 'center'
    },
    {
      id: 'Total Qty',
      label: 'Total QTY',
      maxWidth: 150,
      align: 'center'
    },
    {
      id: 'Total Amount',
      label: 'Total Amount',
      maxWidth: 170,
      align: 'center'
    },
    {
      id: 'Order status',
      label: 'Order Status',
      maxWidth: 150,
      align: 'center'
    },
    {
      id: 'Source',
      label: 'Source',
      maxWidth: 170,
      align: 'center'
    },
    {
      id: 'Action',
      label: 'Action',
      align: 'center'
    }
  ],
  statusKey: 'Order status',
  amountKey: 'Total Amount',
  actionButton: {
    key: 'Action',
    value: (
      <div style={{ backgroundColor: '#4cb748', color: '#fff', borderRadius: 20, minWidth: 55, fontSize: 12, cursor: 'pointer' }}>
        <VisibilityIcon style={{ fontSize: 10 }} />
        View
      </div>
    )
  },
  statusColorFun: getStatusStyle,
  borderRadius: '15px 15px 0px 0px'
};
