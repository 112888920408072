import React from 'react';
import { Grid, Typography } from '@mui/material';
import Footer from '../corporate/customerlandingpage/components/Footer';
import workers from '../../../assets/images/OneDrive_2024-05-10/png file/ANDA_ProductBanner_ForeignWorker.jpg';
import ResponsiveAppBar from '../corporate/customerlandingpage/components/NavBar';
import ImageCard from '../corporate/customerlandingpage/components/ImageCard';
import CalculateForeignContainer from './CalculateForeignContainer';
import ProductForeginTab from './components/ProductForeignTab';
import useProductPlansData from '../corporate/customerlandingpage/hooks/useProductPlansData';
export default function ForeignWorkers() {
  const tsaTypeParam = 'Foreign Worker';
  const { data, isLoading, error } = useProductPlansData(tsaTypeParam);
  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <ResponsiveAppBar />

      <div style={{ position: 'relative', width: '100%', height: '35vh', overflow: 'hidden' }}>
        <img src={workers} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '35%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: 1
          }}
        >
          <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium', marginBottom: '5px' }}>Foreign Workers</p>
          <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>ANDA Insurance: Tailored Reliable Always Ready</p>
        </div>
      </div>

      <div style={{ position: 'relative', zIndex: 2, marginTop: '-50px', width: '100%' }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={7} lg={10} mb={3}>
            <CalculateForeignContainer data={data} />
          </Grid>
        </Grid>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60%', marginTop: '5%', height: 'auto' }}>
        <ProductForeginTab />
      </div>
      {/* </Grid> */}
      <Grid item xs={12} md={7} lg={12} mt={5}>
        {/* <Grid container alignItems="center" justifyContent="space-evenly">
          <Grid item></Grid> */}
        {/* </Grid> */}
        <ImageCard />
      </Grid>
      <Grid item xs={12} md={7} lg={12} mt={4} sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Grid>
  );
}
