

import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Stepper from '../components/Stepper';
import DomesticHelperPolicyEmployerInfoForm from '../components/DomesticHelperPolicyEmployerInfoForm';
import DomesticHelperPolicyAddOn from '../components/DomesticHelperPolicyAddOn';
import DomesticHelperPremiumAmount from '../components/DomesticHelperPremiumAmount';
import { setActiveStep } from 'store/reducers/premiumPolicy';
import useProductPlansData from 'modules/customer/corporate/customerlandingpage/hooks/useProductPlansData';

const StepperSteps = ['Add-on Benefits', 'Calculate Premium', 'Employer Information'];

const DomesticHelperPolicyCoverage = ({ handleGoBack, addOnList }) => {
  const activeSteps = useSelector((state) => state.persistedReducer.premiumPolicy.activeSteps);
  const dispatch = useDispatch();
  const tsaTypeParam = 'Domestic Helper';
  const { data, isLoading, error } = useProductPlansData(tsaTypeParam);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeSteps]);

  const handleNextButton = () => {
    dispatch(setActiveStep(activeSteps + 1));
  };

  const handlePreviousButton = () => {
    if (activeSteps === 0) {
      handleGoBack();
    } else {
      dispatch(setActiveStep(activeSteps - 1));
    }
  };

  const renderPageComponent = () => {
    switch (activeSteps) {
      case 0:
        return (
          <DomesticHelperPolicyAddOn data={addOnList} handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />
        );
      case 1:
        return <DomesticHelperPremiumAmount handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 2:
        return <DomesticHelperPolicyEmployerInfoForm handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        marginTop: '20px'
      }}
    >
      <div style={{ position: 'relative', width: '50%', height: 'auto' }}>
        <Stepper width="100%" steps={StepperSteps} activeSteps={activeSteps} boxwidth={'100%'} />
      </div>
      <Grid item xs={12} md={12} lg={6} mt={4} sx={{ width: '100%' }}>
        {renderPageComponent()}
      </Grid>
    </Grid>
  );
};

export default DomesticHelperPolicyCoverage;
