import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Footer from '../corporate/customerlandingpage/components/Footer';
import motorvehicle from '../../../assets/images/OneDrive_2024-05-10/png file/ANDA_ProductBanner_MotorVehicle.jpg';
import ResponsiveAppBar from '../corporate/customerlandingpage/components/NavBar';
import ImageCard from '../corporate/customerlandingpage/components/ImageCard';
import CalculateMotorVehicleContainer from './CalculateMotorVehicleContainer';
import ProductMotorVehicleTab from './components/ProductMotorVehicleTab';
import useProductPlansData from '../corporate/customerlandingpage/hooks/useProductPlansData';
import PlanPremiumTable from './components/PlanPremiumTable';
import MotorVehicleForm from './components/MotorVehicleForm';
import { useNavigate } from 'react-router-dom';
const MotorVehicle = () => {
  const tsaTypeParam = 'Motor';
  const { data, isLoading, error } = useProductPlansData(tsaTypeParam);
  const [showMotorVehicleForm, setShowMotorVehicleForm] = useState(false);

  const handleCalculatePremium = () => {
    setShowMotorVehicleForm(true);
  };

  const handleGoBack = () => {
    setShowMotorVehicleForm(false);
  };
  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <ResponsiveAppBar />
      <div style={{ position: 'relative', width: '100%', height: '35vh', overflow: 'hidden' }}>
        <div style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}>
          <img src={motorvehicle} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: 1
          }}
        >
          <p style={{ fontSize: 'clamp(16px, 5vw, 24px)', fontFamily: 'Medium', marginBottom: '5px' }}>Motor Vehicle</p>
          <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>ANDA Insurance: Tailored Reliable Always Ready</p>
        </div>
      </div>
      <div style={{ position: 'relative', zIndex: 2, marginTop: '-70px', width: '100%' }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={7} lg={12}>
            {showMotorVehicleForm ? (
               <MotorVehicleForm handleGoBack={handleGoBack} />
            ) : (
              <CalculateMotorVehicleContainer data={data} handleCalculatePremium={handleCalculatePremium} />
            )}
          </Grid>
        </Grid>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', marginTop: '2%', height: 'auto' }}>
        {/* Add other components here */}
        {/* <PlanPremiumTable /> */}
      </div>
      <Grid item xs={12} md={7} lg={12} mt={5}>
        <ImageCard />
      </Grid>
      <Grid item xs={12} md={7} lg={12} mt={4} sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default MotorVehicle;
