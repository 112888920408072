import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, TextField, Grid, Typography, Paper } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import '../../../packages & premium/styles/SelectCustomerSideBar.css';
import { useTheme } from 'components/Theme/ThemeProvider';
import { useGetCustomerMasterbyNRICGetQuery } from 'store/services/VehicleDetails';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { API } from 'api/API';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApiCall from 'api/useApiCall';
import { usePremiumCalculationState } from 'modules/packages & premium/provider/PremiumCalculationProvider';
import { store } from 'store/index';
import { activeItem } from 'store/reducers/menu';
import OverlayLoader from 'components/loader/OverlayLoader';
import useNetworkStatus from 'hooks/useNetworkStatus';

const DomesticAddCustomerSidebar = (props) => {
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  const { uenData } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { otherState, activeParams } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { addOn } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [nric, setNric] = useState('');
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const network = useNetworkStatus();
  const [customerDetails, setCustomerDetails] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    maritalStatus: '',
    source: '',
    mobile: '',
    designation: '',
    occupation: '',
    licensePassDate: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    remarks: '',
    nricNo: ''
  });
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setCustomerDetails({ ...customerDetails, email: email });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateNric = (nric) => {
    const nricPattern = /^[STFG]\d{7}[A-Z]$/;
    return nricPattern.test(nric);
  };

  const { data } = useGetCustomerMasterbyNRICGetQuery({ nric: nric || null });

  const handleSubmitCustomer = async () => {
    if (!network) {
      showDanger('No internet connection.');
      return;
    }
    setLoading(true);
    let obj = { itemCode: `${activeParams.productID}`, salesPrice: props?.apiData?.productAmount, quantity: 1, isAdd_On: false };
    const arr = [];
    {
      arr?.push(obj);
      if (addOn != undefined) {
        Object.values(addOn)?.forEach((itm) => {
          arr.push({ itemCode: `${itm.itemCode}`, salesPrice: itm.salesPrice, quantity: 1, isAdd_On: true });
        });
      }
    }
    const duration = props?.params?.duration;
    try {
      if (isButtonDisabled) return;

      setIsButtonDisabled(true);
      if (!customerDetails?.firstName || !customerDetails?.nricNo || !customerDetails?.lastName) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please fill in all required fields');
        return; // Add return here to exit the function
      } else if (!validateEmail(customerDetails.email)) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please enter a valid email address');
        return; // Add return here to exit the function
      } else if (!validateNric(customerDetails.nricNo)) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please enter a valid NRIC');
        return; // Add return here to exit the function
      } else {
        let body = {
          nric: uenData?.nriC_FIN__c || customerDetails?.nricNo,
          uen: uenData?.ueN_No__c || (otherState?.UENDHInsurance === '' ? '' : otherState.UENDHInsurance),
          isIndividual: otherState.UENDHInsurance === '' ? true : false,
          insuranceStart: otherState?.fromdateDHInsurance,
          insuranceEnd: otherState?.todateDHinsurance,
          opportunityType: 'Domestic Helper',
          portalUserId: props?.apiData?.userId,
          creditTerm: true,
          durationInMonths : `${duration} Months`,
          transactionType: otherState?.transactionType,
          companyRegistered: false,
          vehicleId: null,
          vrn: null,
          coverageType: null,
          deliveryExtension: null,
          chasisNo: null,
          engineNo: null,
          drivingLicenseDate: null,
          excess: 0,
          claimsDetails: null,
          nameOfHirePurchase: null,
          workshopType: null,
          // vehicleTonnage: '0',
          ncdAmount: 0,
          ncd: null,
          // ncdProtector: null,
          reg_manufactureDate: null,
          bondDuration: null,
          reTransmissionReminder: null,
          reviewedByInsurer: true,
          basisOfCover: null,
          mainContractorName: null,
          cpfSubmissionNo: null,
          transmissionStatus: null,
          dischargeDate: null,
          loiStatus: null,
          bondCallStatus: null,
          loiType: null,
          contact: {
            firstName: customerDetails?.firstName,
            lastName: customerDetails?.lastName,
            gender: null,
            email: customerDetails?.email,
            maritalStatus: null,
            source: null,
            mobile: customerDetails?.mobile,
            designation: null,
            occupation: null,
            licensePassDate: customerDetails?.licensePassDate,
            street: null,
            city: null,
            state: null,
            postalCode: null,
            country: null,
            remarks: null,
            nricNo: customerDetails?.nricNo
          },
          items: arr,
          insuredParty: [
            {
              firstName: otherState?.ndw,
              lastName: otherState?.lastname,
              nric: null,
              dob: otherState?.dob || null,
              email: null,
              gender: null,
              drivingLicense: null,
              claimsAmount: null,
              nationality: otherState?.nationalityDHInformation,
              contact: null,
              active: true,
              workPermitNo: otherState?.workpermit,
              passportNo: otherState?.passportno,
              claimsIn3Year: 0,
              amountOfClaimsIn3Year: 0,
              age: 0,
              experienceInMonths: '',
              ageOfDate: '',
              experienceInMonthsOfDate: '',
              fin: otherState?.fin
            }
          ],

          productId: props?.apiData?.productId,
          loginDetailsId: props?.apiData?.loginDetailsId,
          customerPolicyId: null,
          subCategory: otherState?.subcategoryDHInsurance,
          opportunityId:  null,
          totalPremiumAmount:props?.apiData?.finalTotalPremiumAmount || 0
        };
        if (Object.keys(uenData)?.length > 0) {
          body['account'] = {
            accName: uenData?.name || null,
            accEmail: uenData?.email_Address__c || null,
            phone: uenData?.phone?.toString() || null,
            source: uenData?.accountSource || null,
            bankAccountName: uenData?.bank_Account_Name__c || null,
            bankCode: uenData?.bank_Code__c || null,
            bankName: uenData?.bank_Name__c || null,
            bankAccountNo: uenData?.bank_Account_No__c || null,
            street: uenData?.billingStreet || null,
            city: uenData?.billingCity || null,
            state: uenData?.billingState || null,
            postalCode: uenData?.billingPostalCode || null,
            country: uenData?.billingCountry || null
          };
        } else {
          body['account'] = {
            accName: otherState?.compnaynameDHInsurance || '',
            accEmail: otherState?.emailDHInsurance || '',
            phone: otherState?.phoneDHInsurance?.toString() || '',
            source: null,
            bankAccountName: null,
            bankCode: null,
            bankName: null,
            bankAccountNo: null,
            street: null,
            city: null,
            state: null,
            postalCode: null,
            country: null
          };
        }
        let response = await makeRequest({ method: 'POST', url: API.FinalSubmissionCustomerPortalForFWDHAPI, body });
        if (response?.code == 200) {
          showSuccess(response?.data?.message);
          props.setShow(false);
          store.dispatch(activeItem({ openItem: ['policies'] }));
          setLoading(true);
          navigate('/dashboard/Policies');
        } else {
          setIsButtonDisabled(false);
          setLoading(false);
          showDanger('Policy Not Created');
        }
      }
    } catch (error) {
      showDanger(error);
      setIsButtonDisabled(false);
    }
  };

  const handleSearch = (e) => {
    setShowList(true);
    const searchTerm = e?.target?.value;
    setNric(searchTerm);

    setCustomerDetails({
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      nricNo: ''
    });
  };

  const handleNameClick = (name) => {
    setShowList(false);
    if (name) {
      setNric(name?.nricNo);
      setCustomerDetails({
        firstName: name?.firstName,
        lastName: name?.lastName,
        mobile: name?.mobilePhone,
        email: name?.finance_Email__c,
        nricNo: name?.nricNo
      });
    }
  };

  return (
    <Grid className="topContainer_SelectCustomerSideBar">
      <Grid className="heading_SelectCustomerSideBar">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Selected Customer
        </Typography>
      </Grid>

      <hr />

      <Grid className="searchbarGrid_SelectCustomerSideBar">
        <TextField
          placeholder="Search"
          className="textfield_SelectCustomerSideBar"
          variant="standard"
          size="small"
          value={nric}
          onChange={handleSearch}
          InputProps={{
            disableUnderline: true,
            endAdornment: <SearchIcon color="action" className="inputPropStyle_SelectCustomerSideBar" />
          }}
        />
      </Grid>
      {showList && data != '' && (
        <Paper
          style={{
            padding: '2%',
            margin: '0.5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            width: '100%',
            position: 'absolute',
            zIndex: 4,
            maxHeight: '30%',
            overflowY: 'auto'
          }}
        >
          {data?.map((item, index) => (
            <Grid item onClick={() => handleNameClick(item)} style={{ width: '100%' }}>
              <Typography key={index} style={{ width: '100%', cursor: 'pointer' }}>
                {item.nricNo}
              </Typography>
              <hr style={{ width: '100%', border: 'none', borderBottom: '1px solid lightgray' }} />
            </Grid>
          ))}
        </Paper>
      )}

      <Grid className="stylinghrtags_SelectCustomerSideBar">
        <Grid style={{ width: '20%', marginRight: '2%' }}>
          <hr />
        </Grid>
        <p style={{ marginTop: '1%' }}>Or</p>
        <Grid style={{ width: '20%', marginLeft: '2%' }}>
          <hr />
        </Grid>
      </Grid>

      <Grid className="addCustomersGridStyling">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Add Customer
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className="allinputpositioningstyling">
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>First Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.firstName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, firstName: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Last Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.lastName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, lastName: e.target.value })}
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Phone Number</InputLabel>
              {/* <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel> */}
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.mobile}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, mobile: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Email Address</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              type="email"
              size="small"
              variant="standard"
              value={customerDetails?.email}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={handleEmailChange}
            />
          </Grid>

          {/* Last Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>NRIC</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              placeholder="e.g. S3272118D"
              value={customerDetails?.nricNo}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, nricNo: e.target.value })}
            />
          </Grid>
          {/* <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>license Pass Date</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              type="date"
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              value={customerDetails?.licensePassDate}
              onChange={(e) => setCustomerDetails({ ...customerDetails, licensePassDate: e.target.value })}
              required
            />
          </Grid> */}
        </Grid>
      </Grid>

      <Grid>
        {/* <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium', marginBottom: '2%' }} variant="h5">
          Select Payment Mode
        </Typography> */}
        <div>
          {/* <Button
            size="small"
            variant="contained"
            // className="creditNoteButton"
            style={{
              borderRadius: '8px',
              color: '#fff',
              backgroundColor: '#00c7b1',
              minWidth: '50px',
              marginRight: '10px',
              padding: '2%',
              fontFamily: 'Medium'
            }}
            onClick={() => alert('Please Make a function to handle the left button action')}
          >
            Credit Term
          </Button> */}
          <Button
            size="small"
            variant="contained"
            disabled={isButtonDisabled}
            // className="ProceedToPayButton"
            style={{
              borderRadius: '8px',
              color: isButtonDisabled ? '#000' : '#fff',
              backgroundColor: isButtonDisabled ? '#dfe6e9' : '#002D8C',
              minWidth: '100%',
              marginRight: '10px',
              padding: '2%',
              marginTop: '6%',
              fontFamily: 'Medium'
            }}
            onClick={() => handleSubmitCustomer()}
          >
            Complete Purchase
          </Button>
        </div>
      </Grid>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
          <OverlayLoader show={true} />
        </div>
      )}
    </Grid>
  );
};

export default DomesticAddCustomerSidebar;
