import React from 'react';
import ResponsiveAppBar from '../components/NavBar';
import sliderpicture1 from '../../../../../assets/images/Anda New Images/ANDA_homepage_HomepageBanner.png';
import Products from '../components/Products';
import { Grid } from '@mui/material';
import Footer from 'modules/customer/corporate/customerlandingpage/components/Footer';
import ImageCard from '../components/ImageCard';
import ButtonGrid from 'modules/customer/corporate/customerlandingpage/components/ButtonGrid';
import ImageTabProductPlans from '../components/ImageTabProductPlans';
import AboutAndaInsurance from '../components/AboutAndaInsurance';

const CustomerLandingPage = () => {
  return (
    <Grid container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden' }}>
      <ResponsiveAppBar />
      <div style={{ position: 'relative', width: '100%', height: '35vh' }}>
        <img src={sliderpicture1} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '35%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: 1
          }}
        >
          <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium', marginBottom: '5px' }}>Beyond Coverage, Beyond Compare</p>
          <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>ANDA Insurance: Tailored,Reliable,Always Ready</p>
        </div>
      </div>
      <Grid item xs={12} mt={3} >
        <ImageTabProductPlans />
      </Grid>
      <Grid item xs={12} mb={5}>
        <AboutAndaInsurance />
      </Grid>
      <Grid item xs={12} mt={5} mb={5}>
        <Products />
      </Grid>
      <Grid item xs={12} md={7} lg={12} mb={5} mt={5}>
        <ButtonGrid />
      </Grid>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '5%' }}>
        <ImageCard />
      </div>
      <Grid item xs={12} md={7} lg={12} mt={5} sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default CustomerLandingPage;
