import React from 'react';
import { Grid } from '@mui/material';
import '../styles/InformationForm.css';
import DomesticHelperWorkerInformation from './domestichelper/DomesticHelperWorkerInformation';
import DomesticHelperInsurance from './domestichelper/DomesticHelperInsurance';
import DomesticHelperBottomPortion from './domestichelper/DomesticHelperBottomPortion';
import DomesticHelperAddOns from './domestichelper/DomesticHelperAddOns';
import useGetCountriesList from '../hooks/useGetCountriesList';
import useFwDhCalculationGet from '../hooks/useFwDhCalculationGet';

const DomesticHelperPremiumView = React.memo(() => {
  const { data } = useFwDhCalculationGet();
  // const [UserId, setUserId] = useState('');
  // const [productMasterID, setProductMasterID] = useState('');
  // const { params } = usePackagePremiumState();
  // const [cardOpenCloseObj, setCardOpenCloseObj] = useState({
  //   insurance: true,
  //   addons: false,
  //   information: false
  // });

  // useEffect(() => {
  //   if (params) {
  //     setProductMasterID(params?.productID);
  //     setUserId(params?.userId);
  //   }
  // }, [params]);

  // const { data } = useGetFWDHCalculationGetQuery({ UserId, productMasterID });
  const getCountriesListData = useGetCountriesList();

  return (
    <Grid container xs={12} width="100%">
      {/* <DomesticHelperEmployerInformation getCountriesListData={getCountriesListData}/> */}
      <DomesticHelperInsurance /* cardOpenCloseObj={cardOpenCloseObj} setCardOpenCloseObj={setCardOpenCloseObj} */ data={data} />
      <DomesticHelperAddOns /* cardOpenCloseObj={cardOpenCloseObj} setCardOpenCloseObj={setCardOpenCloseObj} */ data={data} />
      <DomesticHelperWorkerInformation
        /*   cardOpenCloseObj={cardOpenCloseObj}
        setCardOpenCloseObj={setCardOpenCloseObj} */
        getCountriesListData={getCountriesListData}
      />
      <DomesticHelperBottomPortion />
    </Grid>
  );
});

export default DomesticHelperPremiumView;
