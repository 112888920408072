import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, InputLabel } from '@mui/material';
import { getUserDetails } from 'helper/utils';
import '../../myprofile/styles/Profile.css';

const ProfileView = () => {
  const [userData, setUserData] = useState(null);
  
  useEffect(() => {
    (async () => {
      let res = await getUserDetails();
      setUserData(res);
    })();
  }, []);

  return (
    <Grid container rowSpacing={1} columnSpacing={2} xs={12} alignItems="center">
      <Grid item xs={12} sm={3} md={3} className="profilepicture_container">
        <div className="profilepicture_profile">
          <img src={userData?.profileUrl} alt="profile" className="image_viewprofile" />
        </div>
      </Grid>

      <Grid item xs={12} sm={9} md={9}>
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={12} sm={4} md={4}>
            <InputLabel className="input_label">Name</InputLabel>
            <TextField
              disabled
              value={userData?.firstName || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <InputLabel className="input_label">UEN No</InputLabel>
            <TextField
              disabled
              value={userData?.uenNo || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <InputLabel className="input_label">TSA Type</InputLabel>
            <TextField
              disabled
              value={userData?.tsA_Type__c || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <InputLabel className="input_label">Address</InputLabel>
            <TextField
              disabled
              value={userData?.street || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={12} sm={4} md={4}>
            <InputLabel className="input_label">Postal Code</InputLabel>
            <TextField
              disabled
              value={userData?.postalCode || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <InputLabel className="input_label">Country</InputLabel>
            <TextField
              disabled
              value={userData?.country || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
            />
          </Grid>

          {/* Last Row */}
          <Grid item xs={12} sm={4} md={4}>
            <InputLabel className="input_label">Contact</InputLabel>
            <TextField
              disabled
              value={userData?.mobilePhone || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <InputLabel className="input_label">Email</InputLabel>
            <TextField
              disabled
              value={userData?.finance_Email__c || ''}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="text_field"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileView;
