import React from 'react';
import { Grid, Typography } from '@mui/material';
import { HorizontalContainer } from '../../corporate/components/HorizontalContainer';


export default function DomesticCalculateContainer({ data, handleCalculatePremium }) {



  return (
    <div>
      <Grid container justifyContent="center" alignItems="center" mt={2}>
        <Grid item xs={11.5} md={11} lg={9}>
          <Grid
            container
           
            sx={{
              width: '100%',
              backgroundColor: '#F2F9FF',
              borderRadius: 5,
              padding: '10px',
              maxHeight: '420px',
              overflowY: 'auto',
              // Custom scrollbar styling
              '&::-webkit-scrollbar': {
                width: '10px' // Change the width of the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#002D8C', // Change the color of the scrollbar thumb
                borderRadius: '10px' // Add some rounding to the thumb
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#F2F9FF' // Change the color of the scrollbar track
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#8e8e8e' // Darker shade on hover
              }
              // scrollbarWidth: 'none' /* Firefox */,
              // '-ms-overflow-style': 'none' /* IE 10+ */,
              // '&::-webkit-scrollbar': {
              //   display: 'none' /* Safari and Chrome */
              // }
            }}
          >
            {/* {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
              <OverlayLoader show={true} />
              </div>
              ) : (
                <> */}
            <Typography style={{ fontFamily: 'Bold', fontSize: 20, padding: 5 }}>Benefits and Premium</Typography>
            {data?.map((item, index) => {
              let obj = { Plan: item.productName, /*  Months: item.durationInMonth || '-', */ Price: item.grossPremium || '-' };
              return <HorizontalContainer data={obj} sizes={[6, 3, 3]} key={index} onClick={handleCalculatePremium} />;
            })}
            {/* </>
            )} */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
