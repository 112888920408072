import React, { useState, useEffect } from 'react';
import Stepper from '../../domestic helper policy view/components/Stepper';
import { Grid } from '@mui/material';
import MotorVehicleRidingExperienceContainer from './MotorVehicleRidingExperienceContainer';
import MotorVehicleCoveragePeriod from './MotorVehicleCoveragePeriod';
import MotorVehiclePlansPremium from './MotorVehiclePlansPremium';
import { store } from 'store/index';
import { setActiveStep } from 'store/reducers/premiumPolicy';
import { useSelector } from 'react-redux';
import MotorVehiclePersonalDetails from './MotorVehiclePersonalDetails';
import MotorVehicleDetails from './MotorVehcileDetails';

const MotorVehicleForm = ({ handleGoBack }) => {
  const activeSteps = useSelector((state) => state.persistedReducer.premiumPolicy.activeSteps);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeSteps]);

  const handleNextButton = () => {
    store.dispatch(setActiveStep(activeSteps + 1));
  };

  const handlePreviousButton = () => {
    if (activeSteps === 0) {
      handleGoBack();
    } else {
      store.dispatch(setActiveStep(activeSteps - 1));
    }
  };

  const StepperSteps = ['Vehicle Details', 'Personal Details', 'Riding Experience', 'Coverage Period', 'Plan & Premium'];
  
  const renderPageComponent = () => {
    switch (activeSteps) {
      case 0:
        return <MotorVehicleDetails handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 1:
        return <MotorVehiclePersonalDetails handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 2:
        return <MotorVehicleRidingExperienceContainer handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 3:
        return <MotorVehicleCoveragePeriod handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 4:
        return <MotorVehiclePlansPremium handlePreviousButton={handlePreviousButton} />;
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        marginTop: '20px'
      }}
    >
      <div style={{ position: 'relative', width: '50%', height: 'auto' }}>
        <Stepper width="100%" steps={StepperSteps} activeSteps={activeSteps} boxwidth={'100%'} />
      </div>
      <Grid item xs={12} md={12} lg={6} mt={4} sx={{ width: '100%' }}>
        {renderPageComponent()}
      </Grid>
    </Grid>
  );
};

export default MotorVehicleForm;
