import React from 'react';
import { Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh' // Set full height of the viewport
});

const TextContainer = styled('div')({
  textAlign: 'center'
});

const StyledButton = styled(Button)({
  backgroundColor: '#0174bf',
  borderRadius: '7px',
  marginTop: '16px' // 16px margin-top
});

const SamplePage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <TextContainer>
        <Typography variant="h4" color="error">
          404 - Page Not Found
        </Typography>
        <Typography variant="body1" style={{ marginTop: '16px' }}>
          Sorry, the page you are looking for does not exist or you are logged out
        </Typography>
        <StyledButton
          size="small"
          onClick={() => {
            navigate('/');
          }}
          variant="contained"
        >
          Go to Home
        </StyledButton>
      </TextContainer>
    </Container>
  );
};

export default SamplePage;
