import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, Paper } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import '../../../packages & premium/styles/SelectCustomerSideBar.css';
import { useTheme } from 'components/Theme/ThemeProvider';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { API } from 'api/API';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApiCall from 'api/useApiCall';
import { activeItem } from 'store/reducers/menu';
import { store } from 'store/index';
import OverlayLoader from 'components/loader/OverlayLoader';
import { removeOtherState } from 'store/reducers/premiumPolicy';
import useNetworkStatus from 'hooks/useNetworkStatus';

const MotorAddCompanySidebar = (props) => {
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const network = useNetworkStatus();
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setAccountDetails({ ...accountDetails, accEmail: email });
    setContactDetails({ ...contactDetails, email: email });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const [accountDetails, setAccountDetails] = useState({
    accName: otherState['account']?.companyName,
    accEmail: otherState['account']?.email,
    phone: '',
    source: '',
    bankAccountName: '',
    bankCode: '',
    bankName: '',
    bankAccountNo: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: ''
  });
  const [contactDetails, setContactDetails] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    maritalStatus: '',
    source: '',
    mobile: '',
    designation: '',
    occupation: '',
    licensePassDate: null,
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    remarks: '',
    nricNo: ''
  });
  const handleSubmitCustomer = async () => {
    if (!network) {
      showDanger('No internet connection.');
      return;
    }
    setLoading(true);
    try {
      if (isButtonDisabled) return;
      setIsButtonDisabled(true);
      if (
        !accountDetails?.accName ||
        !accountDetails?.uen ||
        !accountDetails?.accEmail ||
        !contactDetails.firstName ||
        !contactDetails.lastName
      ) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please fill in all required fields');
      } else if (!validateEmail(accountDetails.accEmail)) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please enter a valid email address');
      } else {
        let isFoodParcel = otherState?.isFoodParcel;
        if (isFoodParcel === true) {
          isFoodParcel = 'yes';
        } else if (isFoodParcel === false) {
          isFoodParcel = 'no';
        }
        let body = {
          nric: '',
          uen: accountDetails.uen,
          isIndividual: false,
          insuranceStart: otherState.POIStartDate,
          insuranceEnd: otherState.POIEndDate,
          opportunityType: 'Motor',
          portalUserId: props.apiData.userId,
          creditTerm: true,
          durationInMonths: '',
          transactionType: otherState.transactionType,
          companyRegistered: otherState.isCompanyRegistered,
          vehicleId: otherState.vehicleId,
          vrn: otherState.vehicleRegistration,
          coverageType: '',
          deliveryExtension: isFoodParcel,
          chasisNo: otherState.chasisNumber,
          engineNo: otherState.engineNumber,
          drivingLicenseDate: accountDetails.licensePassDate,
          excess: props.apiData.excessAmount,
          claimsDetails: otherState.claimDetails,
          nameOfHirePurchase: otherState.nameOfHirePurchase,
          workshopType: otherState.workShopType,
          ncdAmount: props.apiData.ncdAmount,
          ncd: '',
          reg_manufactureDate: '',
          bondDuration: '',
          reTransmissionReminder: null,
          reviewedByInsurer: false,
          basisOfCover: '',
          mainContractorName: '',
          cpfSubmissionNo: '',
          transmissionStatus: '',
          dischargeDate: null,
          loiStatus: '',
          bondCallStatus: '',
          loiType: '',
          account: accountDetails,
          contact: contactDetails,
          items: [
            {
              itemCode: props?.apiData?.productID,
              salesPrice: props?.apiData?.finalPremiumAmount,
              quantity: 1
            }
          ],
          insuredParty: [
            {
              firstName: '',
              lastName: '',
              nric: '',
              dob: null,
              gender: '',
              drivingLicense: null,
              nationality: '',
              contact: '',
              email: '',
              active: true,
              workPermitNo: '',
              passportNo: '',
              claimsIn3Year: 0,
              amountOfClaimsIn3Year: 0,
              age: 0,
              experienceInMonths: '',
              ageOfDate: '',
              experienceInMonthsOfDate: '',
              fin: ''
            }
          ],
          productId: props.apiData.productID,
          loginDetailsId: props?.apiData?.loginDetailsId,
          customerPolicyId: props?.params?.customerPolicyId || null,
          opportunityId: props?.params?.opportunityId || null,
          totalPremiumAmount: props?.apiData.finalTotalPremiumAmount || 0
        };

        const response = await makeRequest({ method: 'POST', url: API.FinalSubmissionCustomerPortalForFWDHAPI, body });

        if (response?.code == 200) {
          showSuccess(response?.data?.message);
          props.setShow(false);

          store.dispatch(activeItem({ openItem: ['policies'] }));

          navigate('/dashboard/Policies');
          store.dispatch(removeOtherState());
        } else {
          setIsButtonDisabled(false);
          setLoading(false);
          showDanger('Policy Not Created');
        }
      }
    } catch (error) {
      console.error(error);

    }
  };

  return (
    <Grid className="topContainer_SelectCustomerSideBar">
      <Grid className="heading_SelectCustomerSideBar">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Selected Customer
        </Typography>
      </Grid>

      <Grid className="stylinghrtags_SelectCustomerSideBar">
        <Grid style={{ width: '20%', marginRight: '2%' }}>
          <hr />
        </Grid>
        <p style={{ marginTop: '1%' }}>Or</p>
        <Grid style={{ width: '20%', marginLeft: '2%' }}>
          <hr />
        </Grid>
      </Grid>

      <Grid className="addCustomersGridStyling">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Add Company
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className="allinputpositioningstyling">
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>First Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={contactDetails?.firstName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setContactDetails({ ...contactDetails, firstName: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Last Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={contactDetails?.lastName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setContactDetails({ ...contactDetails, lastName: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Company Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={accountDetails?.accName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setAccountDetails({ ...accountDetails, accName: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>UEN Number</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={accountDetails?.uen}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setAccountDetails({ ...accountDetails, uen: e.target.value })}
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Email Address</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={accountDetails?.accEmail}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={handleEmailChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <Button
          size="small"
          variant="contained"
          disabled={isButtonDisabled}
          style={{
            borderRadius: '8px',
            color: isButtonDisabled ? '#000' : '#fff',
            backgroundColor: isButtonDisabled ? '#dfe6e9' : '#002D8C',
            minWidth: '100%',
            marginRight: '10px',
            padding: '2%',
            fontFamily: 'Medium'
          }}
          onClick={() => handleSubmitCustomer()}
        >
          Complete Purchase
        </Button>
      </Grid>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
          <OverlayLoader show={true} />
        </div>
      )}
    </Grid>
  );
};

export default MotorAddCompanySidebar;
