import * as React from 'react';
import Box from '@mui/material/Box';
import ReusableCard from 'components/cards/ResuableCard';
import { Grid } from '@mui/material';
import '../styles/ImageTabProductPlans.css';
import Domestic_Helper from '../../../../../assets/images/product_plan_image/domestic_helper.png';
import Foreign_Worker from '../../../../../assets/images/product_plan_image/foreign_worker.png';
import Motor_Vehicle from '../../../../../assets/images/product_plan_image/motor_vehicle.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom'; 

const staticProductPlans = [
  {
    displayUrl: Domestic_Helper,
    productName: 'Domestic Helper',
    grossPremium: 'From $490.50 to $367.88',
    description: 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.',
    route: '/DomesticHelper',
  },
  {
    displayUrl: Motor_Vehicle,
    productName: 'Motor Vehicle',
    grossPremium: 'From $490.50 to $367.88',
    description: 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.',
    route: '/MotorVehicle',
  },
  {
    displayUrl: Foreign_Worker,
    productName: 'Foreign Worker',
    grossPremium: 'From $490.50 to $367.88',
    description: 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.',
    route: '/ForeignWorkers',
  }
];

const ProductCard = ({ item, onClick }) => {
  return (
    <div
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onClick();
      }}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer', margin: '10px' }}
    >
      <ReusableCard
        width="250px"
        height="280px"
        border="none"
        backgroundColor="white"
        content={
          <>
            <div className="image_container_productPlans_imagetab">
              <img
                src={item.displayUrl}
                className="image_styling_productPlans_imageTab"
                alt={item.productName}
              />
            </div>
            <div className="card_content_container_productPlans_imageTab">
              <span className="card_planname_styling_productPlans_imageTab">
                {item.productName}
              </span>
              <span className="card_priceLimit_styling_productPlans_imageTab" style={{ fontWeight: 'bold' }}>
                {item.grossPremium}
              </span>
              <span className="card_priceLimit_styling_productPlans_imageTab" style={{ fontFamily: 'Medium' }}>
                {item.description}
              </span>
              <div className="explore_container_productPlans_imageTab">
                <span className="explore_text_productPlans_imageTab">Explore</span>
                <ArrowForwardIosIcon className="explore_icon_productPlans_imageTab" />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};


const ProductPlans = ({ data }) => {
  const navigate = useNavigate();
  const handleCardClick = (route) => {
    navigate(route);
  };

  return (
    <Grid container spacing={2} className="maincontainer_productPlans_imageTab">
      {data.map((item, index) => (
        <Grid item lg={2} key={index} style={{ display: 'flex', justifyContent: 'center', height: '100%', margin: index === 1 ? '0 30px' : '0' }}>
          <ProductCard item={item} onClick={() => handleCardClick(item.route)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default function ImageTabProductPlans() {
  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
        display: 'flex',
        width: '100vw',
        bgcolor: 'transparent',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '3%'
      }}
    >
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '1%'}}>
        <ProductPlans data={staticProductPlans} />
      </Box>
    </Box>
  );
}
