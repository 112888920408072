// types
import { createSlice } from '@reduxjs/toolkit';
import { currentDate1, formattedDate } from 'modules/packages & premium/static';

let initialObj = {
  //agent fields
  UEM: '',
  UEMSTATUS: null,
  POIStartDate: currentDate1,
  POIEndDate: formattedDate,
  isCompanyRegistered: false,
  isFoodParcel: false,
  businessType: 0,
  cm: '',
  email: '',
  phone: '',
  vehicleId: '',
  ageOfVehicle: '',
  vehicleRegistration: '',
  chasisNumber: '',
  engineNumber: '',
  nameOfHirePurchase: '',
  workShopType: '',
  NCD: '',
  claimDetails: '',
  claimcount: '',
  claimamount:'',
  RegistrationDate: '',
  riderDataSection: {},
  transactionType: 'New',
  basisofcover: '',
  maincontractor: '',
  uen: '',
  CPF: '',
  dischargeDate: '',
  transmissionstatus: '',
  bondcall: '',
  bondduration: '',
  LOIstatus: '',
  LOItype: '',
  fromDate: new Date().toISOString().split('T')[0],
  todate: '',
  durationInMonths: '',
  durationInDays: '',
  subcategory: '',
  durationwise: '',
  addOn: [],
  workerSection: {},
  name: '',
  gender: '',
  nationality: '',
  nric: '',
  dob: '',
  phone: '',
  email: '',
  address: '',
  addOn: [],
  ndw: '',
  lastname: '',
  fin: '',
  passportno: '',
  workpermit: '',
  nationalityDHInformation: '',
  UENDHInsurance: '',
  fromdateDHInsurance: currentDate1,
  noofmonthsDHInsurance: '',
  noofdaysDHInsurance: '',
  todateDHinsurance: '',
  subcategoryDHInsurance: '',
  compnaynameDHInsurance: '',
  emailDHInsurance: '',
  phoneDHInsurance: '',

  //customerfield
  bikeMake: '',
  bikeModal: '',
  bikeRegistered: '',
  gen: '',
  martial: '',
  profession: '',
  dob: '',
  ridingExp: '',
  ncd: '',
  claimscount: '',
  CustomerriderDataSection: {},
  coverageStartDate: '',
  coverageEndDate: '',
  policytype: ''
};
// initial state
const initialState = {
  activeParams: {},
  uenData: {},
  otherState: initialObj,
  policy: {},
  activeSteps: 0,
  flag : false
};

// initial state
// const initialState = {
//   activeParams: {},
//   uenData: {},
//   otherState: initialObj,
//   policy: {}
// };

// ==============================|| SLICE - premiumPolicy ||============================== //

const premiumPolicy = createSlice({
  name: 'premiumPolicy',
  initialState,
  reducers: {
    addActiveParams(state, action) {
      state.activeParams = action.payload;
    },
    removeActiveParams(state) {
      state.activeParams = {};
    },
    addOtherIndividualState(state, action) {
      state.otherState[action.payload[0]] = action.payload[1];
    },
    addOtherCompleteState(state, action) {
      state.otherState = action.payload;
    },
    addRiderData(state, action) {
      let obj = { ...state.otherState['riderDataSection'] };
      let tempObj = {};
      tempObj[action.payload[0]] = action.payload[1];
      let newObj = { ...obj, ...tempObj };
      state.otherState = { ...state.otherState, ['riderDataSection']: newObj };
    },
    CustomerAddRiderData(state, action) {
      let obj = { ...state.otherState['CustomerriderDataSection'] };
      let tempObj = {};
      tempObj[action.payload[0]] = action.payload[1];
      let newObj = { ...obj, ...tempObj };
      state.otherState = { ...state.otherState, ['CustomerriderDataSection']: newObj };
    },
    addWorkerSection(state, action) {
      let obj = { ...state.otherState['workerSection'] };
      let tempObj = {};
      tempObj[action.payload[0]] = action.payload[1];
      let newObj = { ...obj, ...tempObj };
      state.otherState = { ...state.otherState, ['workerSection']: newObj };
    },

    addOtherState(state, action) {
      state.otherState = action.payload;
    },
    removeOtherIndividualState(state, action) {
      delete state.otherState['workerSection'][action.payload];
    },
    removeOtherIndividualState1(state, action) {
      delete state.otherState['riderDataSection'][action.payload];
    },
    removeOtherState(state, action) {
      state.otherState = initialObj;
    },

    addUenData(state, action) {
      state.uenData = {};
      state.uenData = action.payload;

    },
    removeUenData(state) {
      state.uenData = {};
    },
    addPolicyData(state, action) {
      state.policy = action.payload;
    },
    removePolicyData(state) {
      state.policy = {};
    },

    removeAllRenewalData(state) {
      state.uenData = {};
      state.policy = {};
      state.otherState = initialObj;
      state.activeParams = {};
    },
    AddaddOnsData(state, action) {
      let obj = { ...state.otherState };
      let existedAddons = { ...obj.addOn };
      existedAddons = { ...existedAddons, [action.payload[0]]: action.payload[1] };
      let updatedState = { ...obj, ['addOn']: existedAddons };
      state.otherState = updatedState;
    },
    removeAddOnData(state, action) {
      let obj = { ...state.otherState };
      let existedAddons = { ...obj.addOn };
      delete existedAddons[action.payload];
      let updatedState = { ...obj, ['addOn']: existedAddons };
      state.otherState = updatedState;
    },
    setActiveStep(state, action) {
      state.activeSteps = action.payload;
    },
    setFlag(state, action) {
      state.flag = action.payload;
    },
    clearRiderDataSection(state) {
      state.otherState.riderDataSection = {};
    }
  }
});

export default premiumPolicy.reducer;

export const {
  addActiveParams,
  removeActiveParams,
  addOtherIndividualState,
  addOtherState,
  removeOtherIndividualState,
  removeOtherState,
  setSelectedAddons,
  addUenData,
  removeUenData,
  addWorkerSection,
  clearRiderDataSection,
  addOtherCompleteState,
  addRiderData,
  removeOtherIndividualState1,
  addPolicyData,
  removePolicyData,
  removeAllRenewalData,
  AddaddOnsData,
  removeAddOnData,
  setActiveStep,
  clearClaimsAmount ,
  CustomerAddRiderData,
  setFlag
} = premiumPolicy.actions;
