import { useRoutes } from 'react-router-dom';
import SamplePage from 'pages/extra-pages/SamplePage';
import ErrorPage from 'pages/extra-pages/ErrorPage';
import Routes from './routes';
import { useEffect } from 'react';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';

// ==============================|| ROUTING RENDER ||============================== //
let flag = false;

export default function ThemeRoutes(props) {
  const { makeRequestWithoutLogin } = useApiCall();

  const getAccessToken = async () => {
    try {
      const body = {
        grant_type: process.env.REACT_APP_GRANT_TYPE,
        refresh_token: process.env.REACT_APP_REFRESH_TOKEN,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET
      };
      const response = await makeRequestWithoutLogin({ method: 'POST', url: API.AccessTokenGet, body });
      if (response.code === 200) {
        const token = response?.data?.access_token;
        localStorage.setItem('AppToken', JSON.stringify(token));
      }
    } catch (error) {
      console.error('Failed to fetch access token:', error);
    }
  };
  useEffect(() => {
    const storedToken = localStorage.getItem('AppToken');
    if (!storedToken) {
      if (!flag) {
        getAccessToken();
      }
    }
  }, [props]);

  let routes = Routes();
  return useRoutes([
    routes,
    {
      path: '/*',
      element: <SamplePage />
    },
    {
      path: '/error',
      element: <ErrorPage />
    }
  ]);
}
