import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Set full height of the viewport
});

const TextContainer = styled('div')({
  textAlign: 'center',
});

const StyledButton = styled(Button)({
  marginTop: '20px', // 20px margin-top
});

const ErrorPage = ({ errorCode = '404', errorMessage = 'Page not found' }) => (
  <Container>
    <TextContainer>
      <Typography variant="h4" color="error">
        Error {errorCode}
      </Typography>
      <Typography variant="body1" style={{ marginTop: '16px' }}>
        {errorMessage}
      </Typography>
      <StyledButton variant="contained" component={Link} to="/">
        Go to Home
      </StyledButton>
    </TextContainer>
  </Container>
);

export default ErrorPage;
