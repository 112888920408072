import React from 'react';
import ReusableModal from 'components/modals/routemodal/ResuableModal';
import { Link as RouterLink } from 'react-router-dom';
import { Button, FormHelperText, Grid, Link, OutlinedInput, Stack, Box } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'components/@extended/AnimateButton';
import { useNavigate } from 'react-router-dom';
import logo from '../../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';

const SignupModal = ({ isOpen, onClose, setShow, setShowSignup,handleLoginClick }) => {
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  // const handleLoginClick = () => {
  //   setShowSignup(false);
  //   setShowLogin(true);
  // };

  return (
    <ReusableModal
      isOpen={isOpen}
      height="auto"
      showCloseButton={false}
      onClose={onClose}
      ModalContent={
        <Box p={3}>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6} textAlign="center">
              <img
                src={logo}
                alt="Anda Logo"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '12rem',
                  marginBottom: '10%'
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <h6 style={{ fontFamily: 'Medium', fontSize: 14, color: '#000000', textAlign: 'start' }}>Signup to ANDA</h6>
              <Formik
                initialValues={{
                  nric: '',
                  uenNo: '',
                  firstName: '',
                  lastName: '',
                  salesForceId: '',
                  companyName: '',
                  email: '',
                  nationality: '',
                  gender: '',
                  maritalStatus: '',
                  source: '',
                  mobile: '',
                  designation: '',
                  occupation: '',
                  licensePassDate: '',
                  street: '',
                  city: '',
                  state: '',
                  postalCode: '',
                  country: '',
                  remarks: '',
                  isActive: true,
                  agentMasterUserId: '',
                  insuranceStartDate: '',
                  insuranceEndDate: '',
                  opportunityType: '',
                  creaditTerm: true,
                  durationInMonths: '',
                  transactionType: '',
                  attributes: {
                    type: '',
                    url: ''
                  },
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().max(255).required('First Name is required'),
                  lastName: Yup.string().max(255).required('Last Name is required'),
                  nric: Yup.string().max(255).required('NRIC is required'),
                  email: Yup.string().email('Must be a valid Email address').max(255).required('Email is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                
                  try {
                    const body = {
                      nricNo: values.nric,
                      uenNo: '',
                      firstName: values.firstName,
                      lastName: values.lastName,
                      salesForceId: '',
                      companyName: '',
                      email: values.email,
                      nationality: '',
                      gender: '',
                      maritalStatus: '',
                      source: '',
                      mobile: '',
                      designation: '',
                      occupation: '',
                      licensePassDate: '',
                      street: '',
                      city: '',
                      state: '',
                      postalCode: '',
                      country: '',
                      remarks: '',
                      isActive: true,
                      agentMasterUserId: '',
                      insuranceStartDate: '',
                      insuranceEndDate: '',
                      opportunityType: '',
                      creaditTerm: true,
                      durationInMonths: '',
                      transactionType: '',
                      attributes: {
                        type: '',
                        url: ''
                      }
                    };

                    let signupResponse = await makeRequest({ method: 'POST', url: API.CustomerMasterInsertUpdate, body });
                    if (signupResponse.code === 200) {
                      showSuccess(signupResponse.message);
                      setShowSignup(false);
                      setShow(true) 
                      // setShowLogin(true); 
                    } else {
                      showWarning(signupResponse?.message);
                    }
                  } catch (e) {
                    showDanger(`${e}`);
                  }
                  setSubmitting(false);
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                      <OutlinedInput
                        sx={{ width: '22rem' }}
                        id="firstName"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="First Name"
                        error={Boolean(touched.firstName && errors.firstName)}
                      />
                      {touched.firstName && errors.firstName && <FormHelperText error>{errors.firstName}</FormHelperText>}

                      <OutlinedInput
                        sx={{ width: '22rem' }}
                        id="lastName"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Last Name"
                        error={Boolean(touched.lastName && errors.lastName)}
                      />
                      {touched.lastName && errors.lastName && <FormHelperText error>{errors.lastName}</FormHelperText>}

                      <OutlinedInput
                        sx={{ width: '22rem' }}
                        id="nric"
                        name="nric"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="NRIC"
                        error={Boolean(touched.nric && errors.nric)}
                      />
                      {touched.nric && errors.nric && <FormHelperText error>{errors.nric}</FormHelperText>}

                      <OutlinedInput
                        sx={{ width: '22rem' }}
                        id="email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Email address"
                        error={Boolean(touched.email && errors.email)}
                      />
                      {touched.email && errors.email && <FormHelperText error>{errors.email}</FormHelperText>}

                      <AnimateButton>
                        <Button
                          sx={{ width: '22rem' }}
                          style={{ backgroundColor: '#002d8c', fontFamily: 'Medium' }}
                          disableElevation
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Signup
                        </Button>
                      </AnimateButton>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Grid>
            <Grid item xs={12}>
              <Stack alignItems="left">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '5px' }}>
                    <p style={{ fontFamily: 'Medium', fontSize: 13, color: '#000000' }}>Already have an account</p>
                  </div>
                  <span
                    variant="h6"
                    component={RouterLink}
                    color="text.primary"
                    style={{
                      fontFamily: 'Medium',
                      fontSize: 13,
                      color: '#4868ac',
                      cursor: 'pointer'
                    }}
                    onClick= {handleLoginClick}
                  >
                    Login
                  </span>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

export default SignupModal;
