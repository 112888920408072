import OverlayLoader from 'components/loader/OverlayLoader';
import React, { useEffect, useState } from 'react';
import MotorVehicleCalculationView from './MotorVehicleCalculationView';
import { usePremiumCalculationState } from '../provider/PremiumCalculationProvider';
import ForeignWorkerCalculationView from './FoeignWorkerCalculationView';
import DomesticHelperCalculationView from './DomesticHelperCalculationView';

const PremiumCalculationTypes = React.memo(() => {
  const [renderComponent, setRenderComponent] = useState(<OverlayLoader show={true} />);
  const { params } = usePremiumCalculationState();

  useEffect(() => {
    if (params?.tsaType === 'Motor' || params?.opportunityType === 'Motor') setRenderComponent(<MotorVehicleCalculationView />);
    else if (params?.tsaType == 'Corporate' || params?.opportunityType === 'Corporate') setRenderComponent(<>Add Corporate Component Here</>);
    else if (params?.tsaType == 'Foreign Worker' || params?.opportunityType === 'Foreign Worker') setRenderComponent(<ForeignWorkerCalculationView/>);
    else if (params?.tsaType == 'Domestic Helper' || params?.opportunityType === 'Domestic Helper') setRenderComponent(<DomesticHelperCalculationView/>);
  }, []);

  return <>{renderComponent}</>;
});

export default PremiumCalculationTypes;
