import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, TextField, Grid, Typography, Paper } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import '../../../packages & premium/styles/SelectCustomerSideBar.css';
import { useTheme } from 'components/Theme/ThemeProvider';
import { useGetCustomerMasterbyNRICGetQuery } from 'store/services/VehicleDetails';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { API } from 'api/API';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApiCall from 'api/useApiCall';
import { activeItem } from 'store/reducers/menu';
import { store } from 'store/index';
import OverlayLoader from 'components/loader/OverlayLoader';
import { addOtherIndividualState, removeOtherState } from '../../../../store/reducers/premiumPolicy';
import { usePremiumCalculationState } from 'modules/packages & premium/provider/PremiumCalculationProvider';
const MotorAddCustomerSidebar = (props) => {
  const { young } = usePremiumCalculationState();
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  const { riderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const { otherState, uenData } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const [nric, setNric] = useState('');
  const [showList, setShowList] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [licenseDateError, setLicenseDateError] = useState('');
  const [isOwnerSelected, setIsOwnerSelected] = useState(false);
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setCustomerDetails({ ...customerDetails, email: email });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const validateNric = (nric) => {
    const nricPattern = /^[STFG]\d{7}[A-Z]$/;
    return nricPattern.test(nric);
  };

  const customerBody = {
    firstName: otherState['contact']?.firstName,
    lastName: otherState['contact']?.lastName,
    gender: '',
    email: otherState['contact']?.email,
    maritalStatus: '',
    source: '',
    mobile: otherState['contact']?.mobile || null,
    designation: '',
    occupation: '',
    licensePassDate: otherState['contact']?.licensePassDate,
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    remarks: '',
    nricNo: otherState['contact']?.nricNo
  };

  // if (riderDataSection) {
  //   Object?.values(riderDataSection)?.forEach((rider) => {
  //     if (rider?.isCheckboxChecked) {
  //       customerBody.firstName = rider.firstName;
  //       customerBody.lastName = rider.lastName;
  //       customerBody.mobile = rider.contactNumber;
  //       customerBody.email = rider.email;
  //       customerBody.nricNo = rider.nric;
  //       customerBody.licensePassDate = rider.license;
  //     }
  //   });
  //   setIsOwnerSelected
  // }
  useEffect(() => {
    if (riderDataSection) {
      Object.values(riderDataSection).forEach((rider) => {
        if (rider?.isCheckboxChecked) {
          setCustomerDetails({
            firstName: rider.firstName,
            lastName: rider.lastName,
            mobile: rider.contactNumber,
            email: rider.email || '',
            nricNo: rider.nric,
            licensePassDate: rider.license
          });
          setIsOwnerSelected(true);
        }
      });
    }
  }, [riderDataSection]);

  const [customerDetails, setCustomerDetails] = useState(customerBody);
  const { data } = useGetCustomerMasterbyNRICGetQuery({ nric: nric || null });

  const handleSubmitCustomer = async () => {
    setLoading(true);
    try {
      if (isButtonDisabled) return;

      setIsButtonDisabled(true);
      if (!customerDetails?.firstName || !customerDetails?.nricNo || !customerDetails.email) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please fill in all required fields');
      } else if (!validateEmail(customerDetails.email)) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please enter a valid email address');
      } else if (!validateNric(customerDetails.nricNo)) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please enter a valid NRIC');
      } else if (customerDetails?.licensePassDate < young?.dob) {
        setLoading(false);
        setIsButtonDisabled(false);
        setLicenseDateError('Driving license date should not be older than DOB');
      } else {
        const requestBodyRider = Object.values(riderDataSection)?.map((rider) => {
          return {
            firstName: rider?.firstName,
            lastName: rider?.lastName,
            nric: rider?.nric,
            dob: rider?.dob,
            gender: rider?.gender,
            drivingLicense: rider?.license,
            nationality: rider?.nationality,
            contact: rider?.contactNumber,
            email: rider?.email,
            // claimsAmount: rider?.claims,
            active: true,
            workPermitNo: '',
            passportNo: '',
            claimsIn3Year: 0,
            amountOfClaimsIn3Year: 0,
            age: rider?.age,
            experienceInMonths: rider?.experience,
            ageOfDate: '',
            experienceInMonthsOfDate: '',
            fin: '',
            isOwner:rider?.isCheckboxChecked,
            isRider:rider?.isRiderCheckboxChecked
          };
        });

        let isFoodParcel = otherState?.isFoodParcel;
        if (isFoodParcel === true) {
          isFoodParcel = 'yes';
        } else if (isFoodParcel === false) {
          isFoodParcel = 'no';
        }
        let finalAmount=parseInt(props?.apiData?.finalPremiumAmount)+parseInt(props?.apiData?.fdExtentionAmountWithoutGST)
        let body = {
          nric: uenData.nriC_FIN__c || customerDetails.nricNo,
          uen: null,
          isIndividual: otherState.uen === '' ? true : false,
          insuranceStart: otherState.POIStartDate,
          insuranceEnd: otherState.POIEndDate,
          opportunityType: 'Motor',
          portalUserId: props.apiData.userId,
          creditTerm: true,
          durationInMonths: '',
          transactionType: otherState.transactionType,
          companyRegistered: otherState.isCompanyRegistered,
          vehicleId: otherState.vehicleId,
          vrn: otherState.vehicleRegistration,
          coverageType: '',
          deliveryExtension: isFoodParcel,
          chasisNo: otherState.chasisNumber,
          engineNo: otherState.engineNumber,
          drivingLicenseDate: customerDetails.licensePassDate,
          excess: props.apiData.excessAmount,
          claimsDetails: otherState.claimDetails,
          nameOfHirePurchase: otherState.nameOfHirePurchase,
          workshopType: otherState.workShopType,
          // vehicleTonnage: otherState.vehicleTonnage || '0',
          ncdAmount: props.apiData.ncdAmount,
          ncd: '',
          // ncdProtector: '',
          reg_manufactureDate: '',
          bondDuration: '',
          reTransmissionReminder: null,
          reviewedByInsurer: false,
          basisOfCover: '',
          mainContractorName: '',
          cpfSubmissionNo: '',
          transmissionStatus: '',
          dischargeDate: null,
          loiStatus: '',
          bondCallStatus: '',
          loiType: '',
          contact: customerDetails,
          items: [
            {
              itemCode: props?.apiData?.productID,
              salesPrice: finalAmount,
              quantity: 1
            }
          ],
          insuredParty: requestBodyRider,
          productId: props.apiData.productID,
          loginDetailsId: props?.apiData?.loginDetailsId,
          customerPolicyId: props?.params?.customerPolicyId || null,
          opportunityId: props?.params?.opportunityId || null,
          totalPremiumAmount:props?.apiData.finalTotalPremiumAmount||0
        };
        if (Object.keys(uenData).length > 0) {
          body['account'] = {
            accName: uenData.name,
            accEmail: uenData.email_Address__c,
            phone: uenData.phone?.toString(),
            source: uenData.accountSource,
            bankAccountName: uenData.bank_Account_Name__c,
            bankCode: uenData.bank_Code__c,
            bankName: uenData.bank_Name__c,
            bankAccountNo: uenData.bank_Account_No__c,
            street: uenData.billingStreet,
            city: uenData.billingCity,
            state: uenData.billingState,
            postalCode: uenData.billingPostalCode,
            country: uenData.billingCountry
          };
        } else {
          body['account'] = {
            accName: otherState.UEMSTATUS == null ? '' : otherState.cm,
            accEmail: otherState.UEMSTATUS == null ? '' : otherState.email,
            phone: otherState.UEMSTATUS == null ? '' : otherState.phone?.toString(),
            source: '',
            bankAccountName: '',
            bankCode: '',
            bankName: '',
            bankAccountNo: '',
            street: '',
            city: '',
            state: '',
            postalCode: '',
            country: ''
          };
        }


        const response = await makeRequest({ method: 'POST', url: API.FinalSubmissionCustomerPortalForFWDHAPI, body });
        if (response?.code == 200) {
          showSuccess(response?.data?.message);
          props.setShow(false);
          store.dispatch(activeItem({ openItem: ['policies'] }));
          setLoading(true);
          navigate('/dashboard/Policies');
        } else {
          setIsButtonDisabled(false);
          setLoading(false);
          showDanger('Policy Not Created');
        }
      }
    } catch (error) {
      console.error(error);

    }
  };

  const handleSearch = (e) => {
    setShowList(true);
    // refetch();
    const searchTerm = e?.target?.value;
    setNric(searchTerm);

    setCustomerDetails({
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      nricNo: '',
      licensePassDate: ''
    });
  };

  const handleNameClick = (name) => {
    setShowList(false);
    if (name) {
      setNric(name?.nricNo);
      setCustomerDetails({
        firstName: name?.firstName,
        lastName: name?.lastName,
        mobile: name?.mobilePhone,
        finance_Email__c: name?.finance_Email__c,
        nricNo: name?.nricNo,
        licensePassDate: name?.licensePassDate
      });
    }
  };
  function handleNumericKeyPress(event) {
    const keyPressed = event.key;
    if (!/^\d$/.test(keyPressed) && !event.ctrlKey && !event.metaKey && !event.altKey) {
      event.preventDefault();
    }
  }
  return (
    <Grid className="topContainer_SelectCustomerSideBar">
      <Grid className="heading_SelectCustomerSideBar">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Selected Customer
        </Typography>
      </Grid>

      <hr />

      <Grid className="searchbarGrid_SelectCustomerSideBar">
        <TextField
          placeholder="Search"
          className="textfield_SelectCustomerSideBar"
          variant="standard"
          size="small"
          value={nric}
          onChange={handleSearch}
          InputProps={{
            disableUnderline: true,
            endAdornment: <SearchIcon color="action" className="inputPropStyle_SelectCustomerSideBar" />
          }}
        />
      </Grid>
      {showList && data != '' && (
        <Paper
          style={{
            padding: '2%',
            margin: '0.5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            width: '100%',
            position: 'absolute',
            zIndex: 4,
            maxHeight: '30%',
            overflowY: 'auto'
          }}
        >
          {data?.map((item, index) => (
            <Grid item onClick={() => handleNameClick(item)} style={{ width: '100%' }}>
              <Typography key={index} style={{ width: '100%', cursor: 'pointer' }}>
                {item?.nricNo}
              </Typography>
              <hr style={{ width: '100%', border: 'none', borderBottom: '1px solid lightgray' }} />
            </Grid>
          ))}
        </Paper>
      )}

      <Grid className="stylinghrtags_SelectCustomerSideBar">
        <Grid style={{ width: '20%', marginRight: '2%' }}>
          <hr />
        </Grid>
        <p style={{ marginTop: '1%' }}>Or</p>
        <Grid style={{ width: '20%', marginLeft: '2%' }}>
          <hr />
        </Grid>
      </Grid>

      <Grid className="addCustomersGridStyling">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Add Customer
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className="allinputpositioningstyling">
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>First Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.firstName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, firstName: e.target.value })}
              disabled={isOwnerSelected}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Last Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.lastName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, lastName: e.target.value })}
              disabled={isOwnerSelected}
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Phone Number</InputLabel>
              {/* <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel> */}
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.mobile}
              onKeyPress={handleNumericKeyPress}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              style={{ marginTop: 5 }}
              onChange={(e) => setCustomerDetails({ ...customerDetails, mobile: e.target.value })}
              disabled={isOwnerSelected}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Email Address</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              type="email"
              size="small"
              variant="standard"
              value={customerDetails?.email}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={handleEmailChange}
              disabled={isOwnerSelected}
            />
          </Grid>

          {/* Last Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>NRIC</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              placeholder="e.g. S3272118D"
              value={customerDetails?.nricNo}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, nricNo: e.target.value })}
              disabled={isOwnerSelected}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Driving License Date</InputLabel>
              {/* <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel> */}
            </div>

            <TextField
              type="date"
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px', marginTop: 5 }}
              value={customerDetails?.licensePassDate}
              onChange={(e) => {
                setCustomerDetails({ ...customerDetails, licensePassDate: e.target.value });
                if (licenseDateError) {
                  setLicenseDateError('');
                }
              }}
              required
              inputProps={{
                max: new Date().toISOString().split('T')[0]
              }}
              disabled={isOwnerSelected}
              
            />
          </Grid>
          {/* <Grid item xs={12}> */}
          <Grid xs={12}>
            <div style={{ height: '1.5em', marginLeft: '20px' }}>
              {licenseDateError && <p style={{ color: 'red', fontFamily: 'Medium' }}>{licenseDateError}</p>}
            </div>
          </Grid>
          {/* </Grid> */}
        </Grid>
      </Grid>

      <Grid>
        <Button
          size="small"
          variant="contained"
          disabled={isButtonDisabled}
          // className="ProceedToPayButton"
          style={{
            borderRadius: '8px',
            color: isButtonDisabled ? '#000' : '#fff',
            backgroundColor: isButtonDisabled ? '#dfe6e9' : '#002D8C',
            minWidth: '100%',
            marginRight: '10px',
            padding: '2%',
            fontFamily: 'Medium'
          }}
          onClick={() => handleSubmitCustomer()}
        >
          Complete Purchase
        </Button>
      </Grid>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
          <OverlayLoader show={true} />
        </div>
      )}
      {/* <PaymentRedirectModal isOpen={isOpen} setIsOpen={setIsOpen} handleCloseModal={handleClosePaymentRedirect} />
      <Successmodal
        isOpen={show}
        setIsOpen={setShow}
        handleCloseModal={handleThankYouButton}
        title={'Policy Purchased Successfully'}
        subtitle={"Congratulations! Your policy purchase is now complete and we've thrilled to have you as part of our community"}
      /> */}
    </Grid>
  );
};

export default MotorAddCustomerSidebar;
