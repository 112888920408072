import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { API } from 'api/API';
import { store } from 'store/index';
import { addOtherIndividualState, addUenData, removeUenData } from 'store/reducers/premiumPolicy';
import useApiCall from '../../../api/useApiCall';

const useUenVerification = () => {
  const { makeRequest } = useApiCall();
  const handleVerifyClick = async (isFlag, uen) => {
    try {
      let params = { uENNo: uen };
      let response = await makeRequest({ method: 'GET', url: API.CompanyUENNoWiseGet, params: params });
      
      if (response.code === 200) {
        store.dispatch(addOtherIndividualState(['UEMSTATUS', true]));
        store.dispatch(addUenData(response.data));
      } else {
        store.dispatch(addOtherIndividualState(['UEMSTATUS', false]));
        store.dispatch(removeUenData(response.data));
      }
    } catch (error) {
      console.error("Error in handleVerifyClick"+error);

    }
  };
  

  return { handleVerifyClick };
};

export default useUenVerification;
