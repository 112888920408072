import React from 'react';
import { Grid } from '@mui/material';
import '../styles/InformationForm.css';
import MotorVehicleProductInformation from './motar/MotorVehicleProductInformation';
import MotorVehiclePolicyInformation from './motar/MotorVehiclePolicyInformation';
import MotorVehicleInformation from './motar/MotorVehicleInformation';
import MotarRiderInformation from './motar/MotorRiderInformation';
import MotorVehicleBottomPortion from './motar/MotorVehicleBottomPortion';

const MotorVehiclePremiumView = React.memo(() => {
  return (
    <Grid container xs={12} width="100%" ml={2}>
      <MotorVehicleProductInformation  />
      <MotorVehiclePolicyInformation  />
      <MotorVehicleInformation  />
      <MotarRiderInformation  />
      <MotorVehicleBottomPortion  />
    </Grid>
  );
});

export default MotorVehiclePremiumView;
