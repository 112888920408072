import React from 'react';
import { Grid, Button } from '@mui/material';
import OverlayLoader from 'components/loader/OverlayLoader';

export const HorizontalContainer = ({ data, sizes, onClick }) => {


  const formatValue = (value) => {
   
    if (typeof value === 'string' && !isNaN(parseFloat(value))) {
      
      return parseFloat(value).toFixed(2);
    }
    
    return value;
  };

  const isPriceKey = (key) => key.toLowerCase().includes('price');

  return (
    <Grid item xs={12} style={{ marginBottom: '15px' }}>
      <div style={{ borderRadius: 15, padding: '20px', border: '1px solid lightgrey', backgroundColor: '#fff' }}>
        <Grid container>
          {Object.keys(data).map((item, index) => {
    
            return (
              <Grid item xs={12} sm={sizes[index] || 4} key={index} style={{ marginTop: '8px' }}>
                <div>
                  <p
                    style={{
                      fontFamily: 'Medium',
                      marginBottom: '4px',
                      marginLeft: '5%',
                      display: 'flex',
                      justifyContent: 'left',
                      fontWeight: '600',
                      fontSize: '16px'
                    }}
                  >
                    {item}
                  </p>
                  <p
                    style={{
                      fontFamily: 'Medium',
                      fontWeight: '500',
                      fontSize: 13,
                      display: 'flex',
                      marginBottom: '4px',
                      marginLeft: '5%',
                      justifyContent: 'left'
                    }}
                  >
                    {isPriceKey(item) ? `$ ${formatValue(data[item])}` : formatValue(data[item])}
                  </p>
                </div>
              </Grid>
            );
          })}
          <Grid item xs={12} sm={3} md={3} alignItems="center" display="flex" justifyContent="end">
            <Button
              fullWidth
              size="small"
              variant="contained"
              style={{ backgroundColor: '#002D8C', width: 'auto', display: 'flex', justifyContent: 'end' }}
              onClick={onClick}
            >
              Calculate Premium
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
