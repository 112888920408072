import React, { useEffect, useState } from 'react';
import CardLayout from '../CardLayout';
import { Grid, TextField, InputLabel } from '@mui/material';
import '../../styles/InformationForm.css';
import DropDown from 'components/dropdown/DropDown';
import { useDomesticWorkerInformation } from 'modules/packages & premium/hooks/useDomesticHelper';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import { useSelector } from 'react-redux';

const RenderFunction = ({ item, otherState, dropdownData }) => {
  const [value, setValue] = useState(otherState[item?.value]);

  const onChangeText = (txt, value) => {
    const allowedChars = /^[a-zA-Z0-9 ]*$/;
    const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
    const currentDate = new Date().toISOString().split('T')[0];
    try {
      if (item?.texttype === 'date') {
        if (allowedCharsForDate.test(txt)) {
          if (txt <= currentDate) {
            setValue(txt);
            store.dispatch(addOtherIndividualState([value, txt]));
          } else {
            console.warn('Invalid date format. Please enter a date not greater than today.');
            setValue(currentDate); 
            store.dispatch(addOtherIndividualState([value, currentDate]));
          }
        } else {
          console.warn('Invalid date format. Please enter in YYYY-MM-DD format.');
        }
      } else {
        if (allowedChars.test(txt)) {
          setValue(txt);
          store.dispatch(addOtherIndividualState([value, txt]));

        } else {
        }
      }
    } catch (e) {
      console.error(e);

    }
  };
  
  
 

  const onValueChange = (selectedItem) => {
    try {
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error('Error during onChange --', e);
    }
  };

  return (
    <Grid item xs={12} md={4} alignItems={'center'}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <InputLabel className="inputs_styless_">{item.label}</InputLabel>
        {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px' }}>*</div>}
      </div>
      {item?.type == 'textField' && (
        <div style={{ marginTop: '2px' }}>
          <TextField
            type={item?.texttype}
            disabled={item?.isEnable}
            onChange={(txt) => onChangeText(txt.target.value, item?.value)}
            // onInput={handleInput}
            required={item?.isRequired}
            placeholder={item.value === 'fin' ? 'e.g. S3272118D' : ''}
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            className="txtinputs_stylingg"
            value={value}
            inputProps={{
              pattern: /^\d{4}$/, // Allow only four digits for year
              max: new Date().toISOString().split('T')[0] // Set minimum date (optional)
            }}
          />
        </div>
      )}
      {item?.type == 'dropdown' && (
        <DropDown
          width={'96%'}
          heightsss={'38px'}
          backgroundColor="#F8FAFB"
          options={dropdownData}
          onValueChange={onValueChange}
          defaultValue={otherState[item?.value]}
        />
      )}
    </Grid>
  );
};

const DomesticHelperWorkerInformation = React.memo((props) => {
  const { data } = useDomesticWorkerInformation();
  const [verificationMessage, setVerificationMessage] = useState('');
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const getCountriesListData = props.getCountriesListData;

  const [getUpdatedCountriesListData, setUpdatedCountriesListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };



  useEffect(() => {
    const updatedCountriesListData = getCountriesListData.map((country) => ({
      ...country,
      value: 'nationalityDHInformation'
    }));
    setUpdatedCountriesListData(updatedCountriesListData);
  }, [getCountriesListData]);
 

  return (
    <CardLayout toggleForm={toggleForm} isOpen={isOpen} setIsOpen={setIsOpen} heading="Domestic Helper Information">
      {data.map((item, index) => (
        <RenderFunction
          item={item}
          index={index}
          setVerificationMessage={setVerificationMessage}
          verificationMessage={verificationMessage}
          otherState={otherState}
          dropdownData={getUpdatedCountriesListData}
        />
      ))}
    </CardLayout>
  );
});

export default DomesticHelperWorkerInformation;
