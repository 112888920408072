import {StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import * as serviceWork from './serviceWorkerRegistration';
import { store, persistor } from '../src/store/index';
import OverlayLoader from './components/loader/OverlayLoader';
import App from './App';
import 'simplebar/src/simplebar.css';
import 'assets/third-party/apex-chart.css';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
  <StrictMode>
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/">
        <Suspense fallback={<OverlayLoader />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </ReduxProvider>
 </StrictMode>
);

serviceWork.register();

reportWebVitals();
