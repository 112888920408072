import React, { useEffect, useState } from 'react';
import CardLayout from '../CardLayout';
import { Grid, TextField, InputLabel, Typography, Button } from '@mui/material';
import '../../styles/InformationForm.css';
import DropDown from 'components/dropdown/DropDown';
import { useDomesticHelperInsurance, useDomesticHelperInsuranceDetails } from 'modules/packages & premium/hooks/useDomesticHelper';
import { usePackagePremiumState } from 'modules/packages & premium/provider/PackagePremiumStateProvider';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import { useSelector } from 'react-redux';
import useUenVerification from '../../hooks/useUenVerification';
import { useDropdown } from 'modules/packages & premium/hooks/useForeignWorker';

const RenderFunction = ({ item, index, otherState, verificationMessage, handleVerifyClick }) => {
  const [value, setValue] = useState(otherState[item?.value]);
  const { params } = usePackagePremiumState();

  useEffect(() => {
    if (item?.label == 'Product Name') {
      setValue(params?.productName);
    }
  }, [otherState, params]);

  const onChangeText = (txt, value) => {
    try {
      store.dispatch(addOtherIndividualState([value, txt]));
      setValue(txt);
    } catch (e) {
      console.error('Error during change text inside motorVehiclePolicyInformation ->', e);
    }
  };

  const onValueChange = (selectedItem) => {
    try {
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error('Error during onChange --', e);
    }
  };

  useEffect(() => {
    if (item?.label === 'To date' && otherState && params.duration) {
      const fromDate = otherState?.fromdateDHInsurance;
      const numOfMonths = parseInt(params.duration);

      if (fromDate && numOfMonths) {
        const fromDateObj = new Date(fromDate);
        const newDate = new Date(fromDateObj.setMonth(fromDateObj.getMonth() + numOfMonths));
        const toDate = newDate.toISOString().slice(0, 10);
        store.dispatch(addOtherIndividualState(['todateDHinsurance', toDate]));
        setValue(toDate);
      }
    }
  }, [otherState, params.duration]);

  useEffect(() => {
    if (item.value === 'UENDHInsurance' && !otherState[item.value]) {
      store.dispatch(addOtherIndividualState(['UEMSTATUS', null]));
      store.dispatch(addOtherIndividualState(['compnaynameDHInsurance', '']));
      store.dispatch(addOtherIndividualState(['emailDHInsurance', '']));
      store.dispatch(addOtherIndividualState(['phoneDHInsurance', '']));
    }
  }, [otherState, item]);

  let isFlagm = otherState['UEMSTATUS'] === false ? true : item.isFlag;
  const isLabelVisible = item.type !== 'dropdown' || item?.ddoptions?.length > 0;

  return (
    <>
      {isFlagm && (
        <Grid item xs={12} md={index === 0 ? 3 : 4} alignItems="center">
          {isLabelVisible && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <InputLabel className="inputs_styless_">{item.label}</InputLabel>
              {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-10px' }}>*</div>}
            </div>
          )}

          {item.type === 'textField' && isFlagm ? (
            <TextField
              type={item?.texttype}
              disabled={item?.value == 'uen' && otherState['navigateFrom'] == 'renewal' ? true : item?.isEnable}
              onChange={(txt) => onChangeText(txt.target.value, item?.value)}
              required={item?.isRequired}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="txtinputs_stylingg"
              value={value}
              style={{ marginTop: '13px' }}
              inputProps={{
                pattern: /^\d{4}$/,
                min: new Date().toISOString().split('T')[0]
              }}
            />
          ) : (
            <></>
          )}
          {index === 0 && value?.length > 0 && (
            <Typography variant="body2" style={{ color: otherState['UEMSTATUS'] ? 'lightgreen' : 'red', marginLeft: '4px' }}>
              {otherState['UEMSTATUS'] == null ? '' : otherState['UEMSTATUS'] ? ' This UEN is verified.' : 'This UEN is not registered.'}
            </Typography>
          )}
          {item.type == 'dropdown' && item?.ddoptions.length > 0 && (
            <DropDown
              width={'96%'}
              heightsss={'38px'}
              options={item?.ddoptions || []}
              onValueChange={onValueChange}
              defaultValue={otherState[item?.value]}
            />
          )}
        </Grid>
      )}
      {index === 0 && otherState['navigateFrom'] == undefined && (
        <Grid item xs={12} md={1} mt={verificationMessage ? 0 : 4}>
          <Button
            variant="contained"
            size="small"
            disabled={value == 0 ? true : false}
            style={{
              backgroundColor: value == 0 ? 'grey' : '#4CB748',
              color: value > 0 && '#FFF'
            }}
            onClick={() => handleVerifyClick()}
          >
            Verify
          </Button>
        </Grid>
      )}
    </>
  );
};

const DomesticHelperInsurance = React.memo((props) => {
  const { data } = useDomesticHelperInsurance();
  const [verificationMessage, setVerificationMessage] = useState('');
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const dropdownData = props.data;
  const { handleVerifyClick } = useUenVerification();
  const [isOpen, setIsOpen] = useState(true);
  const { data: dropdownOptions, isLoading } = useDropdown(data, dropdownData);
  useDropdown(data);
  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CardLayout isOpen={isOpen} setIsOpen={setIsOpen} toggleForm={toggleForm} heading="Domestic Helper Insurance">
      {dropdownOptions.map((item, index) => (
        <RenderFunction
          key={index}
          item={item}
          index={index}
          setVerificationMessage={setVerificationMessage}
          verificationMessage={verificationMessage}
          otherState={otherState}
          dropdownData={dropdownData}
          handleVerifyClick={handleVerifyClick}
        />
      ))}
    </CardLayout>
  );
});

export default DomesticHelperInsurance;
