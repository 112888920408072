import React from 'react';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import PremiumCalculationTypes from '../components/PremiumCalculationTypes';
import PremiumCalculateSubHeader from '../components/PremiumCalculateSubHeader';
import PremiumCalculationStateProvider, { usePremiumCalculationState } from '../provider/PremiumCalculationProvider';

const ActiveComponents = React.memo(() => {
  const {params} = usePremiumCalculationState();

  return (
    <>
      {params && (
        <Grid height={'120vh'}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2.75}
            alignItems="center"
            justifyContent="space-between"
            style={{ backgroundColor: '#fff' }}
          >
            <PremiumCalculateSubHeader isRightEnable={false} />
            <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />
            <Grid
              container
              rowSpacing={2}
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'row', marginTop: '-20px', justifyContent: 'space-between' }}>
              <PremiumCalculationTypes params={params} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
});

const PremiumCalculation = () => {
  const location = useLocation();
  const {apiData, young } = location?.state || {};

  return (
    <PremiumCalculationStateProvider params={location.state.data} apiData={apiData} young={young}>
      <ActiveComponents />
    </PremiumCalculationStateProvider>
  );
};

export default PremiumCalculation;
