// assets
import { LoginOutlined, ProfileOutlined } from '@ant-design/icons';
import { LogoutOutlined, HomeOutlined, TextSnippetOutlined, AccountCircleOutlined } from '@mui/icons-material';

const icons = {
  LoginOutlined,
  ProfileOutlined,
  HomeOutlined,
  LogoutOutlined,
  TextSnippetOutlined,
  AccountCircleOutlined
};

function createMenuItem({ id, title, icon, url = [] }) {
  return {
    id,
    title,
    type: 'item',
    icon: icons[icon],
    url: url,
    breadcrumbs: true
  };
}

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const loginData = localStorage?.getItem('@login');
const data = JSON.parse(loginData);
const isFinanceAccess = data?.finance_Access__c;

const pages = {
  id: 'ANDA',
  title: 'ANDA',
  type: 'group',
  children: [
    {
      id: 'dashboard1',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard/default',
      icon: icons.HomeOutlined
    },
    createMenuItem({
      id: 'policies',
      title: 'My Policies',
      icon: 'TextSnippetOutlined',
      url: '/dashboard/Policies'
    }),
    createMenuItem({
      id: 'Premium',
      title: 'Packages & Premium',
      icon: 'TextSnippetOutlined',
      url: '/dashboard/Premium'
    }),
    createMenuItem({
      id: 'Profile',
      title: 'My Profile',
      icon: 'AccountCircleOutlined',
      url: '/dashboard/Profile'
    }),

    isFinanceAccess &&
      createMenuItem({
        id: 'IncorporateFinance',
        title: 'Finance',
        icon: 'AccountCircleOutlined',
        url: '/dashboard/IncorporateFinance'
      }),

    createMenuItem({ id: 'logout', title: 'Logout', icon: 'LogoutOutlined', url: '/login' })
  ]
};

export default pages;
