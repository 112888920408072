import '../styles/InformationForm.css';
import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useSelector } from 'react-redux';

const CardLayout = ({
  heading = 'Heading',
  children,
  headerButtonTitle,
  isTopButtonVisible = false,
  handleClickOnTopButton = () => {},
  setIsOpen,
  isOpen,
  toggleForm = () => {}
}) => {
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const isCompanyRegistered = otherState?.isCompanyRegistered;
  return (
    <div className="containerrr">
      <div className={`form-headerrr ${isOpen ? 'open' : 'closed'}`}>
        <span
          onClick={() => toggleForm()}
          style={{ width: '85%', marginLeft: '10px', fontFamily: 'Medium', color: '#000', fontSize: 15, backgroundColor: '#ffffff' }}
        >
          {heading}
        </span>
        <span style={{ marginRight: '15px' }}>
          {isTopButtonVisible && (
            <Button
              size="medium"
              onClick={() => handleClickOnTopButton()}
              style={{  backgroundColor: isCompanyRegistered ? 'grey' : '#00C7B1', color: 'white', fontFamily: 'Medium' }}
              disabled={isCompanyRegistered}
            >
              {headerButtonTitle}
            </Button>
          )}
          {isOpen ? (
            <KeyboardArrowUpOutlinedIcon style={{ fontSize: '36px', color: '#ABACB0' }} onClick={() => toggleForm()} />
          ) : (
            <KeyboardArrowDownOutlinedIcon style={{ fontSize: '36px', color: '#ABACB0' }} onClick={() => toggleForm()} />
          )}
        </span>
      </div>

      {isOpen && (
        <form className="form-bodyy">
          <Grid container spacing={2}>
            {children}
          </Grid>
        </form>
      )}
    </div>
  );
};

export default CardLayout;
