import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import logo from '../../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';
import '../styles/NavBar.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import CustomerLoginModal from './CustomerLoginModal';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { activeItem, navRoutesOptions } from 'store/reducers/menu';
import Rightsidebar from 'components/rightsidebar/Rightsidebar';
import NavBarSidebar from './NavBarSidebar';
import CustomerResetPassword from 'modules/customer/customerpasswordreset/CustomerResetPassword';
import CustomerOtpModal from 'modules/customer/customerOTP/CustomerOtpModal';
import OTPResetModal from 'components/modals/contentmodal/OTPResetModal';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { updateUserAuth } from 'store/reducers/auth';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import useNetworkStatus from 'hooks/useNetworkStatus';

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { navRoutesOption } = useSelector((state) => state.persistedReducer.menu);
  const { isUserLogin, user } = useSelector((state) => state.persistedReducer.auth);
  const [selectedRoute, setSelectedRoute] = useState(location.pathname);
  const network = useNetworkStatus();
  const [show, setShow] = useState(false);
  const [isMobileOrTab, setIsMobileOrTab] = useState(false);
  const [showNavbarSidebar, setShowNavbarSidebar] = useState(false);
  const [resetShow, setResetShow] = useState(false);
  const [otpshow, setOtpShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [emailId, setEmailId] = useState('');
  const { makeRequestWithoutLogin } = useApiCall();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTab(window.innerWidth < 920);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleOptions = () => {
    setShowNavbarSidebar(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location.pathname]);

  const handleOptionClick = (route) => {
    setSelectedRoute(route);
    navigate(route);
  };
  const closeSidebar = () => {
    setShowNavbarSidebar(false);
  };

  const handleResetPasswordModalOpen = () => {
    setResetShow(true);
    setShow(false);
  };

  const handleCloseResetModal = () => {
    setIsOpen(false);
    setShow(true);
  };

  const handleOTP = ({ OTP, UserId, emailId }) => {
    setEmailId(emailId);
    setUserId(UserId);
    setResetShow(false);
    setOtpShow(true);
  };

  const handleVerifyOtp = () => {
    setOtpShow(false);
    setIsOpen(true);
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem('@user');
      localStorage.removeItem('@login');
      showSuccess('Logout successfully');
      dispatch(updateUserAuth({ status: false, data: {} }));
      dispatch(navRoutesOptions({ navRoutesOption: '/' }));
      navigate('/');
    } catch (e) {
      showDanger(e);
    }
  };

  const handleClickOnResendOTP = async () => {
    if (network) {
      try {
        let params = {
          UserId: userId,
          emailId: emailId
        };
        let res = await makeRequestWithoutLogin({ method: 'POST', url: API.PasswordChangeOTPSent, params });
        if (res?.code == 200) {
          showSuccess('OTP sent successfully');
        } else {
          showWarning(res?.message);
        }
      } catch (e) {
        showDanger(`${e}`);
      }
    } else {
      showDanger('No internet');
    }
  };


  return (
    <Grid container className="navbar-container" alignItems="center" style={{ padding: '10px' }}>
      {isMobileOrTab && (
        <Grid item xs={1} sm={isMobileOrTab ? 1 : 5} md={0} lg={0} alignItems="center">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <MenuIcon style={{ cursor: 'pointer' }} onClick={toggleOptions} />
          </div>
        </Grid>
      )}
      <Grid item xs={4} sm={isMobileOrTab ? 2 : 2} md={3} lg={3.25} justifyContent="center" alignItems="center">
        <div
          className={`img-containerrr-navbarrr ${navRoutesOption === '/' ? 'selectedddd-route-text' : ''}`}
          onClick={() => handleOptionClick('/')}
          style={{ cursor: 'pointer' }}
        >
          <img src={logo} alt="LOGO" className="imagesizedifferscreen_navbarr" />
        </div>
      </Grid>
      {!isMobileOrTab && (
        <Grid item xs={0} sm={7} md={6} lg={5.5} alignItems="center">
          <div className="text-containerrrr-navbarrrr">
            <Typography
              className={`optionsss-styling-navbarrr ${selectedRoute === '/' ? 'selectedddd-route-text' : ''}`}
              onClick={() => handleOptionClick('/')}
            >
              Home
            </Typography>
            <Typography
              className={`optionsss-styling-navbarrr ${selectedRoute === '/DomesticHelper' ? 'selectedddd-route-text' : ''}`}
              onClick={() => handleOptionClick('/DomesticHelper')}
            >
              Domestic Helper
            </Typography>
            <Typography
              className={`optionsss-styling-navbarrr ${selectedRoute === '/MotorVehicle' ? 'selectedddd-route-text' : ''}`}
              onClick={() => handleOptionClick('/MotorVehicle')}
            >
              Motor Vehicle
            </Typography>
            <Typography
              className={`optionsss-styling-navbarrr ${selectedRoute === '/ForeignWorkers' ? 'selectedddd-route-text' : ''}`}
              onClick={() => handleOptionClick('/ForeignWorkers')}
            >
              Foreign Workers
            </Typography>

            {/* <Typography
              className={`optionsss-styling-navbarrr ${navRoutesOption === '/Corporate' && !fromLogin ? 'selectedddd-route-text' : ''}`}
              // onClick={() => handleOptionClick('/Corporate')}
            >
              Life Style
            </Typography> */}
          </div>
        </Grid>
      )}
      <Grid item xs={7} sm={isMobileOrTab ? 9 : 3} md={3} lg={3.25} alignItems="center">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: isMobileOrTab ? 'end' : 'end', marginRight: '5%' }}>
          {isUserLogin ? (
            <>
              <Button size="small" className="buttonStyling_navbarrr" onClick={() => navigate('/ProfileView')}>
                Profile
              </Button>
              <Button size="small" variant="contained" className="buttonStyling_navbarrr_agentportal" onClick={() => handleLogout()}>
                Logout
              </Button>
            </>
          ) : (
            <Button size="small" className="buttonStyling_navbarrr" onClick={() => setShow(true)}>
              Customer Login
            </Button>
          )}

          {!isUserLogin && (
            <Button size="small" variant="contained" className="buttonStyling_navbarrr_agentportal" onClick={() => navigate('/login')}>
              Agent Login
            </Button>
          )}
        </div>
      </Grid>

      <CustomerLoginModal
        isOpen={show}
        setShow={setShow}
        onClose={handleClose}
        handleResetPasswordModalOpen={handleResetPasswordModalOpen}
      />
      <CustomerResetPassword isOpen={resetShow} onClose={() => setResetShow(false)} handleOTP={handleOTP} />
      <CustomerOtpModal
        isOpen={otpshow}
        onClose={() => setOtpShow(false)}
        handleVerifyOtp={handleVerifyOtp}
        handleClickOnResendOTP={handleClickOnResendOTP}
      />
      <OTPResetModal isOpen={isOpen} handleCloseModal={handleCloseResetModal} />
      {
        <Rightsidebar
          Rcontent={<NavBarSidebar />}
          show={showNavbarSidebar}
          closeSidebar={closeSidebar}
          maxWidth="250px"
          placement={'start'}
          borderTopLeftRadius={0}
          borderBottomLeftRadius={0}
        />
      }
    </Grid>
  );
};

export default NavBar;
