import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import '../authentication/Login.css';
import logo from '../../assets/images/OneDrive_2024-05-10/png file/ANDA_AgentLogin.jpg';
import VerifyOtp from './auth-forms/VerifyOtp';
import OTPResetModal from 'components/modals/contentmodal/OTPResetModal';
import { useNavigate } from 'react-router-dom';
// ================================|| LOGIN ||================================ //

const VerifyOtpForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () =>{
    try{
      setIsOpen(false);
      navigate(-2, { replace: true });
    }catch(e){
      console.error("Error in handleCloseModal"+e);

    }
  }

  return (
    <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={7}  container justifyContent="center" alignItems="center">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '325px' }}>
        <VerifyOtp setIsOpen={setIsOpen}/>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={5} container justifyContent="center" alignItems="center">
        <img src={logo} alt="Login" style={{ width: '100%', height: '100%', maxWidth: '500px' }} />
      </Grid>
      {isOpen && <OTPResetModal isOpen={isOpen} handleCloseModal={handleCloseModal}/>}
    </Grid>

  
  );
};

export default VerifyOtpForm;
