import React, { useEffect, useState } from 'react';
import { Grid, TextField, InputLabel } from '@mui/material';
import { getUserDetails } from 'helper/utils';
export default function ProfileInformationForm() {
  const [userCustomerData, setUserCustomerData] = useState(null);

  useEffect(() => {
    (async () => {
      let res = await getUserDetails();
      setUserCustomerData(res);
    })();
  }, []);
  const profileCustomerData = [
    { label: 'First name', value: userCustomerData?.firstName, type: 'textfield' },
    { label: 'Last name', value: userCustomerData?.lastName, type: 'textfield' },
    { label: 'Email address', value: userCustomerData?.finance_Email__c, type: 'textfield' },
    { label: 'Phone number', value: '', type: 'textfield' },
    { label: 'Date of birth', value: '', type: 'textfield' },
    { label: 'Gender', value: '', type: 'textfield' },
    { label: 'Member since', value: '', type: 'textfield' }
  ];
  return (
    <div>
      {' '}
      <form className="form-bodyy" style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
        <Grid container spacing={2}>
          {profileCustomerData.map((data, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} md={4} alignItems={'center'}>
                <InputLabel className="inputs_styless_">{data.label}</InputLabel>
                <TextField
                  size="small"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  className="txtinputs_stylingg"
                  value={data.value}
                  disabled={true}
                />
              </Grid>
              {(index + 1) % 3 === 0 && <div style={{ width: '100%', height: 0 }} />}
            </React.Fragment>
          ))}
        </Grid>
      </form>
    </div>
  );
}
