import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DomesticHelperPlansTable from 'modules/customer/domestic helper policy view/components/DomesticHelperPlansTable';
import { Grid, Button, TextField, IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// const pointData = [
//   { point: 'Security bond of $5,000 to Ministry of Manpower' },
//   { point: '24 hour worldwide personal accident cover of up to $100,000 for your domestic helper (except on home leave)' },
//   { point: 'Hospitalisation and surgical expenses of up to $100,000 per year' },
//   { point: 'Outpatient cancer treatment and/or kidney Dialysis of up to S$4,000' },
//   { point: 'Critical illness of up to S$3,000' },
//   { point: 'Special Grant of up to S$4,000' },
//   { point: "Up to S$7,500 for your domestic helper's liability to third parties" },
//   { point: 'In-Hospital Psychiatric Treatment up to S$5,000' },
//   { point: 'Dental Care of up to S$3,000' },
//   { point: 'Fidelity Guarantee up to S$2,000' },
//   { point: 'Re-hiring Expenses of up to S$1,000' },
//   { point: 'Repatriation Expenses in the event of death or permanent disablement' },
//   { point: 'Wages Reimbursement of up to S$35 per day (Max 60 days of Hospitalisation)' }
// ];

const faqsData = [
  {
    heading: 'I need a foreign maid. How do I apply for one?',
    description:
      "Apply online for a Foreign Domestic worker work permit at Ministry of Manpower's (MOM) website . https://www.mom.gov.sg/passes-and-permits/work-permit-for-foreign-domestic-worker"
  },
  {
    heading: 'What to do after approval of my MOM application?',
    description:
      'You need to submit to MOM, a Security Bond of S$5,000 , a medical insurance of at least S$60,000 and a personal accident insurance of S$60,000 per year.'
  },
  {
    heading: 'What is the Security Bond Protector benefit?',
    description:
      'This is to protect your liability in the event the breach of the S$5,000 security bond is not due to your negligence or fault as an employer.You can purchase this cover by paying an additional premium but this is subject to an excess of S$250 on each claim. '
  },
  {
    heading: 'What is SB Transmission Ref No.?',
    description:
      "This is the Security Bond Transmission Refrence Number found on MOM's In-Principle Approval or Renewal Letter. This number is required for the successful transmission to MOM. Without this information, you will not be able to renew your helper's work permit."
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3, display: 'flex' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export default function ProductDetailVerticalTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ minWidth: { xs: 0, sm: '250px' } }}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <Tab
          label="Coverages"
          sx={{
            bgcolor: value === 0 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 0 ? '#002d8c !important' : '#86878c',
            fontWeight: '500',
            fontFamily: 'Medium'
          }}
          {...a11yProps(0)}
        />
        <Tab
          label="Product Details"
          {...a11yProps(1)}
          sx={{
            bgcolor: value === 1 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 1 ? '#002d8c !important' : '#86878c',
            fontWeight: '500',
            fontFamily: 'Medium'
          }}
        />
        <Tab
          label="FAQs"
          {...a11yProps(2)}
          sx={{
            bgcolor: value === 2 ? '#f2f9ff' : 'inherit',
            borderRadius: '12px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5%',
            color: value === 2 ? '#002d8c !important' : '#86878c',
            fontWeight: '500',
            fontFamily: 'Medium'
          }}
        />
      </Tabs>
      <Box sx={{ flex: '1', overflowY: 'auto', padding: { xs: '0', sm: '10px' } }}>
        <TabPanel value={value} index={0}>
          <div>
            <DomesticHelperPlansTable width={'100%'} />
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#002D8C', width: '35%', height: '30px', padding: '3%', color: '#ffffff', marginTop: '5%' }}
              // onClick={handlePreviousButton}
              // onClick={handleNextButton}
            >
              <FileDownloadIcon />
              <Typography style={{ padding: '3%', color: '#ffffff' }}>Download Coverages</Typography>
            </Button>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>Domestic Helper</Typography>
          <Typography variant="body1">
            Our eProtect Helper Insurance is a competitive and comprehensive package of benefits developed to meet the needs and obligations
            of individual employers like yourself beyond the requirements of the Ministry of Manpower. it offers you protection against
            unforseen medical or hospitalisation expenses incurred by your domestic helper during her stay with you. Our plans are
            underwritten exclusively by etiqa Insurance Pte.Ltd.
          </Typography>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {faqsData.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Typography style={{ color: '#002d8c', fontSize: '16px', fontFamily: 'Medium' }}>{item.heading}</Typography>
              <Typography style={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium' }}>{item.description}</Typography>
            </div>
          ))}
        </TabPanel>
      </Box>
    </Box>
  );
}
