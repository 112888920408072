import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Offcanvas } from 'react-bootstrap';

function Rightsidebar({
  Rcontent,
  closeSidebar,
  show,
  maxWidth,
  placement,
  borderBottomLeftRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius
}) {
  const sidebarRef = useRef(null);

  useEffect(() => {
    // const handleClickOutside = (event) => {
    //   if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
    //     closeSidebar();
    //   }
    // };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        closeSidebar();
      }
    };

    const handleOutsideClick = (event) => {
      if (!sidebarRef.current) return;
      if (!sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [closeSidebar]);

  return (
    <>
      <Offcanvas
        placement={placement}
        show={show}
        onHide={() => closeSidebar()}
        style={{
          backgroundColor: '#fff',
          maxWidth: maxWidth,
          borderTopLeftRadius: borderTopLeftRadius,
          borderBottomLeftRadius: borderBottomLeftRadius,
          borderTopRightRadius: borderTopRightRadius,
          borderBottomRightRadius: borderBottomRightRadius
          // marginTop: '4rem'
        }}
      >
        <Offcanvas.Body style={{ justifyContent: 'center', display: 'flex' }} ref={sidebarRef}>
          {Rcontent}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default Rightsidebar;
Rightsidebar.defaultProps = {
  Rcontent: <div>Rcontent</div>,
  placement: 'end',
  borderTopLeftRadius: 20,
  borderBottomLeftRadius: 20,
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20
};
