// import React from 'react'

// function PlanPremiumTable() {
//   return (
//     <div>PlanPremiumTable</div>
//   )
// }

// export default PlanPremiumTable

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import anda_logo from '../../../../assets/images/Anda New Images/anda logo/ANDA Logo_RGB_Logotype.svg';

function createData(name, basic, silver, platinum) {
  return { name, basic, silver, platinum };
}
const headerData = [{ title: 'Basic' }, { title: 'Silver' }, { title: 'Platinum' }];

const rows = [
  { name: 'Co-Payment', basic: '25% Co-Payment', silver: 'No Co-Payment', platinum: 'No Co-Payment' },
  { name: 'Letter of Gurantee', basic: '$5,000', silver: '$5,000', platinum: '$5,000' },
  { name: 'Accidental Death/Permanent Disablement', basic: '$60,000', silver: '$70,000', platinum: '$100,000' },
  { name: 'Medical Expenses', basic: '$1,000', silver: '$3,000', platinum: '$4,000' },

  { name: 'Repatriation Expenses', basic: '$10,000', silver: 'Unlimited', platinum: 'Unlimited' },

  { name: 'Hospitalisation & Surgical Expenses (Per Year)', basic: '$60,000', silver: '$60,000', platinum: '$100,000' },

  { name: 'Wages Reimbursement', basic: '$35', silver: '$35', platinum: '$35' },

  { name: 'Re-hiring Expenses', basic: '$750', silver: '$750', platinum: '$750' },

  { name: "Domestic Worker's Liability", basic: '$5,000', silver: '$5,000', platinum: '$5,000' },
  { name: 'Special Grant', basic: '$3,000', silver: '$3,000', platinum: '$4,000' }
];

// const rows = [
//   createData('Co-Payment', '25% Co-Payment', 'No Co-Payment', 'No Co-Payment'),
//   createData('Letter of Gurantee', '$5,000', '$5,000', '$5,000'),
//   createData('Accidental Death/Permanent Disablement', '$60,000', '$70,000', '$100,000'),
//   createData('Medical Expenses', '$1,000', '$3,000', '$4,000'),
//   createData('Repatriation Expenses', '$10,000', 'Unlimited', 'Unlimited'),
//   createData('Hospitalisation & Surgical Expenses (Per Year)', '$60,000', '$60,000', '$100,000'),

//   createData('Wages Reimbursement', '$35', '$35', '$35'),

//   createData('Re-hiring Expenses', '$750', '$750', '$1,000'),

//   createData("Domestic Worker's Liability", '$5,000', '$5,000', '$5,000'),
//   createData('Special Grant', '$3,000', '$3,000', '$4,000')
// ];

export default function DomesticHelperPlansTable(props) {
  const { width, bottomButtons } = props;
  return (
    <TableContainer
      component={Paper}
      style={{
        width: props.width,
        margin: 'auto',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        overflow: 'hidden',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px'
      }}
    >
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: '#012D8C' }}>
            <TableCell
              align="left"
              style={{
                padding: '1%',
                alignItems: 'center'
              }}
            >
              <div style={{ width: '80px' }}>
                <img
                  src={anda_logo}
                  alt="ANDA logo"
                  style={{
                    width: '100%',
                    filter: 'brightness(0) invert(1)',
                    mixBlendMode: 'screen',
                    margin: 0
                  }}
                />
              </div>
            </TableCell>
            {headerData.map((header, index) => (
              <TableCell key={index} align="left" style={{ color: '#FFFFFF' }}>
                <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <p>{header.title}</p>
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5'
              }}
            >
              <TableCell align="left" component="th" scope="row" style={{ padding: '2%' }}>
                {row.name}
              </TableCell>
              <TableCell align="left">{row.basic}</TableCell>
              <TableCell align="left">{row.silver}</TableCell>
              <TableCell align="left">{row.platinum}</TableCell>
            </TableRow>
          ))}
          {props.bottomButtons ? (
            <TableRow
              style={{ backgroundColor: '#012D8C', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', padding: '2%' }}
            >
              <TableCell></TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px' }}>Buy Now</button>
              </TableCell>
              <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                <button style={{ color: '#012D8C', backgroundColor: '#ffffff', padding: '1%', borderRadius: '10px' }}>Buy Now</button>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DomesticHelperPlansTable.defaultProps = {
  width: '70%',
  bottomButtons: false,
  rows: [
    { name: 'Accidental loss or damage by fire', thirdPartyFireTheft: true, thirdPartyOnly: false },
    { name: 'Loss or damage by theft', thirdPartyFireTheft: true, thirdPartyOnly: false },
    { name: 'Accidental loss or damage by other insured causes', thirdPartyFireTheft: true, thirdPartyOnly: false },
    { name: 'Policy Excess', thirdPartyFireTheft: true, thirdPartyOnly: true },
    { name: 'Towing to repairers or places of safety following accident', thirdPartyFireTheft: true, thirdPartyOnly: true },
    { name: 'Vehicle stolen outside Singapore subject to applicable excess', thirdPartyFireTheft: true, thirdPartyOnly: false },
    { name: 'Death or bodily injury to third party', thirdPartyFireTheft: false, thirdPartyOnly: true },
    { name: 'Loss or damage to third party property (up to $5,00,000)', thirdPartyFireTheft: false, thirdPartyOnly: true },
    { name: 'Legal costs and expenses', thirdPartyFireTheft: false, thirdPartyOnly: true }
  ],
  headerData: [
    { title: 'Third-Party Fire & Theft', price: '$531.48' },
    { title: 'Third-Party Only', price: '$484.79' }
  ]
};
