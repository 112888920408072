import { useState } from 'react';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { showWarning } from 'components/nudges/Alert';
import { getUserDetails } from 'helper/utils';

export default function useIncorporateFinanceTable() {
  const { makeRequest } = useApiCall();
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(true);

  const handleVerifyClick = async (startDate, endDate) => {
    try {
      let UserData = await getUserDetails();
      let body = {
        ueN_No: UserData?.uenNo,
        startDate,
        endDate
      };
      let res = await makeRequest({ method: 'POST', url: API.CommissionEarnPolicyGet, body });
      if (res?.code == 200) {
        let arr = [];
        res?.data?.data?.forEach((item, index) => {
          arr.push({
            // sr: index + 1,
            transaction: `${item.transactionDate || '-'}`,
            invoice: `${item.invoiceNo || '-'}`,
            pno: `${item.policyNo || '-'}`,
            insured: `${item.insuredDescription || '-'}`,
            insurer: `${item.insurer || '-'}`,
            premium: item.premium,
            tsa: item.tsaFee,
            outstanding: item.outstanding,
            status: `${item.status || '-'}`
          });
        });
        setData(arr);
        setIsLoading(false);
      } else if (res?.code == 404) {
        showWarning('No Data found');
        setIsLoading(false);
      }
    } catch (e) {
      console.error('Error in useEffect to getting the packages or premium data', e);
    }
  };

  return { handleVerifyClick, data, loading };
}
