import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { TextSnippetOutlined } from '@mui/icons-material';
import DataChart from 'components/charts/DataChart';
import { useTheme } from 'components/Theme/ThemeProvider';
import useApiCall from 'api/useApiCall';
import DropDown from 'components/dropdown/DropDown';
import '../../../src/modules/packages & premium/styles/InformationForm.css';
import { activeItem } from 'store/reducers/menu';
import { store } from 'store/index';
import Skeleton from '@mui/material/Skeleton';
import DoubleDataChart from 'components/charts/DoubleDataChart';
import { API } from 'api/API';
import { showDanger } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';

const options = [
  { label: 'Current Year', id: 'Y' },
  { label: 'Current Quarter', id: 'Q' },
  { label: 'Current Month', id: 'M' },
  { label: 'Current Week', id: 'W' }
];

const DashboardDefault = () => {
  const { currentTheme } = useTheme();
  const { makeRequest } = useApiCall();
  const [dashboardData, setDashboardData] = useState(null);
  const [filterBy, setFilterBy] = useState('Y');
  const [filterByProductSales, setFilterByProductSales] = useState('Y');
  const [loading, setLoading] = useState(true);
  const network = useNetworkStatus();

 
  useEffect(() => {
    store.dispatch(activeItem({ openItem: ['dashboard1'] }));
  }, []);


  useEffect(() => {
    const fetchDashboardData = async () => {
      const loginData = localStorage.getItem('@login');
      if (loginData) {
        const loginDataObj = JSON.parse(loginData);
        const userMasterId = loginDataObj.userMasterId;

        try {
          if (!network) {
            showDanger('No internet connection.');
            setLoading(false);
            return;
          }
          const response = await makeRequest({
            method: 'GET',
            url: `${API.AgentDashboardAPI}?UserId=${userMasterId}`
          });
          setDashboardData(response.data);
        } catch (error) {
          console.error(error);
          showDanger(error)
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchDashboardData();
  }, []);

  return (
    <Grid container rowSpacing={2.5} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12} sx={{ mt: -0.15 }}>
        <Typography variant="h5" color={currentTheme.textColor} style={{ fontFamily: 'Medium' }}>
          Dashboard
        </Typography>
      </Grid>

      {loading ? (
        <>
          <Grid item xs={12} sm={6} md={2} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={80} animation="wave" style={{ borderRadius: '10px' }} />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={80} animation="wave" style={{ borderRadius: '10px' }} />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={80} animation="wave" style={{ borderRadius: '10px' }} />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3}>
            <Skeleton variant="rectangular" width="100%" height={80} animation="wave" style={{ borderRadius: '10px' }} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="MTD Policy Value"
              count="$1,452.12"
              icon={
                <TextSnippetOutlined
                  sx={{ fontSize: 40, backgroundColor: '#D5FFEF', color: '#4ABC8C', borderRadius: '20%', padding: '8px' }}
                />
              }
              dashboardData={`$${dashboardData?.mtD_PolicyBasicValue}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="YTD Sold Policies"
              count="475"
              color="error"
              icon={
                <TextSnippetOutlined
                  sx={{ fontSize: 40, backgroundColor: '#E9F2FF', color: '#7AAFFF', borderRadius: '20%', padding: '8px' }}
                />
              }
              dashboardData={dashboardData?.totalPolicyCount}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="YTD Commision Earned"
              count="$2,145.12"
              color="success"
              icon={
                <TextSnippetOutlined
                  sx={{ fontSize: 38, backgroundColor: '#FFF9E8', color: '#FED66E', borderRadius: '20%', padding: '8px' }}
                />
              }
              dashboardData={`$${dashboardData?.ytD_CommissionEarned}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="YTD Policy Value"
              color="error"
              icon={
                <TextSnippetOutlined
                  sx={{ fontSize: 40, backgroundColor: '#EFE2FF', color: '#B1A2E8', borderRadius: '20%', padding: '10px' }}
                />
              }
              dashboardData={`$${dashboardData?.ytD_PolicyBasicValue}`}
            />
          </Grid>
        </>
      )}

      {/* row 2 */}
      <Grid item xs={12} md={7} lg={6}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item></Grid>
        </Grid>

        <MainCard style={{ backgroundColor: currentTheme.cardColor }}>
          <Typography
            variant="h5"
            color={'#000000'}
            style={{ fontFamily: 'Medium', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            Commission breakup
            <DropDown
              width={'150px'}
              heightsss={'40px'}
              placeholder={'Current Year'}
              height={'auto'}
              backgroundColor={'#fafafa'}
              options={options}
              setSelectedValue={setFilterBy}
            />
          </Typography>
          <DataChart filterBy={filterBy} setFilterBy={setFilterBy} />
        </MainCard>
        {/* )} */}
      </Grid>

      <Grid item xs={12} md={7} lg={6}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item></Grid>
        </Grid>

        <MainCard style={{ backgroundColor: currentTheme.cardColor }}>
          <Typography
            variant="h5"
            color={currentTheme.textColor}
            style={{ fontFamily: 'Medium', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            Product Sales Performance
            <DropDown
              width={'150px'}
              heightsss={'40px'}
              placeholder={'Current Year'}
              height={'auto'}
              backgroundColor={'#fafafa'}
              options={options}
              setSelectedValue={setFilterByProductSales}
            />
          </Typography>

          <DoubleDataChart filterByProductSales={filterByProductSales} setFilterByProductSales={setFilterByProductSales} />
        </MainCard>
        {/* )} */}
      </Grid>
    </Grid>
  );
};

export default DashboardDefault;
