// import ReusableCard from 'components/cards/ResuableCard';
// import React, { useEffect, useState } from 'react';
// import { Grid, Button, TextField, IconButton } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import ReusableRadioGroup from './ReusableRadioGroup';
// import Checkbox from '@mui/material/Checkbox';
// import SingleCheckbox from './ReusableCheckBoxGroup';
// import InputLabel from '@mui/material/InputLabel';
// import DropDown from 'components/dropdown/DropDown';
// import WestIcon from '@mui/icons-material/West';
// import EastIcon from '@mui/icons-material/East';
// import RiderSectionTable from 'modules/packages & premium/components/RiderSectionTable';
// import { AddaddOnsData, addOtherIndividualState, removeAddOnData } from 'store/reducers/premiumPolicy';
// import { store } from 'store/index';
// import { FormControlLabel } from '../../../../../node_modules/@mui/material/index';
// import { useDispatch, useSelector } from 'react-redux';
// import { DomesticCustomerAddons } from 'modules/packages & premium/static';
// import useProductPlansData from 'modules/customer/corporate/customerlandingpage/hooks/useProductPlansData';
// // import { Grid, Button, TextField, IconButton } from '@mui/material';
// const DomesticHelperCustomerPolicyAddons = [
//   { id: 'sr', label: 'Sr.', minWidth: 50, align: 'center' },
//   { id: 'productName', label: 'Product ID', minWidth: 150, align: 'center' },
//   { id: 'Prices', label: 'Price', minWidth: 90, align: 'center' },
//   { id: 'price', label: 'Action', minWidth: 60, align: 'center' }
// ];

// const DomesticHelperPolicyAddOn = ({ handleNextButton, handlePreviousButton, data }) => {
//   const tsaTypeParam = 'Domestic Helper';

//   const [getdata, setData] = useState([]);
//   const [verificationMessage, setVerificationMessage] = useState('');
//   const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
//   const { addOn } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);

//   const handleCheckboxChange = (item, checked) => {
//     let obj = {
//       itemCode: item?.productID,
//       salesPrice: item?.price,
//       quantity: 1
//     };
//     if (checked) {
//       store.dispatch(AddaddOnsData([item.productID, obj]));
//     } else {
//       store.dispatch(removeAddOnData(item.productID));
//     }
//   };

//   const DomesticHelperAddonRows = data?.customerProductList?.map((item, index) => {
//     return {
//       sr: index + 1,
//       productName: item.productID,
//       Prices: item.price,
//       price: (
//         <div>
//           <FormControlLabel
//             control={
//               <Checkbox checked={addOn[item?.productID] ? true : false} onChange={(e) => handleCheckboxChange(item, e.target.checked)} />
//             }
//           />
//         </div>
//       )
//     };
//   });

//   useEffect(() => {
//     setData(DomesticCustomerAddons);
//   }, []);

//   return (
//     <ReusableCard
//       backgroundColor={'#ffffff'}
//       border={'1px solid #f2f2f2'}
//       padding={'1%'}
//       boxShadow={' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
//       borderRadius={'15px'}
//       width="100%"
//       height="auto"
//       marginTop={'2%'}
//       content={
//         <>
//           {getdata?.map((item, index) => (
//             <RenderFunction
//               item={item}
//               index={index}
//               setVerificationMessage={setVerificationMessage}
//               verificationMessage={verificationMessage}
//               otherState={otherState}
//               // dropdownData={getUpdatedCountriesListData}
//             />
//           ))}

//           {/* AddOns */}

//           <Grid item xs={12}>
//             <RiderSectionTable columns={DomesticHelperCustomerPolicyAddons} rows={DomesticHelperAddonRows} />
//           </Grid>

//           {/* Previous and Next Button */}

//           <Grid item xs={12}>
//             <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
//               <Grid item xs={6} sm={6} md={6} lg={3}>
//                 <Button
//                   size="small"
//                   variant="contained"
//                   style={{ backgroundColor: '#e4e4e4', width: '100%', padding: '3%', color: '#000000' }}
//                   onClick={handlePreviousButton()}
//                 >
//                   <IconButton style={{ marginRight: '5px' }}>
//                     <WestIcon />
//                   </IconButton>
//                   Previous
//                 </Button>
//               </Grid>
//               <Grid item xs={6} sm={6} md={6} lg={3}>
//                 <Button
//                   size="small"
//                   variant="contained"
//                   style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff' }}
//                   onClick={handleNextButton()}
//                 >
//                   Next
//                   <IconButton style={{ marginLeft: '5px', color: '#ffffff' }}>
//                     <EastIcon />
//                   </IconButton>
//                 </Button>
//               </Grid>
//             </Grid>
//           </Grid>
//         </>
//       }
//     />
//   );
// };

// export default DomesticHelperPolicyAddOn;

import React, { useEffect, useState } from 'react';
import { Grid, Button, IconButton, InputLabel, TextField } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import ReusableCard from 'components/cards/ResuableCard';
import RiderSectionTable from 'modules/packages & premium/components/RiderSectionTable';
import { AddaddOnsData, addOtherIndividualState, removeAddOnData } from 'store/reducers/premiumPolicy';
import { useDispatch, useSelector } from 'react-redux';
import { DomesticCustomerAddons } from 'modules/packages & premium/static';
import DropDown from 'components/dropdown/DropDown';
import { store } from 'store/index';

const RenderFunction = ({ item, otherState, index }) => {
  // const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const [value, setValue] = useState(otherState[item?.value] || '');
  const dispatch = useDispatch();

  useEffect(() => {
    if (otherState?.fromDate && item?.value === 'todate') {
      const fromDate = otherState?.fromDate;
      const numOfMonths = 14; // duration in months

      if (fromDate && numOfMonths) {
        const fromDateObj = new Date(fromDate);
        fromDateObj.setMonth(fromDateObj.getMonth() + numOfMonths);
        const toDate = fromDateObj.toISOString().slice(0, 10);
        store.dispatch(addOtherIndividualState(['todate', toDate]));
        setValue(toDate);
      }
    }
  }, [otherState, item?.value]);
  
  const onValueChange = (selectedItem) => {
    try {
      dispatch(addOtherIndividualState([item.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error(e);

    }
  };

  const onChangeText = (txt, value) => {
    const allowedChars = /^[a-zA-Z0-9 ]*$/;
    const allowedCharsForDate = /^\d{4}-\d{2}-\d{2}$/;
    const currentDate = new Date().toISOString().split('T')[0];
    try {
      if (item?.texttype === 'date') {
        if (allowedCharsForDate.test(txt)) {
          if (txt <= currentDate) {
            setValue(txt);
            store.dispatch(addOtherIndividualState([value, txt]));
          } else {
           
            setValue(currentDate);
            store.dispatch(addOtherIndividualState([value, currentDate]));
          }
        } else {
          
        }
      } else {
        if (allowedChars.test(txt)) {
          
          setValue(txt);
          store.dispatch(addOtherIndividualState([value, txt]));
         
        } else {
         
        }
      }
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p style={{ fontSize: '18px', fontWeight: '500', marginTop: '2rem' }}>{item.title}</p>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <InputLabel>{item.label}</InputLabel>
            {item.astric && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}
          </div>
          {item.type === 'dropdown' && (
            <DropDown width={'100%'} options={item.ddoptions} onValueChange={onValueChange} defaultValue={value} heightsss={'45px'} />
          )}
          {item?.type == 'textField' && (
            <div style={{ marginTop: '2px' }}>
              <TextField
                type={item?.texttype}
                disabled={item?.isEnable}
                onChange={(txt) => onChangeText(txt.target.value, item?.value)}
                // onInput={handleInput}
                required={item?.isRequired}
                placeholder={item.value === 'fin' ? 'e.g. S3272118D' : ''}
                size="small"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                className="txtinputs_stylingg"
                value={value}
                inputProps={{
                  pattern: /^\d{4}$/, // Allow only four digits for year
                  max: new Date().toISOString().split('T')[0] // Set minimum date (optional)
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const DomesticHelperPolicyAddOn = ({ handleNextButton, handlePreviousButton, data }) => {

  const [getdata, setData] = useState([]);

  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { addOn } = otherState;
 
  const dispatch = useDispatch();

 

  const handleCheckboxChange = (item, checked) => {
 
    let obj = {
      itemCode: item?.productID,
      salesPrice: item?.price,
      quantity: 1
    };
    if (checked) {
      dispatch(AddaddOnsData([item?.productID, obj]));
    } else {
      dispatch(removeAddOnData(item?.productID));
    }
  };

  const DomesticHelperAddonRows = data?.map((item, index) => {
    return {
      sr: index + 1,
      productid: item.productID,
      Prices: item.price,
      price: (
        <div>
          <FormControlLabel
            control={
              <Checkbox checked={addOn[item?.productID] ? true : false} onChange={(e) => handleCheckboxChange(item, e.target.checked)} />
            }
          />
        </div>
      )
    };
  });

  useEffect(() => {
    setData(DomesticCustomerAddons);
  }, []);

  return (
    <ReusableCard
      backgroundColor={'#ffffff'}
      border={'1px solid #f2f2f2'}
      padding={'5%'}
      boxShadow={'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
      borderRadius={'15px'}
      width="100%"
      height="auto"
      marginTop={'2%'}
      content={
        <>
          {getdata.map((item, index) => (
            <RenderFunction key={index} item={item} index={index} otherState={otherState} />
          ))}

          {/* AddOns */}
          <Grid item xs={12}>
            <RiderSectionTable columns={DomesticHelperCustomerPolicyAddons} rows={DomesticHelperAddonRows} />
          </Grid>

          {/* Previous and Next Button */}
          <Grid item xs={12}>
            <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button
                  size="small"
                  variant="contained"
                  style={{ backgroundColor: '#e4e4e4', width: '100%', padding: '3%', color: '#000000' }}
                  onClick={handlePreviousButton}
                >
                  <IconButton style={{ marginRight: '5px' }}>
                    <WestIcon />
                  </IconButton>
                  Previous
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button
                  size="small"
                  variant="contained"
                  style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff' }}
                  onClick={handleNextButton}
                >
                  Next
                  <IconButton style={{ marginLeft: '5px', color: '#ffffff' }}>
                    <EastIcon />
                  </IconButton>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default DomesticHelperPolicyAddOn;

const DomesticHelperCustomerPolicyAddons = [
  { id: 'sr', label: 'Sr.', minWidth: 50, align: 'center' },
  { id: 'productid', label: 'Product ID', minWidth: 150, align: 'center' },
  { id: 'Prices', label: 'Price', minWidth: 90, align: 'center' },
  { id: 'price', label: 'Action', minWidth: 60, align: 'center' }
];
