import OverlayLoader from 'components/loader/OverlayLoader';
import React, { useEffect, useState } from 'react';
import MotorVehiclePremiumView from '../components/MotorVehiclePremiumView';

import { usePackagePremiumState } from '../provider/PackagePremiumStateProvider';
import ForeignWorkerPremiumView from './ForeignWorkerPremiumView';
import DomesticHelperPremiumView from './DomesticHelperPremiumView';

const PremiumPolicyTypes = React.memo(() => {
  const [renderComponent, setRenderComponent] = useState(<OverlayLoader show={true} />);
  const { params } = usePackagePremiumState();

  useEffect(() => {
    if (params?.tsaType === 'Motor' || params?.opportunityType === 'Motor') setRenderComponent(<MotorVehiclePremiumView />);
    else if (params?.tsaType === 'Corporate' || params?.opportunityType  === 'Corporate') setRenderComponent(<>Add Corporate Component Here</>);
    else if (params?.tsaType === 'Foreign Worker' || params?.opportunityType === 'Foreign Worker') setRenderComponent(<ForeignWorkerPremiumView  />);
    else if (params?.tsaType === 'Domestic Helper' || params?.opportunityType === 'Domestic Helper') setRenderComponent(<DomesticHelperPremiumView   />);
  }, []);

  return <>{renderComponent}</>;
});

export default PremiumPolicyTypes;
