// import ReusableCard from 'components/cards/ResuableCard';
// import React from 'react';
// import { Grid, Button, TextField, IconButton } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import InputLabel from '@mui/material/InputLabel';
// import WestIcon from '@mui/icons-material/West';
// import EastIcon from '@mui/icons-material/East';
// import DropDown from 'components/dropdown/DropDown';

// const ForeignWorkerCustomerPackageInformation = ({ handleNextButton, handlePreviousButton }) => {
//   const navigate = useNavigate();
//   const keysforplan = Object.keys(planDetail);
//   const valuesforplan = Object.values(planDetail);

//   return (
//     <div>
//       <ReusableCard
//         backgroundColor={'#ffffff'}
//         border={'1px solid #f2f2f2'}
//         padding={'1%'}
//         boxShadow={' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
//         borderRadius={'15px'}
//         width="100%"
//         height="auto"
//         marginTop={'2%'}
//         content={
//           <Grid container p={2}>
//             {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
//               <span style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '500' }}>Basic Plan (With Co-Payment)</span>
//               <Button
//                 size="small"
//                 variant="contained"
//                 style={{ backgroundColor: '#002D8C', width: 'auto' }}
//                 onClick={() => {
//                   navigate('/DomesticHelper');
//                 }}
//               >
//                 Change Plan
//               </Button>
//             </Grid>

//             <div
//               style={{
//                 width: '100vw',
//                 border: '1px solid #d8d8d8',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 padding: '2%',
//                 marginTop: '2rem',
//                 borderRadius: '12px'
//               }}
//             >
//               <div style={{ display: 'flex', flexDirection: 'row' }}>
//                 {keysforplan.map((item, index) => (
//                   <Grid key={index} item xs={4} sx={{ display: 'flex', flexDirection: 'row' }}>
//                     <span style={{ fontFamily: 'Heavy', fontSize: '14px', display: 'flex', alignItems: 'center' }}>{item}</span>
//                   </Grid>
//                 ))}
//               </div>
//               <div style={{ display: 'flex', flexDirection: 'row' }}>
//                 {valuesforplan.map((item, index) => (
//                   <Grid key={index} item xs={4} sx={{ display: 'flex', flexDirection: 'row' }}>
//                     <span style={{ fontFamily: 'Medium', fontSize: '14px' }}>{item}</span>
//                   </Grid>
//                 ))}
//               </div>
//             </div> */}

//             <Grid item xs={12}>
//               <Grid container spacing={2} xs={12}>
//                 {/* <Grid item xs={12}>
//                   <p
//                     style={{
//                       display: 'flex',
//                       fontFamily: 'Medium',
//                       fontSize: '18px',
//                       fontWeight: '500',
//                       marginTop: '2rem',
//                       color: '#002d8c'
//                     }}
//                   >
//                     Employer Information
//                   </p>
//                 </Grid> */}

//                 <Grid item xs={4}>
//                   <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>
//                     Name of Company
//                   </InputLabel>
//                   <TextField
//                     size="small"
//                     variant="standard"
//                     InputProps={{ disableUnderline: true }}
//                     style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//                   />
//                 </Grid>

//                 <Grid item xs={4}>
//                   <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Address1*</InputLabel>
//                   <TextField
//                     size="small"
//                     variant="standard"
//                     InputProps={{ disableUnderline: true }}
//                     style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//                   />
//                 </Grid>

//                 <Grid item xs={4}>
//                   <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Address2*</InputLabel>
//                   <TextField
//                     size="small"
//                     variant="standard"
//                     InputProps={{ disableUnderline: true }}
//                     style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//                   />
//                 </Grid>
//               </Grid>
//               <hr />

//               <Grid container spacing={2} xs={12}>
//                 <Grid item xs={12}>
//                   <p
//                     style={{
//                       display: 'flex',
//                       fontFamily: 'Medium',
//                       fontSize: '18px',
//                       fontWeight: '500',
//                       marginTop: '2rem',
//                       color: '#002d8c'
//                     }}
//                   >
//                     Contact Person
//                   </p>
//                 </Grid>

//                 <Grid item xs={4}>
//                   <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Tel</InputLabel>
//                   <TextField
//                     size="small"
//                     variant="standard"
//                     InputProps={{ disableUnderline: true }}
//                     style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//                   />
//                 </Grid>

//                 <Grid item xs={4}>
//                   <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>HP</InputLabel>
//                   <TextField
//                     size="small"
//                     variant="standard"
//                     InputProps={{ disableUnderline: true }}
//                     style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//                   />
//                 </Grid>

//                 <Grid item xs={4}>
//                   <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Email</InputLabel>
//                   <TextField
//                     size="small"
//                     variant="standard"
//                     InputProps={{ disableUnderline: true }}
//                     style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//                   />
//                 </Grid>

//                 <Grid item xs={4}>
//                   <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>UEN</InputLabel>
//                   <TextField
//                     size="small"
//                     variant="standard"
//                     InputProps={{ disableUnderline: true }}
//                     style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//                   />
//                 </Grid>
//               </Grid>
//               <hr />

//               <Grid container spacing={2} xs={12}>
//                 <Grid item xs={12}>
//                   <p
//                     style={{
//                       display: 'flex',
//                       fontFamily: 'Medium',
//                       fontSize: '18px',
//                       fontWeight: '500',
//                       marginTop: '2rem',
//                       color: '#002d8c'
//                     }}
//                   >
//                     Period of Insurance
//                   </p>
//                 </Grid>

//                 <Grid item xs={4}>
//                   <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>From</InputLabel>
//                   <TextField
//                     size="small"
//                     variant="standard"
//                     InputProps={{ disableUnderline: true }}
//                     style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//                   />
//                 </Grid>

//                 <Grid item xs={4}>
//                   <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>To</InputLabel>
//                   <TextField
//                     size="small"
//                     variant="standard"
//                     InputProps={{ disableUnderline: true }}
//                     style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//                   />
//                 </Grid>
//               </Grid>
//               {/* <hr /> */}

//               <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
//                 <Grid item xs={6} sm={6} md={6} lg={3}>
//                   <Button
//                     size="small"
//                     variant="contained"
//                     style={{ backgroundColor: '#e4e4e4', width: '100%', padding: '3%', color: '#000000' }}
//                     onClick={handlePreviousButton}
//                   >
//                     <IconButton style={{ marginRight: '5px' }}>
//                       <WestIcon />
//                     </IconButton>
//                     Previous
//                   </Button>
//                 </Grid>
//                 <Grid item xs={6} sm={6} md={6} lg={3}>
//                   <Button
//                     size="small"
//                     variant="contained"
//                     style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff' }}
//                     onClick={handleNextButton}
//                   >
//                     Next
//                     <IconButton style={{ marginLeft: '5px', color: '#ffffff' }}>
//                       <EastIcon />
//                     </IconButton>
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         }
//       />
//     </div>
//   );
// };

// export default ForeignWorkerCustomerPackageInformation;

// const planDetail = { 'Plan Premium': 'Basic Plan (With Co-Payment)', '26 months': '$490.50', '14 Months': '$367.88' };

import ReusableCard from 'components/cards/ResuableCard';
import React, { useEffect, useState } from 'react';
import { Grid, Button, TextField, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReusableRadioGroup from '../../domestic helper policy view/components/ReusableRadioGroup';
// import Checkbox from '@mui/material/Checkbox';
// import SingleCheckbox from './ReusableCheckBoxGroup';
import InputLabel from '@mui/material/InputLabel';
import DropDown from 'components/dropdown/DropDown';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

// import { Grid, Button, TextField, IconButton } from '@mui/material';

const ForeignWorkerCustomerPackageInformation = ({ handleNextButton, handlePreviousButton }) => {
  const navigate = useNavigate();
  const keysforplan = Object.keys(planDetail);
  const valuesforplan = Object.values(planDetail);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const options = [
    { label: 'option 1', id: 1 },
    { label: 'option 2', id: 2 },
    { label: 'option 3', id: 3 },
    { label: 'option 4', id: 4 }
  ];

  useEffect(() => {
    const initialFromDate = new Date();
    const initialToDate = new Date();
    initialToDate.setMonth(initialToDate.getMonth() + 14);

    setFromDate(initialFromDate);
    setToDate(initialToDate);
  }, []);

  const handleFromDateChange = (event) => {
    const newFromDate = new Date(event.target.value);
    const newToDate = new Date(newFromDate);
    newToDate.setMonth(newToDate.getMonth() + 14);

    setFromDate(newFromDate);
    setToDate(newToDate);
  };

  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
  };

  const handleCheckboxChange3 = () => {
    setIsChecked3(!isChecked3);
  };

  // const options = [
  //   { value: 'female', label: 'Female' },
  //   { value: 'male', label: 'Male' },
  //   { value: 'other', label: 'Other' },
  //   { value: 'disabled', label: '(Disabled option)', disabled: true }
  // ];

  return (
    <div>
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'1%'}
        boxShadow={' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <Grid container p={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <p
                    style={{
                      display: 'flex',
                      fontFamily: 'Medium',
                      fontSize: '18px',
                      fontWeight: '500',
                      marginTop: '2rem',
                      color: '#000000'
                    }}
                  >
                    A. Policy Type
                  </p>
                </Grid>
              </Grid>
              <hr />

              <Grid container spacing={1} xs={12}>
                <Grid item xs={4}>
                  <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '3%', color: '#9fa1a9' }}>
                    Policy type*
                  </InputLabel>
                  <div style={{ justifyContent: 'start', display: 'flex', alignItems: 'start' }}>
                    <DropDown width="100%" itemHeight={55} options={options} height={50} heightsss={'45px'} />
                  </div>
                </Grid>
                {/* <Grid item xs={4}>
                  <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '3%', color: '#9fa1a9' }}>Category*</InputLabel>
                  <div style={{ justifyContent: 'start', display: 'flex', alignItems: 'start' }}>
                    <DropDown width="100%" itemHeight={55} options={options} height={50} heightsss={'45px'} />
                  </div>
                </Grid> */}
              </Grid>

              <Grid container spacing={1} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item xs={12}>
                  <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>
                    Expected policy duration
                  </InputLabel>
                </Grid>
                <Grid container spacing={1} xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      placeholder="From"
                      type="date"
                      variant="standard"
                      value={fromDate.toISOString().split('T')[0]}
                      onChange={handleFromDateChange}
                      InputProps={{ disableUnderline: true }}
                      style={{
                        backgroundColor: '#F8F8F8',
                        width: '95%',
                        margin: '5% 0% 0% 5%',
                        borderRadius: '8px',
                        height: '45px',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '10px'
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      placeholder="To"
                      type="date"
                      variant="standard"
                      disabled={true}
                      value={toDate.toISOString().split('T')[0]}
                      InputProps={{ disableUnderline: true, readOnly: true }}
                      style={{
                        backgroundColor: '#F8F8F8',
                        width: '95%',
                        margin: '5% 0% 0% 5%',
                        borderRadius: '8px',
                        height: '45px',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '10px'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <p
                    style={{
                      display: 'flex',
                      fontFamily: 'Medium',
                      fontSize: '18px',
                      fontWeight: '500',
                      marginTop: '2rem',
                      color: '#000000'
                    }}
                  >
                    B. Add-ons
                  </p>

                  <p style={{ fontFamily: 'Medium', fontSize: '14px', color: '#002d8c' }}>Add-on 2: Additional Benefits</p>
                </Grid>
                <Grid item xs={10}>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>
                    1. Daily Hospital Allowance , up to $600($20 per day, max 30 days)
                  </p>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>
                    2. Alternative Maid Services , up to $600($20 per day, max 30 days)
                  </p>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>
                    3. Ambulance Fees , up to $300
                  </p>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>
                    4. Fidelity Guarantee, up to $2000
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <ReusableRadioGroup defaultValue="female" options={[{ value: 'S$32.70', label: 'S$32.70' }]} />
                </Grid>
              </Grid>
              <hr />

              <Grid container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', color: '#002d8c' }}>Add-on 3: Dental Care</p>
                </Grid>
                <Grid item xs={10}>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Add S$1,000</p>
                </Grid>
                <Grid item xs={2}>
                  <ReusableRadioGroup defaultValue="female" options={[{ value: 'S$327.00', label: 'S$327.00' }]} />
                </Grid>
                <Grid item xs={10}>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Add S$3,000</p>
                </Grid>
                <Grid item xs={2}>
                  <ReusableRadioGroup defaultValue="female" options={[{ value: 'S$436.00', label: 'S$436.00' }]} />
                </Grid>
              </Grid>
              <hr />

              <Grid container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', color: '#002d8c' }}>Add-on 4:In-hospital Psychiatric Care</p>
                </Grid>
                <Grid item xs={10}>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Add S$3,000</p>
                </Grid>
                <Grid item xs={2}>
                  <ReusableRadioGroup defaultValue="female" options={[{ value: 'S$65.40', label: 'S$65.40' }]} />
                </Grid>
                <Grid item xs={10}>
                  <p style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Add S$5,000</p>
                </Grid>
                <Grid item xs={2}>
                  <ReusableRadioGroup defaultValue="female" options={[{ value: 'S$81.75', label: 'S$81.75' }]} />
                </Grid>
              </Grid>
              <hr />

              {/* <Grid container spacing={2} xs={12}>
                <Grid item xs={0}>
                  <SingleCheckbox label="" checked={isChecked1} onChange={handleCheckboxChange1} />
                </Grid>
                <Grid item xs={11} mt={1}>
                  <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#9fa1a9', fontWeight: '500' }}>
                    Declaration and undertaking
                  </span>
                  <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#002d8c', fontWeight: '500', marginLeft: '1%' }}>
                    read details
                  </span>
                </Grid>

                <Grid item xs={0}>
                  <SingleCheckbox label="" checked={isChecked2} onChange={handleCheckboxChange2} />
                </Grid>
                <Grid item xs={11} mt={1}>
                  <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#9fa1a9', fontWeight: '500' }}>
                    Terms and conditions of counter indemnity for letter of guarantee applied for above
                  </span>
                  <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#002d8c', fontWeight: '500', marginLeft: '1%' }}>
                    read details
                  </span>
                </Grid>

                <Grid item xs={0}>
                  <SingleCheckbox label="" checked={isChecked3} onChange={handleCheckboxChange3} />
                </Grid>
                <Grid item xs={11} mt={1}>
                  <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#9fa1a9', fontWeight: '500' }}>Personal data</span>
                  <span style={{ fontFamily: 'Medium', fontSize: '14px', color: '#002d8c', fontWeight: '500', marginLeft: '1%' }}>
                    read details
                  </span>
                </Grid>
              </Grid>
              <hr /> */}

              <Grid item xs={12}>
                <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
                  <Grid item xs={6} sm={6} md={6} lg={3}>
                    <Button
                      size="small"
                      variant="contained"
                      style={{ backgroundColor: '#e4e4e4', width: '100%', padding: '3%', color: '#000000' }}
                      onClick={handlePreviousButton}
                    >
                      <IconButton style={{ marginRight: '5px' }}>
                        <WestIcon />
                      </IconButton>
                      Previous
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={3}>
                    <Button
                      size="small"
                      variant="contained"
                      style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff' }}
                      onClick={handleNextButton}
                    >
                      Next
                      <IconButton style={{ marginLeft: '5px', color: '#ffffff' }}>
                        <EastIcon />
                      </IconButton>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default ForeignWorkerCustomerPackageInformation;

const planDetail = { 'Plan Premium': 'Basic Plan (With Co-Payment)', '26 months': '$490.50', '14 Months': '$367.88' };
