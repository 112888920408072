import { useState, useEffect } from 'react';
 
const useBodyScrollLock = () => {
  const [isLocked, setIsLocked] = useState(false);
 
  useEffect(() => {
    const bodyStyle = document.body.style;
    if (isLocked) {
      bodyStyle.overflowY = 'hidden';
    } else {
      bodyStyle.overflowY = 'auto';
    }
 
    return () => {
      bodyStyle.overflowY = 'auto'; // Ensure scroll is reset when component unmounts
    };
  }, [isLocked]);
 
  const toggleScrollLock = () => setIsLocked(!isLocked);
 
  return [isLocked, setIsLocked, toggleScrollLock];
};
 
export default useBodyScrollLock;
 
