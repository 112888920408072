import React, { useState } from 'react';
import { Button, TextField, Grid, InputLabel, Typography, Box } from '@mui/material';
import ReusableModal from '../routemodal/ResuableModal';
import DropDown from 'components/dropdown/DropDown';
import { showSuccess, showWarning } from 'components/nudges/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerAddRiderData } from 'store/reducers/premiumPolicy';

const AddRiderContent = ({ isOpen, onClose, selectedRow, isEditMode }) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(selectedRow ? selectedRow.firstName : '');
  const [lastName, setLastName] = useState(selectedRow ? selectedRow.lastName : '');
  const [dob, setDob] = useState(selectedRow ? selectedRow.dob : '');
  const [gender, setGender] = useState(selectedRow ? selectedRow.gender : '');
  const [experience, setExperience] = useState(selectedRow ? selectedRow.experience : '');
  const key = selectedRow ? selectedRow.id : new Date().toString();

  const handleAddRider = () => {
    const riderData = {
      id: key,
      firstName,
      lastName,
      dob,
      gender,
      experience,
      isEditMode
    };

    dispatch(CustomerAddRiderData([key, riderData]));
    showSuccess(isEditMode ? 'Rider edited successfully' : 'Rider added successfully');
    onClose();
  };

  const options = [
    { label: 'Male', id: 1 },
    { label: 'Female', id: 2 }
  ];
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box borderBottom={1} borderColor="grey.300">
            <Typography
              style={{ color: '#000', fontFamily: 'Medium', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}
              variant="h5"
            >
              {isEditMode ? 'Edit Rider' : 'Add Rider'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>First Name</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Last Name</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Date of Birth</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            type="date"
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
            inputProps={{
              max: new Date().toISOString().split('T')[0]
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Experience</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <DropDown
            marginTop={'1px'}
            width={'100%'}
            heightsss={'37px'}
            height={'40px'}
            placeholder="Select experience"
            value={experience}
            onValueChange={(i) => {
              setExperience(i.label);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Gender</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <DropDown
            marginTop={'1px'}
            width={'100%'}
            heightsss={'37px'}
            height={'40px'}
            options={options}
            placeholder="Select gender"
            value={gender}
            onValueChange={(i) => {
              setGender(i.label);
            }}
            

          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-start" mt={2}>
        <Button
          size="small"
          variant="contained"
          style={{
            borderRadius: '8px',
            color: '#fff',
            backgroundColor: '#002d8c',
            minWidth: '100px',
            marginRight: '10px',
            padding: '8px 16px',
            fontFamily: 'Medium'
          }}
          onClick={handleAddRider}
        >
          {isEditMode ? 'Edit Rider' : 'Add Rider'}
        </Button>
        <Button
          size="small"
          variant="outlined"
          style={{
            borderRadius: '8px',
            color: '#002d8c',
            backgroundColor: '#ffffff',
            minWidth: '100px',
            marginRight: '10px',
            padding: '8px 16px',
            fontFamily: 'Medium',
            border: '1px solid #002d8c'
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Grid>
    </>
  );
};

const AddRiderModal = ({ isOpen, onClose, selectedRow }) => {
  const isEditMode = !!selectedRow;
  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      ModalContent={<AddRiderContent isOpen={isOpen} onClose={onClose} selectedRow={selectedRow} isEditMode={isEditMode} />}
      width="400px"
      height="auto"
      borderRadius="10px"
      padding="20px"
      showCloseButton={false}
    />
  );
};

export default AddRiderModal;
