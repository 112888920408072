import React, { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import Rightsidebar from 'components/rightsidebar/Rightsidebar';
import MotorAddCustomerSidebar from './MotorAddCustomerSidebar';
import { usePremiumCalculationState } from 'modules/packages & premium/provider/PremiumCalculationProvider';
import MotorAddCompanySidebar from './MotorAddCompanySidebar';
import { useSelector } from 'react-redux';

const MotorAmountCard = ({ borderRadius }) => {
  const [show, setShow] = useState(false);
  const { apiData, params } = usePremiumCalculationState();
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);


  const handleOpenSideBar = () => {
    setShow(true);
  };

  const handlePaymentButton = () => {
    setShow(false);
  };

  return (
    <Grid
      item
      xs={12}
      md={4}
      lg={4}
      mt={2}
      ml={2}
      style={{
        backgroundColor: '#fff',
        borderRadius: '15px',
        boxShadow: '10px 10px 30px 10px  #F7F7F8',
        padding: '10px',
        maxWidth: '100%'
      }}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>
            Summary
          </Typography>
        </Grid>
        <Grid item style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Grid style={{ border: '1px solid #ddd', width: '100%' }} />

          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>Premium W/O GST</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${apiData?.finalPremiumAmount}`}</Typography>
          </Grid>

          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>GST {`(${apiData?.gstPercentage}%)`}</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${apiData?.gstAmount}`}</Typography>
          </Grid>
          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>Food Delivery with GST</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${apiData?.fdExtentionAmount}`}</Typography>
          </Grid>
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid style={{ border: '1px solid #ddd', width: '100%' }} />
          </Grid>
          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography style={{ color: '#76777D', fontFamily: 'Heavy', fontWeight: 600, marginRight: '2px' }}>Total Premium</Typography>
              <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>with GST</Typography>
            </div>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${apiData?.finalTotalPremiumAmount}`}</Typography>
          </Grid>

          <Grid container justifyContent="space-between" style={{ marginTop: '5px' }}>
            <Typography style={{ color: '#76777D', fontFamily: 'Thin', fontWeight: 600 }}>Commission</Typography>
            <Typography style={{ color: '#000', fontFamily: 'Light', fontWeight: 600 }}>{`$${apiData?.agentCommissionAmount}`}</Typography>
          </Grid>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#002D8C',
              borderRadius: '5px',
              fontFamily: 'Thin',
              fontWeight: 600,
              marginTop: 10,
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              fontSize: 13
            }}
            onClick={() => handleOpenSideBar()}
          >
            Proceed to next
          </Button>
        </Grid>
        <Rightsidebar
          Rcontent={
            otherState.isCompanyRegistered === true ? (
              <MotorAddCompanySidebar setShow={setShow} handlePaymentButton={handlePaymentButton} apiData={apiData} params={params} />
            ) : (
              <MotorAddCustomerSidebar setShow={setShow} handlePaymentButton={handlePaymentButton} apiData={apiData} params={params} />
            )
          }
          show={show}
          closeSidebar={handlePaymentButton}
          maxWidth="400px"
        />
      </Grid>
    </Grid>
  );
};

export default MotorAmountCard;
