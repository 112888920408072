import { showSuccess, showWarning } from 'components/nudges/Alert';
import { useDispatch } from 'react-redux';
import { addWorkerSection } from 'store/reducers/premiumPolicy';

export default function useHandleForeignWorker(
  buttonLabel,
  selectedRowData,
  setShow,
  firstName,
  lastName,
  workPermit,
  dob,
  nationality,
  nric,
  gender,
  contactNumber,
  email,
  passport,
  mode
) {
  const dispatch = useDispatch();

  const calculateAge = (dob) => {
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - new Date(dob);
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return Math.floor(ageInYears);
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleAddWorker = () => {
    if (!firstName || !lastName || !workPermit || !nationality || !passport) {
      showWarning('Please fill in all required fields');
      return;
    }

    if (email && !validateEmail(email)) {
      showWarning('Please enter a valid email address');
      return;
    }

    const age = calculateAge(dob);
    if (age < 18) {
      showWarning('Age must be 18 years or older');
      return;
    }

    let key = selectedRowData ? (selectedRowData?.id ? selectedRowData?.id : new Date().toString()) : new Date().toString();
    const workerData = {
      id: key,
      firstName,
      lastName,
      workPermit,
      dob,
      nationality,
      nric,
      gender,
      contactNumber,
      email,
      passport,
      age
    };
    let obj = {};
    obj[key] = workerData;
    dispatch(addWorkerSection([key, workerData]));
    if (mode === 'edit') {
      showSuccess('Worker edited successfully');
    } else {
      showSuccess('Worker added successfully');
    }
    setShow(false);
  };

  return [handleAddWorker];
}
