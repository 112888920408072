import React, { useState } from 'react';
import { Grid, Button, TextField, IconButton } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import ReusableCard from 'components/cards/ResuableCard';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DropDown from 'components/dropdown/DropDown';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import { useSelector } from 'react-redux';

const CommonDropdown = ({ item, onValueChange,value }) => {
    return (
      <DropDown
        width={'96%'}
        marginTop={'10px'}
        heightsss={'37px'}
        height={'40px'}
        backgroundColor="#F8FAFB"
        options={item?.ddoptions || []}
        onValueChange={(selectedItem) => onValueChange(item.value, selectedItem.label)}
        value={value}
      />
    );
  };
const ContainerContent = ({ handleNextButton, handlePreviousButton }) => {
    const formData = useSelector(state => state.persistedReducer.premiumPolicy.otherState); 

  const dropdownOptions = {
    gender: [
      { label: 'Male', id: 1 },
      { label: 'Female', id: 2 }
    ],
    marital: [
      { label: 'Single', id: 1 },
      { label: 'Married', id: 2 },
      { label: 'Widow/Divorced', id: 3 }
    ],
    profession: [
      { label: 'Accountant', id: 1 },
      { label: 'Profession 2', id: 2 }
    ]
  };
  const handleInputChange = (field, value) => {
    store.dispatch(addOtherIndividualState([field, value])); 
    // setFormData((prevState) => ({
    //   ...prevState,
    //   [field]: value
    // }));
  };

  const handleDOBChange = (newValue) => {
    const currentDate = new Date();
    const selectedDate = new Date(newValue);
    if (selectedDate > currentDate) {
      newValue = currentDate.toISOString().split('T')[0];
    }
    // setFormData((prevState) => ({
    //   ...prevState,
    //   dob: newValue
    // }));
  };

  const dropdownItems = [
    { label: 'What is your gender?', value: 'gen', ddoptions: dropdownOptions.gender },
    { label: 'What is your marital status?', value: 'marital', ddoptions: dropdownOptions.marital },
    { label: 'What is your profession?', value: 'profession', ddoptions: dropdownOptions.profession }
  ];

  return (
    <Grid container rowSpacing={1} columnSpacing={2} xs={12} alignItems={'center'}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {dropdownItems.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <InputLabel style={{ fontFamily: 'Thin', fontSize: '14px', marginLeft: '2%', color: '#B8B9BF', fontWeight: 600 }}>
                {item.label}
              </InputLabel>
              <CommonDropdown item={item} state={formData} onValueChange={handleInputChange}  />
            </Grid>
          ))}
          <Grid item xs={12} sm={6}>
            <InputLabel style={{ fontFamily: 'Thin', fontSize: '14px', marginLeft: '2%', color: '#B8B9BF', fontWeight: 600 }}>
              What is your date of birth?
            </InputLabel>
            <TextField
              type="date"
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              style={{
                backgroundColor: '#F8F8F8',
                width: '90%',
                margin: '2px',
                padding: '6px',
                borderRadius: '8px',
                marginTop: '8px'
              }}
              value={formData.dob} 
              onChange={(e) => handleDOBChange(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                max: new Date().toISOString().split('T')[0]
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={8} sm={6} md={6} lg={2.5}>
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#f7f7f7',
                width: '100%',
                padding: '3%',
                color: '#000000',
                height: '100%',
                border: '1px solid #ddd'
              }}
              onClick={handlePreviousButton}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={8} sm={6} md={6} lg={2.5}>
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff', height: '100%' }}
              onClick={handleNextButton}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MotorVehiclePersonalDetails = ({ handleNextButton, handlePreviousButton }) => {
  return (
    <ReusableCard
      backgroundColor={'#fff'}
      border="1px solid #ddd"
      padding={'3%'}
      borderRadius={15}
      content={<ContainerContent handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />}
    />
  );
};

export default MotorVehiclePersonalDetails;
