// Successmodal.js

import React, { useEffect, useState } from 'react';
import '../../../../assets/fonts/Fonts.css';
import { successModalStyles } from '../../../../components/modals/modalcss/Successmodal_style';
import ReusableModal from 'components/modals/routemodal/ResuableModal';
import DropDown from 'components/dropdown/DropDown';
import { TextField } from '../../../../../node_modules/@mui/material/index';
import { Grid, Button } from '@mui/material';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';

export default function RaiseEndorsementModal(props) {
  const { makeRequest } = useApiCall();
  const [options, setOptions] = useState([]);
  const [dropDownValue,setDropDownValue] = useState('')


  const handleCancelButton = () => {
    props.setIsOpen(false);
  };

  const fetchEndorsementType = async () => {
    try {
      let response = await makeRequest({ method: 'GET', url: API.EndorsementCategoryGet });
      let res = response?.data?.data;
      let dropdownTypes = res.map((item) => {
        let obj = {};
        let temp = { ...obj, label: item, id: item };
        return temp;
      });
      setOptions(dropdownTypes);
    } catch (error) {
      console.error(error);

    }
  };

  useEffect(() => {
    fetchEndorsementType();
  }, []);

  return (
    <ReusableModal
      showCloseButton={false}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      onClose={props.onClose}
      height="100%"
      width="315px"
      borderRadius="10px"
      ModalContent={
        <div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', width: '280px', padding: '2%' }}>
          <p style={{ fontFamily: 'Medium', fontSize: '16px', padding: '5% 0% 0% 5%' }}>Raise Endorsement</p>
          <p style={{ fontFamily: 'Medium', fontSize: '12px', padding: '5% 0% 0% 5%' }}>Type</p>
          <div style={{ width: '98%', padding: '5% 5% 5% 1%' }}>
            <DropDown width="100%" heightsss="45px" options={options} height="auto" setSelectedValue={setDropDownValue} />
          </div>
          <div style={{ width: '100%', padding: '5% 5% 5% 5%' }}>
            <TextField
              size="small"
              placeholder="Remarks"
              variant="standard"
              multiline
              rows={4}
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            />
          </div>
          <div style={{ width: '100%', padding: '5% 5% 5% 5%' }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#002D8C',
                borderRadius: '5px',
                fontFamily: 'Medium',
                color: '#FFF',
                width: '48%'
              }}
              //   onClick={handleClick}
            >
              Submit
            </Button>
            <Button
              onClick={() => handleCancelButton()}
              variant="contained"
              style={{
                backgroundColor: '#fff',
                borderRadius: '5px',
                color: '#002D8C',
                fontFamily: 'Medium',
                width: '48%',
                border: '1px solid #002D8C',
                margin: '2%'
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      }
    />
  );
}
