import React from 'react';
import { Grid } from '@mui/material';
import SubHeader from 'components/subHeader/SubHeader';
import { useTheme } from 'components/Theme/ThemeProvider';
import PremiumTable from '../components/PremiumTable';
import '../../packages & premium/styles/InformationForm.css';

const ActiveComponents = () => {
  const { currentTheme } = useTheme();

  return (
    <Grid className="css-1gnspwu-MuiGrid-root">
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2.75}
        alignItems="center"
        justifyContent="space-between"
        style={{ backgroundColor: currentTheme.cardColor }}
      >
        <SubHeader title="Packages & Premium" />
        <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />
        <PremiumTable />
      </Grid>
    </Grid>
  );
};

const Premium = () => {
  return <ActiveComponents />;
};

export default Premium;
