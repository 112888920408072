import React from 'react';
import { Grid, Button, TextField, IconButton,Typography,Link } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import ReusableCard from 'components/cards/ResuableCard';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DropDown from 'components/dropdown/DropDown';

const ContainerContent = ({ handleNextButton, handlePreviousButton }) => {
  return (
    <Grid item container rowSpacing={1} columnSpacing={2} xs={12} alignItems={'center'}>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={12} sm={6} md={6}>
            <InputLabel style={{ fontFamily: 'Thin', fontSize: '14px', marginLeft: '2%', color: '#B8B9BF', fontWeight: 600 }}>
              What is your coverage start date?
            </InputLabel>
            <TextField
              type="date"
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              //   value={dob}
              //   onChange={(e) => handleDOBChange(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                max: new Date().toISOString().split('T')[0]
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} mt={2.5}>
            <TextField
              type="date"
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
              //   value={dob}
              //   onChange={(e) => handleDOBChange(e.target.value)}
              required
              onClick={(e) => e.stopPropagation()}
              inputProps={{
                max: new Date().toISOString().split('T')[0]
                
              }}
            />
          </Grid>

          {/* Second Row */}
          <Grid container item xs={12} sm={6} md={5} alignItems="flex-end">
  <Grid item style={{ width: '100%', marginBottom: '5px' }}>
    <InputLabel style={{ fontFamily: 'Thin', fontSize: '14px', color: '#B8B9BF', fontWeight: 600 }}>
      Apply Promo Code
    </InputLabel>
  </Grid>

  <Grid item style={{ width: 'calc(70% - 10px)' }}>
    <TextField
      size="small"
      variant="standard"
      InputProps={{ disableUnderline: true }}
      style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
      // value={dob}
      // onChange={(e) => handleDOBChange(e.target.value)}
      required
      onClick={(e) => e.stopPropagation()}
    // inputProps={{
    //   max: new Date().toISOString().split('T')[0]
    // }}
    />
  </Grid>
  <Grid item style={{ width: 'calc(35% - 6px)' }}>
    <Button
      variant="contained"
      style={{ width: '100%', marginBottom: '2px',marginLeft:'-25%', backgroundColor: '#00C7B1',borderRadius:'10px' }}
      // onClick={handleApplyPromoCode}
    >
      Apply
    </Button>
  </Grid>
</Grid>
<Grid item xs={12} style={{ width: '100%' }}>
  <Typography sx={{ fontSize: '12px' }}>
    To know more about terms and conditions for the selected policy <Link href="#" color="primary">click here</Link>.
  </Typography>
</Grid>

        </Grid>

        <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
          <Grid item xs={8} sm={6} md={6} lg={5}>
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#f7f7f7', width: '100%', padding: '3%', color: '#000000', height: '100%', border: '1px solid #ddd'  }}
              onClick={handlePreviousButton}
            >
              <IconButton style={{}}>
                <WestIcon />
              </IconButton>
              Previous
            </Button>
          </Grid>
          <Grid item xs={8} sm={6} md={6} lg={5}>
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff', height: '100%' }}
              onClick={handleNextButton}
            >
              Get Quote
              <IconButton style={{ marginLeft: '5px', color: '#ffffff' }}>
                <EastIcon />
              </IconButton>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MotorVehicleCoveragePeriod = ({ handleNextButton, handlePreviousButton }) => {
  return (
    <ReusableCard
      backgroundColor={'#fff'}
      border="1px solid #ddd"
      padding={'3%'}
      borderRadius={15}
      content={<ContainerContent handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />}
    />
  );
};

export default MotorVehicleCoveragePeriod;
