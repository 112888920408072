import React, { useEffect, useState } from 'react';
import CardLayout from '../CardLayout';
import { Grid, TextField, InputLabel } from '@mui/material';
import { useVehiclesDetails } from 'modules/packages & premium/hooks/useVehicle';
import '../../styles/InformationForm.css';
import DropDown from 'components/dropdown/DropDown';
import AutoCompleteDropdown from 'components/dropdown/AutoCompleteDropdown';
import { useSelector } from 'react-redux';
import { store } from 'store/index';
import { addOtherIndividualState, clearClaimsAmount } from 'store/reducers/premiumPolicy';

function RenderFunction({ item, index, otherState }) {
  const [value, setValue] = useState(otherState[item?.value]);
  const [claimCount, setClaimCount] = useState(otherState?.claimcount);
 
  useEffect(() => {
    setValue(otherState[item?.value]);
    setClaimCount(otherState?.claimcount);
  }, [otherState[item?.value], otherState?.claimcount]);

  const onChangeText = (txt, value) => {
    try {
      if (value === 'claimamount') {
        txt = txt.replace(/[^0-9.]/g, '');
      }
      store.dispatch(addOtherIndividualState([value, txt]));
      setValue(txt);
    } catch (e) {
      console.error("Error in onChangeText"+e);

    }
  };


  const onValueChange = (selectedItem) => {
    try {
      if (selectedItem?.value === 'claimcount') {
        if (selectedItem?.id === 0) {
          onChangeText('', 'claimamount');
        }
        setClaimCount(selectedItem?.id);
      }
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error("Error in onValueChange"+e);

    }
  };



  return (
    <Grid item xs={12} md={4} alignItems={'center'} key={index}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <InputLabel className="inputs_styless_">{item.label}</InputLabel>
        {item?.astric && item.value !== 'claimamount' && (
          <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-12px' }}>*</div>
        )}
        {item?.astric && item.value === 'claimamount' && (claimCount == 2 || claimCount == 1) && (
          <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-12px' }}>*</div>
        )}
      </div>

      {item.type == 'textField' && (
        <TextField
          type={item?.texttype}
          disabled={claimCount == 0 && item?.value == 'claimamount'}
          onChange={(txt) => onChangeText(txt.target.value, item?.value)}
          required={item?.isRequired}
          size="small"
          variant="standard"
          InputProps={{ disableUnderline: true }}
          className="txtinputs_stylingg"
          value={value}
          inputProps={{
            min: new Date().toISOString().split('T')[0],
            max: (() => {
              const maxDate = new Date();
              maxDate.setFullYear(maxDate.getFullYear() + 10);
              return maxDate.toISOString().split('T')[0];
            })()
          }}
        />
      )}

      {item.type == 'dropdown' && (
        <DropDown
          width={'96%'}
          marginTop={'10px'}
          heightsss={'37px'}
          height={'40px'}
          backgroundColor="#F8FAFB"
          options={item?.ddoptions || []}
          onValueChange={onValueChange}
          defaultValue={otherState[item?.value]}
        />
      )}

      {item.type == 'autocomplete' && (
        <AutoCompleteDropdown
          width={'96%'}
          heightsss={'38px'}
          marginTop={'10px'}
          height={38}
          backgroundColor="#F8FAFB"
          options={item?.ddoptions || []}
          onChange={onValueChange}
          value={otherState[item?.value]}
        />
      )}
    </Grid>
  );
}

const MotorVehicleInformation = React.memo((props) => {
  const { data } = useVehiclesDetails();
  const { activeParams, otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);

  const [isOpen, setIsOpen] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CardLayout isOpen={isOpen} setIsOpen={setIsOpen} toggleForm={toggleForm} heading="Vehicle Details">
      {data.map((item, index) => (
        <RenderFunction key={index} item={item} index={index} otherState={otherState} />
      ))}
    </CardLayout>
  );
});

export default MotorVehicleInformation;
