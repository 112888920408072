import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { getUserId } from 'helper/utils';
import { store } from 'store/index';
import { showDanger, showWarning } from 'components/nudges/Alert';
import { addPolicyData, removeOtherState } from '../../../store/reducers/premiumPolicy';
import useNetworkStatus from 'hooks/useNetworkStatus';
export default function useGetPoliciesTable() {
  const { makeRequest } = useApiCall();
  const navigate = useNavigate();
  const network = useNetworkStatus();
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (!network) {
          showDanger('No internet connection.');
          return;
        }
        let UserId = await getUserId();
        let params = { UserId };
        let res = await makeRequest({ method: 'POST', url: API.AgentSpecificPoliciesGet, params });
        if (res?.code == 200) {
          let arr = [];
          res?.data?.forEach((item, index) => {
            let isActive = item?.data?.policy?.policy_status;
            let statusText = isActive ? 'Active' : 'Inactive';
            let statusColor = isActive ? '#6fc56b' : '#dc4a4a';

            arr.push({
              sr: index + 1,
              nricno: `${item?.data?.customer?.nricfin || '-'}`,
              cname: `${item?.data?.customer?.customerName || '-'}`,
              psd: `${item?.data?.policy?.periodOfInsuranceStartDate || '-'}`,
              ped: `${item?.data?.policy?.periodOfInsuranceEndDate || '-'}`,
              pa: item?.data?.policy?.premiumAmount > 0 ? `$${item.data.policy.premiumAmount}` : '-',
              commision: item?.data?.policy?.tsaComissionEarned > 0 ? `$${item.data.policy.tsaComissionEarned}` : '-',
              createdat: `${item?.data?.policy?.createdDate.split(',')[0] || '-'}`,
              Pno: `${item?.data?.policy?.policyNumber || '-'}`,
              policystatus: (
                <span
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    color: statusColor,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {statusText}
                </span>
              ),
              Action: (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {item?.data?.policy?.policyNumber == null ? (
                    <div
                      style={{
                        backgroundColor: 'grey',
                        color: '#fff',
                        borderRadius: 20,
                        fontSize: '12px',
                        cursor: 'pointer',
                        width: '55px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        padding: '3px',
                        fontFamily: 'Medium'
                      }}
                    >
                      <VisibilityIcon style={{ fontSize: '15px' }} />
                      View
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#4cb748',
                        color: '#fff',
                        borderRadius: 20,
                        fontSize: '12px',
                        cursor: 'pointer',
                        width: '55px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        padding: '3px',
                        fontFamily: 'Medium'
                      }}
                      onClick={() => {
                        store.dispatch(removeOtherState());
                        store.dispatch(addPolicyData(item));
                        navigate('/dashboard/viewpolicy');
                      }}
                    >
                      <VisibilityIcon style={{ fontSize: '15px' }} />
                      View
                    </div>
                  )}

                  <div
                    style={{
                      backgroundColor: item?.data?.policy.renewable ? '#4cb748' : 'grey',
                      color: '#fff',
                      borderRadius: 20,
                      fontSize: '12px',
                      cursor: 'pointer',
                      width: '55px',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                      padding: '3px',
                      fontFamily: 'Medium',
                      marginLeft: '5px'
                    }}
                    onClick={() => {}}
                  >
                    Renew
                  </div>
                </div>
              )
            });

            setData(arr);
            setIsLoading(false);
          });
        } else if (res?.code == 404) {
          showWarning('No Data found');
          setIsLoading(false);
        }
      } catch (e) {
        console.error("Error in useGetPoliciesTable"+e);

      }
    })();
  }, [network]);

  return [data, loading];
}
