import { store } from 'store/index';
import axios from 'axios';

const CryptoJS = require('crypto-js');

export const getValueFromRedux = (slice) => {
  const value = store.getState();
  const { persistedReducer } = value;

  return persistedReducer[slice];
};

export const getUserDetails = async () => {
  try {
    let getData = localStorage.getItem('@user');

    let employee = JSON.parse(getData);
    return employee;
  } catch (e) {
    console.error(e);

  }
};

function SERVER_URL() {
  try {
    //Live URL
    // let serverurl = 'http://194.233.80.220:8088/';
    //UAT URL
    let serverurl = 'https://andabackend.plus91labs.com/';
    return serverurl;
  } catch (e) {
    console.error(e);
  }
}

export const RemoveLocalData = async () => {
  try {
    const clearData = localStorage.clear('@login');
    return clearData;
  } catch (e) {
    console.error(e);

  }
};

export const getUserId = async () => {
  try {
    let getData = localStorage.getItem('@user');
    let user = JSON.parse(getData);

    return user?.userId;
  } catch (e) {
    console.error(e);

  }
};

export const getUserEmail = async () => {
  try {
    let getData = localStorage.getItem('@user');
    let user = JSON.parse(getData);
    return user?.finance_Email__c;
  } catch (e) {
    console.error(e);

  }
};

export const getTSAType = async () => {
  try {
    let getData = localStorage.getItem('@user');
    let user = JSON.parse(getData);
    return user?.tsA_Type__c;
  } catch (e) {
    console.error(e);

  }
};

export const getLoginDetailsId = async () => {
  try {
    let getData = localStorage.getItem('@login');
    let user = JSON.parse(getData);
    return user?.loginDetailsId;
  } catch (e) {
    console.error(e);
  }
};

export const headers = ({ method }) => {
  const appToken = localStorage.getItem('AppToken');
  const config = {
    method: method?.toUpperCase(),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${appToken ? JSON.parse(appToken) : ''}`
    }
  };
  return config;
};
const handleDownload = async ({ url, key }) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Failed to fetch PDF');
    }

    const blob = await response.blob();
    if (blob.size === 0) {
      throw new Error('Empty PDF blob');
    }

    const blobUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `document_${key}.pdf`);

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error(error);
  }
};

const downloadPdf = async ({ url, key }) => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob'
    });

    // Create a Blob from the response data
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create a temporary URL for the Blob
    const tempUrl = window.URL.createObjectURL(pdfBlob);

    // Create a temporary <a> element to trigger the download
    const tempLink = document.createElement('a');
    tempLink.href = tempUrl;
    tempLink.setAttribute('download', `${key}_${new Date()}.pdf`); // Set the desired filename for the downloaded file

    // Append the <a> element to the body and click it to trigger the download
    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean up the temporary elements and URL
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(tempUrl);
  } catch (error) {
    console.error(error);
  }
};

const EncryptValue = (value) => {
  const passPhrase = 'd1arfa14k&';
  const initVector = '@1B2c3D4e5F6g7H8';

  // Reverse function
  const Reverse = (s) => {
    return s.split('').reverse().join('');
  };

  const plainText = value;
  const saltValue = Reverse(passPhrase);

  const initVectorBytes = CryptoJS.enc.Utf8.parse(initVector);
  const saltValueBytes = CryptoJS.enc.Utf8.parse(saltValue);
  const plainTextBytes = CryptoJS.enc.Utf8.parse(plainText);

  const keyBytes = CryptoJS.PBKDF2(passPhrase, saltValueBytes, {
    keySize: 128 / 32,
    iterations: 1,
    hasher: CryptoJS.algo.SHA1
  });

  const encrypted = CryptoJS.AES.encrypt(plainTextBytes, keyBytes, {
    iv: initVectorBytes,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.toString();
};

export { SERVER_URL, handleDownload, EncryptValue, downloadPdf };
