import { useEffect, useState } from 'react';
import {
  useGetAgeOfMotorcycleDetailsQuery,
  useGetNCDDetailsQuery,
  useGetVehicleCapacityDetailsQuery,
  useGetVehicleDetailsQuery,
  useGetWorkShopTypeQuery
} from 'store/services/VehicleDetails';
import { useSelector } from 'react-redux';
import { MotarPackageData, MotorPolicyDetails, MotorVehiclesDetails } from '../static';
import { store } from 'store/index';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { removeOtherIndividualState1 } from 'store/reducers/premiumPolicy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { showDanger } from 'components/nudges/Alert';
import useNetworkStatus from 'hooks/useNetworkStatus';

const useProductDetails = (params) => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  useEffect(() => {
    let result = MotarPackageData.map((item) => ({ ...item, value: params[item.value] }));
    setData(result);
  }, []);

  return { data, isLoading, isError, setData };
};

// A Hook to getPolicyDetails
const usePolicyDetails = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setData(MotorPolicyDetails);
  }, []);

  return { data, isLoading, isError };
};

// A Hook to get the vehicle list
const useVehiclesDetails = () => {
  const { data: getVehicleDetails, isLoading: getVehicleDetailsLoad, isError: error } = useGetVehicleDetailsQuery();
  const { data: NCDDetails, isLoading: NCDLoad } = useGetNCDDetailsQuery();
  const { data: getAgeOfMotorcycleDetails, isLoading: getAgeOfMotorcycleDetailsLoad } = useGetAgeOfMotorcycleDetailsQuery();
  const { data: workshopType } = useGetWorkShopTypeQuery();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const network = useNetworkStatus();

  useEffect(() => {
    try {
      if (!network) {
        showDanger('No internet connection.');
        return;
      }
      if (error) {
        setIsError(true);
      } else if (getVehicleDetails) {
        let res = MotorVehiclesDetails.map((item) => {
          let obj = { ...item };
          switch (item?.label) {
            case 'Vehicle List':
              obj['ddoptions'] =
                getVehicleDetails?.data?.map((item) => ({ id: item?.id, label: item?.vehicleModel, value: 'vehicleId' })) || '';
              break;
            // case 'Vehicle Capacity':
            //   obj['ddoptions'] =
            //     VehicleCapacityDetails?.data?.data?.map((item) => ({ id: item, label: item, value: 'vehicleCapacity' })) || '';
            //   break;
            case 'Age of MotorCycle':
              obj['ddoptions'] =
                getAgeOfMotorcycleDetails?.data?.data?.map((item) => ({ id: item, label: item, value: 'ageOfVehicle' })) || '';
              break;

            case 'Workshop Type':
              obj['ddoptions'] = workshopType?.data?.data?.map((item) => ({ id: item, label: item, value: 'workShopType' })) || [];
              // obj['ddoptions'].unshift({ id: '', label: 'None', value: 'workShopType' });
              break;

            case 'No Claim Discount (NCD)':
              obj['ddoptions'] = NCDDetails?.data?.data?.map((item) => {
                let idWithoutPercentage = parseInt(item?.replace('%', ''));
                return { id: idWithoutPercentage, label: item, value: 'NCD' };
              });
              // obj['ddoptions'].unshift({ id: 0, label: "None", value: 'NCD' });

              break;
          }
          return obj;
        });
        setData(res);
        setIsLoading(false);
      }
    } catch (e) {
      console.error("Error in useEffect" + error);
    }
  }, [getVehicleDetails]);

  return { data, isLoading, isError };
};

const useRiderDetails = (handleClickOnEdit) => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);
  const { riderDataSection } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);

  const handleDelete = (id) => {
    try {
      store.dispatch(removeOtherIndividualState1(id));
    } catch (e) {
      console.error("Error in useRiderDetails" + e);
    }
  };

  useEffect(() => {
    const rows = Object?.values(riderDataSection)?.map((rider, index) => ({
      sr: index + 1,
      id: rider?.id,
      firstName: rider?.firstName,
      lastName: rider?.lastName,
      nric: rider?.nric,
      age: rider?.age,
      gender: rider?.gender,
      nationality: rider?.nationality,
      contactNumber: rider?.contactNumber,
      email: rider?.email,
      Action: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button
            style={{
              backgroundColor: '#4cb748',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '55px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium',
              border: 'none'
            }}
            onClick={() => handleClickOnEdit(rider, 'edit')}
            onKeyDown={(e) => e.key === 'Enter' && handleClickOnEdit(rider, 'edit')}
            tabIndex={0}
            role="button"
            aria-label="Edit rider details"
          >
            <EditIcon style={{ fontSize: '15px', marginRight: '2.5px' }} />
            Edit
          </button>
          <button
            style={{
              backgroundColor: rider?.isCheckboxChecked ? 'grey' : '#0174BF',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '75px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium',
              marginLeft: '10px',
              border: 'none'
            }}
            onClick={() => {
              if (rider?.isCheckboxChecked !== true) {
                handleDelete(rider.id);
              }
            }}
            onKeyDown={(e) => e.key === 'Enter' && rider?.isCheckboxChecked !== true && handleDelete(rider.id)}
            tabIndex={0}
            role="button"
            aria-label="Delete rider"
          >
            <DeleteOutlineOutlinedIcon style={{ fontSize: '15px' }} />
            Delete
          </button>
        </div>
      )
    }));
    setData(rows);
  }, [riderDataSection]);

  return { data, isLoading, isError };
};

export { useProductDetails, usePolicyDetails, useVehiclesDetails, useRiderDetails };
