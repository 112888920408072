import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useMediaQuery } from '../../../../../../node_modules/@mui/material/index';

const data = [
  {
    id: 1,
    img: require('../../../../../assets/images/ANDA-Icons/08.png'),
    heading: 'Decades of Trusted Expertise',
    text: 'Over 30 years of trusted industry experience, providing tailored advice for your unique needs'
  },
  {
    id: 2,
    img: require('../../../../../assets/images/ANDA-Icons/10.png'),
    heading: 'Unparalleled Service Standards',
    text: 'Exceeding expectations with seamless support at every step.'
  },
  {
    id: 3,
    img: require('../../../../../assets/images/ANDA-Icons/13.png'),
    heading: 'Strong Industry Partnerships',
    text: 'Competitive prices secured through established industry relationships.'
  },
  {
    id: 4,
    img: require('../../../../../assets/images/ANDA-Icons/09.png'),
    heading: 'Tailored Insurance Solutions',
    text: 'Crafted insurance solutions ensuring your business operates optimally.'
  },
  {
    id: 5,
    img: require('../../../../../assets/images/ANDA-Icons/08.png'),
    heading: 'Comprehensive Coverage Options',
    text: 'Diverse insurance options for vehicles, domestic helpers, foreign workers, and corporates.'
  },
  {
    id: 6,
    img: require('../../../../../assets/images/ANDA-Icons/14.png'),
    heading: 'eazy Pte Ltd Affiliation',
    text: 'Aligned with innovation and excellence through our affiliation with eazy Pte Ltd.'
  }
];

export default function ButtonGrid() {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      <Grid item xs={12} mb={5} width="100vw" flexDirection="row" display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h4" align="center">
          Why Choose ANDA Insurance
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ textAlign: 'center' }}>
          <Grid container spacing={1} style={{ display: 'flex', padding: '10px 80px', width: '100vw ' }}>
            {data.map((item, index) => (
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
                key={index}
                style={{ margin: 'auto', width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <img
                  src={item.img}
                  alt="ANDA Logo_RGB"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '70px',
                    marginTop: '4px'
                  }}
                />
                <div
                  style={{ textAlign: 'center', width: isMobile ? '100%' : '30%', fontFamily: 'Medium', fontWeight: '600', marginTop: 10 }}
                >
                  {item.heading}
                </div>
                <div style={{ textAlign: 'center', width: isMobile ? '100%' : '60%', fontFamily: 'Medium' }}>{item.text}</div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </>
  );
}
