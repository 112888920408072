import React from 'react';
import PropTypes from 'prop-types';
import './OverlayLoader.css'; 

const OverlayLoader = ({ show }) => {
  // If 'show' is false or undefined, don't render anything
  if (!show) return null;

  return (
    <div className="overlay-loader">
      <div className="loader"></div>
    </div>
  );
};

OverlayLoader.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default OverlayLoader;
