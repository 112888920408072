import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import SubHeader from 'components/subHeader/SubHeader';
import { useTheme } from 'components/Theme/ThemeProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReusableCard from 'components/cards/ResuableCard';
import InputLabel from '@mui/material/InputLabel';
import '../../policies/styles/ViewPolicy.css';
import Successmodal from 'components/modals/contentmodal/Successmodal';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import { useSelector } from 'react-redux';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import { getUserId } from 'helper/utils';
import { store } from 'store/index';
import { addOtherCompleteState } from 'store/reducers/premiumPolicy';
import { showDanger } from 'components/nudges/Alert';
const ViewPolicyForm = ({ policy }) => {
  const renderField = (label, value) => {
    if (!value) return null;

    return (
      <Grid item xs={12} sm={4} md={4} lg={4} className="input_label_container">
        <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#383c4d' }}>{label}</InputLabel>
        <TextField
          size="small"
          variant="standard"
          disabled
          value={value}
          InputProps={{ disableUnderline: true }}
          style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="secondary_head_cont" mt={2}>
        <Grid container spacing={2}>
          {renderField('Policy Number', policy?.policy?.policyNumber)}
          {renderField('Customer Name', policy?.customer?.customerName)}
          {renderField('Vehicle Model', policy?.policy?.vehicleModel)}
          {renderField('Vehicle Name', policy?.policy?.vehicleName)}
          {renderField('Policy Type', policy?.policy?.recordType)}
          {renderField('Policy Start Date', policy?.policy?.periodOfInsuranceStartDate)}
          {renderField('Policy End Date', policy?.policy?.periodOfInsuranceEndDate)}
          {renderField('Created Date', policy?.policy?.createdDate?.split(',')[0])}
        </Grid>
      </Grid>
    </Grid>
  );
};

const ActiveComponents = ({ policy }) => {
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { makeRequest } = useApiCall();

  const handleDownloadButton = () => {
    setIsOpen(true);
  };

  const handleThankYouButton = () => {
    setIsOpen(false);
  };

  const handleRenew = async (policyData) => {
    try {
      const userId = await getUserId();
      let body = {
        userId: userId,
        productId: policyData?.products[0]?.productcode,
        customerPolicyId: policyData?.policy?.customerPolicyId
      };
      let res = await makeRequest({ method: 'POST', url: API.RenewPolicyDetailsGet, body });
      if (res?.code == 200) {
        let data = res?.data;

        let rider = data.insuredParty;
        let domestic = [];
        rider.map((itm) => {
          domestic.push(itm);
        });

        let addOn = data.items;

        let add = {};
        addOn.forEach((itm) => {
          if (itm.isAdd_On) {
            let obj = {
              itemCode: itm.itemCode,
              salesPrice: itm.salesPrice,
              quantity: itm.quantity
            };
            add[itm.itemCode] = obj;
          }
        });

        let arr = [];
        let riderData = {};
        data.insuredParty?.map((item, index) => {
          let d = new Date();

          let innerData = {
            ...item,
            ['age']: item?.age,
            ['lastName']: item?.lastName,
            ['claims']: item?.claimsIn3Year,
            ['amountOfClaims']: item.amountOfClaimsIn3Year,
            ['contactNumber']: item.contact,
            ['experience']: item?.experienceInMonths,
            ['dob']: item.dob,
            ['license']: item.drivingLicense,
            ['passport']: item.passportNo,
            ['workPermit']: item.workPermitNo,
            ['firstName']: item?.firstName,
            ['nric']: item.nric,
            ['isCheckboxChecked']: item.isOwner,
            ['isRiderCheckboxChecked']: item.isRider,

            id: `${d}@${index}`
          };
          riderData[`${d}@${index}`] = innerData;
        });

        arr.push(rider);
        const isDeliveryExtension = data.deliveryExtension.toLowerCase() === 'yes';
        let initialObj = {
          // Motor policy information
          UEM: data.uen,
          UEMSTATUS: null,
          POIStartDate: data.insuranceStart,
          POIEndDate: data.insuranceEnd,
          isCompanyRegistered: data.companyRegistered,
          isFoodParcel: isDeliveryExtension,
          businessType: 0,
          cm: data?.account?.accName,
          email: data?.account?.accEmail,
          phone: data?.account?.phone,
          //motor vehicle information
          vehicleId: data?.vehicleId || '',
          // vehicleCapacity: data?.capacity || '',
          ageOfVehicle: data?.ageOfMotorCycle || '',
          vehicleRegistration: data?.vrn,
          chasisNumber: data.chasisNo,
          engineNumber: data?.engineNo,
          nameOfHirePurchase: data?.nameOfHirePurchase,
          workShopType: data?.workshopType,
          NCD: data?.ncd,
          claimcount: data?.claimsIn3Year,
          claimamount: data?.amountOfClaimsIn3Year,
          // vehicleTonnage: data?.vehicleTonnage,
          RegistrationDate: data?.reg_manufactureDate,
          // NCDPROTECTOR: data?.ncdProtector,
          riderDataSection: riderData,
          contact: data.contact,
          transactionType: 'Renewal',
          //Foreign Worker package information
          basisofcover: data?.basisOfCover,
          maincontractor: data?.mainContractorName,
          uen: data?.uen,
          CPF: data?.cpfSubmissionNo,
          dischargeDate: data?.dischargeDate,
          transmissionstatus: data?.transmissionStatus,
          bondcall: '',
          bondduration: data?.bondDuration,
          LOIstatus: data?.loiStatus,
          LOItype: data?.loiType,
          fromDate: data.insuranceStart,
          todate: data.insuranceEnd,
          durationInMonths: data?.basisOfCover,
          durationInDays: data?.basisOfCover,
          subcategory: data?.basisOfCover,
          durationwise: '',
          // addOn:add,
          workerSection: riderData,

          //Domestic Employer informatiion
          // name: '',
          // gender: '',
          // nationality: '',
          // nric: '',
          // dob: '',
          // transmissionstatus: '',
          // sbTransmission: '',
          // // phone: '',
          // // email: '',
          // address: '',
          // addOn: {},

          //Domestic Worker information
          ndw: domestic[0].name,
          fin: domestic[0].fin,
          passportno: domestic[0].passportNo,
          dob: domestic[0].dob,
          workpermit: domestic[0].workPermitNo,
          nationalityDHInformation: domestic[0].nationality,

          // Domestic Helper Insurance
          // productname: '',
          UENDHInsurance: '',
          fromdateDHInsurance: data.insuranceStart,
          noofmonthsDHInsurance: data?.durationInMonths,
          noofdaysDHInsurance: '',
          todateDHinsurance: data.insuranceEnd,
          subcategoryDHInsurance: data?.subCategory,
          compnaynameDHInsurance: '',
          emailDHInsurance: '',
          phoneDHInsurance: '',
          navigateFrom: 'renewal'
        };

        initialObj['addOn'] = add;

        store.dispatch(addOtherCompleteState(initialObj));
        let newObj = {
          productName: policy?.data?.products[0]?.productName,
          productDescription: '',
          productID: policy?.data?.products[0]?.productcode,
          policyClass: policy?.data?.policy?.recordType,
          insurer: policy?.data?.products[0]?.insurer
        };

        navigate('/dashboard/PremiumCalculateView', {
          state: { data: { item: { ...res?.data, ...newObj }, policyData: policy, navigateFrom: 'renewal' } }
        });
      } else {
        showDanger(res?.message);
      }
    } catch (e) {
      showDanger(e);
    }
  };

  return (
      <Grid height={'120vh'}>
        <Grid container rowSpacing={2} alignItems="center" justifyContent="space-between" style={{ backgroundColor: currentTheme.cardColor }}>
          <SubHeader
            title="View Policy"
            isLeftEnable={true}
            leftComponent={
              <Button
                size="small"
                variant="contained"
                style={{ borderRadius: 12, color: '#fff', backgroundColor: '#2d2d2d', minWidth: '30px', marginRight: '10px' }}
                onClick={() => navigate('/dashboard/policies', { replace: true })}
              >
                <ArrowBackIcon />
              </Button>
            }
            isRightEnable={true}
            rightComponent={
              <Grid>
                {policy?.data?.policy?.renewable && (
                  <Button
                    size="small"
                    sx={{ borderRadius: 10, backgroundColor: '#0174bf' }}
                    variant="contained"
                    onClick={() => handleRenew(policy?.data)}
                  >
                    Renew Policy
                  </Button>
                )}
              </Grid>
            }
            isRightEnableSecond={true}
            rightSecondComponent={
              <Button
                size="small"
                sx={{ borderRadius: 10, backgroundColor: '#0174bf', marginLeft: 1 }}
                variant="contained"
                onClick={handleDownloadButton}
              >
                <SimCardDownloadOutlinedIcon style={{ fontSize: 14 }} />
                Download
              </Button>
            }
          />
  
          <Grid className="top_container" style={{ color: currentTheme.textColor }} minWidth={'100%'}>
            <ReusableCard
              backgroundColor={currentTheme.bodyColor}
              boxShadow={'0 0 0px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1)'}
              border="#f8f8f8"
              padding={'2%'}
              borderRadius={15}
              content={<ViewPolicyForm policy={policy?.data} />}
            />
          </Grid>
          <Successmodal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleCloseModal={handleThankYouButton}
            title={'Downloading Packages & Premium'}
            subtitle={'Packages & Premium file is downloading...'}
          />
        </Grid>
      </Grid>
    );
  };

const ViewPolicy = () => {
  const { policy } = useSelector((state) => state.persistedReducer.premiumPolicy);

  return <ActiveComponents policy={policy} />;
};

export default ViewPolicy;
