import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './DropDown.css';
import '../../assets/fonts/Fonts.css';

const DropDown =(props)=> {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [value, setValue] = useState(props.value);
  const [data, setData] = useState(props.options);

  const minHeight = data.length > 0 ? 50 : 0;
  const maxHeight = data.length * (props.itemHeight + 5);
  const dropdownHeight = Math.max(minHeight, maxHeight);

  const dropdownAnimation = useSpring({
    height: isDropdownOpen ? dropdownHeight : 0,
    opacity: isDropdownOpen ? 1 : 0,
    config: { duration: 300 }
  });


  useEffect(() => {
    // Set the initial selected value based on the provided ID
    if (props.defaultValue != undefined) {
      const selectedItem = props.options.find(option => option?.id === props?.defaultValue);
      if (selectedItem) {
        setValue(selectedItem?.label);
      }
    }
    setData(props.options)
  }, [props.defaultValue, props.options]);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const renderPlaceholder = () => {
    return (
      <div className="placeholderr" style={{ color: props.color }}>
        <span style={{  fontFamily: 'Medium',color: '#616471',  fontWeight: '200' }}>{value || props.placeholder}</span>
      </div>
    );
  };

  const onItemPress = (item) => {
    setValue(item.label);
    props?.setSelectedValue(item.id);
    setIsDropdownOpen(false);
    props.onValueChange(item);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
 
  return (
    <div
      ref={dropdownRef}
      className={`dropdown-containerr ${isDropdownOpen ? 'open' : ''}`}
      style={{ width: props.width, height: props.height,marginTop:props.marginTop }}
    >
      <div
        style={{ backgroundColor: props.backgroundColor, height: props.heightsss }}
        role="button"
        tabIndex={0}
        onClick={toggleDropdown}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleDropdown();
          }
        }}
        className="dropdown-btnn"
      >
        <span className="selected-valuee">{renderPlaceholder()}</span>
        <span className="arrow-iconn" style={{ color: props.color }}>
          {isDropdownOpen ? <KeyboardArrowUpIcon 
          onClick={toggleDropdown} 
          /> : <KeyboardArrowDownIcon />}
        </span>
      </div>
      <animated.div
        className="dropdown-contentt"
        style={{
          height: dropdownAnimation.height,
          opacity: dropdownAnimation.opacity,
          backgroundColor: props.backgroundColor,
          color: props.color
        }}
      >
 {data.length > 0 ? (
          data.map((item) => (
            <div
              key={item.id}
              role="menuitem"
              tabIndex={0}
              onClick={() => onItemPress(item)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  onItemPress(item);
                }
              }}
              className="dropdown-item spaced-text"
            >
              <span className="label" style={{ fontFamily: 'Medium', color: '#9f9fa4' }}>
                {item?.label}
              </span>
              <span className="icon"></span>
            </div>
          ))
        ) : null}
      </animated.div>
    </div>
  );
};

export default DropDown

DropDown.defaultProps = {
  onValueChange: () => {},
  options: [
    { label: 'option 1', id: 1 },
    { label: 'option 2', id: 2 },
    { label: 'option 3', id: 3 },
    { label: 'option 4', id: 4 }
  ],
  radius: 10,
  left: null,
  right: null,
  itemHeight: 30,
  placeholder: 'Select an option',
  width: '200px',
  height: 'auto',
  backgroundColor: '#f8f8f8',
  color: '#32385C',
  setSelectedValue: () => {}
};