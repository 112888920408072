import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, TextField, Grid, Typography, Paper } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import '../../../packages & premium/styles/SelectCustomerSideBar.css';
import { useTheme } from 'components/Theme/ThemeProvider';
import { useGetCustomerMasterbyNRICGetQuery } from 'store/services/VehicleDetails';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import { API } from 'api/API';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApiCall from 'api/useApiCall';
import { activeItem } from 'store/reducers/menu';
import { store } from 'store/index';
import OverlayLoader from 'components/loader/OverlayLoader';
import { usePackagePremiumState } from 'modules/packages & premium/provider/PackagePremiumStateProvider';
import useNetworkStatus from 'hooks/useNetworkStatus';

const ForeignAddCustomerSidebar = (props) => {
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  const { makeRequest } = useApiCall();
  const { workerSection } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const { addOn } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const { uenData } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { otherState, activeParams } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const [nric, setNric] = useState('');
  const [showList, setShowList] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const network = useNetworkStatus();
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setCustomerDetails({ ...(customerDetails || {}), email: email });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateNric = (nric) => {
    const nricPattern = /^[STFG]\d{7}[A-Z]$/;
    return nricPattern.test(nric);
  };

  const customerBody = {
    firstName: otherState['contact']?.firstName,
    lastName: otherState['contact']?.lastName,
    gender: '',
    email: otherState['contact']?.email,
    maritalStatus: '',
    source: '',
    mobile: otherState['contact']?.mobile || null,
    designation: '',
    occupation: '',
    licensePassDate: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    remarks: '',
    nricNo: otherState['contact']?.nricNo
  };
  const [customerDetails, setCustomerDetails] = useState(customerBody);
  const { data } = useGetCustomerMasterbyNRICGetQuery({ nric: nric || null });

  const handleSubmitCustomer = async () => {
    if (!network) {
      showDanger('No internet connection.');
      return;
    }
    setLoading(true);
    let obj = { itemCode: `${activeParams.productID}`, salesPrice: props?.apiData?.productAmount, quantity: 1, isAdd_On: false };
    const arr = [];
    {
      arr.push(obj);
      if (addOn != undefined) {
        Object?.values(addOn)?.forEach((itm) => {
          arr.push({ itemCode: `${itm?.itemCode}`, salesPrice: itm?.salesPrice, quantity: itm?.quantity, isAdd_On: true });
        });
      }
    }
    const duration = props?.params?.duration;
    try {
      if (isButtonDisabled) return;

      setIsButtonDisabled(true);
      if (!customerDetails?.firstName || !customerDetails?.lastName || !customerDetails?.nricNo || !customerDetails.email) {
        setIsButtonDisabled(false);
        showWarning('Please fill in all required fields');
        setLoading(false);
        return;
      } else if (!validateEmail(customerDetails.email)) {
        setIsButtonDisabled(false);
        showWarning('Please enter a valid email address');
        setLoading(false);
        return;
      } else if (!validateNric(customerDetails.nricNo)) {
        setLoading(false);
        setIsButtonDisabled(false);
        showWarning('Please enter a valid NRIC');
        return;
      } else {
        const requestBodyWorker = Object.values(workerSection)?.map((worker) => {
          return {
            firstName: worker?.firstName,
            lastName: worker?.lastName,
            nric: worker?.nric,
            dob: worker?.dob || null,
            gender: worker?.gender,
            nationality: worker?.nationality,
            contact: worker?.contactNumber,
            email: worker?.email,
            workPermitNo: worker.workPermit,
            passportNo: worker.passport,
            active: true
          };
        });
        let body = {
          nric: uenData.nriC_FIN__c || customerDetails.nricNo,
          uen: uenData.ueN_No__c || otherState.uen,
          isIndividual: otherState.uen === '' ? true : false,
          insuranceStart: otherState.fromDate,
          insuranceEnd: otherState.todate,
          opportunityType: 'Foreign Worker',
          portalUserId: props?.apiData?.userId,
          creditTerm: true,
          durationInMonths: `${duration} Months`,
          transactionType: otherState?.transactionType,
          companyRegistered: false,
          vehicleId: null,
          vrn: null,
          coverageType: null,
          deliveryExtension: null,
          chasisNo: null,
          engineNo: null,
          drivingLicenseDate: null,
          excess: 0,
          claimsDetails: null,
          nameOfHirePurchase: null,
          workshopType: null,
          // vehicleTonnage: '0',
          ncdAmount: 0,
          ncd: null,
          // ncdProtector: null,
          reg_manufactureDate: null,
          bondDuration: null,
          reTransmissionReminder: null,
          reviewedByInsurer: true,
          basisOfCover: otherState.basisofcover,
          mainContractorName: otherState.maincontractor,
          cpfSubmissionNo: otherState.CPF,
          transmissionStatus: 'Waiting on User',
          dischargeDate: '2025-12-23',
          loiStatus: 'PENDING',
          bondCallStatus: 'Premium',
          loiType: 'BLANKET',
          contact: customerDetails,
          items: arr,
          insuredParty: requestBodyWorker,
          productId: props.apiData.productId,
          loginDetailsId: props?.apiData?.loginDetailsId,
          customerPolicyId: null,
          opportunityId: props?.params?.opportunityId || null,
          totalPremiumAmount: props?.apiData.finalTotalPremiumAmount || 0
        };
        if (Object.keys(uenData).length > 0) {
          body['account'] = {
            accName: uenData.name || null,
            accEmail: uenData.email_Address__c || null,
            phone: uenData.phone.toString() || null,
            source: uenData.accountSource,
            bankAccountName: uenData.bank_Account_Name__c,
            bankCode: uenData.bank_Code__c,
            bankName: uenData.bank_Name__c,
            bankAccountNo: uenData.bank_Account_No__c,
            street: uenData.billingStreet,
            city: uenData.billingCity,
            state: uenData.billingState,
            postalCode: uenData.billingPostalCode,
            country: uenData.billingCountry
          };
        } else {
          body['account'] = {
            accName: otherState?.cm || '',
            accEmail: otherState?.email || '',
            phone: otherState?.phone?.toString() || '',
            source: null,
            bankAccountName: null,
            bankCode: null,
            bankName: null,
            bankAccountNo: null,
            street: null,
            city: null,
            state: null,
            postalCode: null,
            country: null
          };
        }
        const response = await makeRequest({ method: 'POST', url: API.FinalSubmissionCustomerPortalForFWDHAPI, body });
        if (response?.code == 200) {
          showSuccess(response?.data?.message);
          props.setShow(false);
          store.dispatch(activeItem({ openItem: ['policies'] }));
          setLoading(false);
          navigate('/dashboard/Policies');
        } else {
          showDanger('Policy Not Created');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (e) => {
    setShowList(true);
    // refetch();
    const searchTerm = e?.target?.value;
    setNric(searchTerm);

    setCustomerDetails({
      firstName: '',
      lastName: '',
      mobile: '',
      email: '',
      nricNo:''
    });
  };


  const handleNameClick = (name) => {
    setShowList(false);
    if (name) {
      setNric(name?.nricNo);
      setCustomerDetails({
        firstName: name?.firstName,
        lastName: name?.lastName,
        mobile: name?.mobilePhone,
        email: name?.finance_Email__c,
        nricNo: name?.nricNo
      });
    }
  };

  function handleNumericKeyPress(event) {
    const keyPressed = event.key;
    if (!/^\d$/.test(keyPressed) && !event.ctrlKey && !event.metaKey && !event.altKey) {
      event.preventDefault();
    }
  }

  return (
    <Grid className="topContainer_SelectCustomerSideBar">
      <Grid className="heading_SelectCustomerSideBar">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Selected Customer
        </Typography>
      </Grid>

      <hr />

      <Grid className="searchbarGrid_SelectCustomerSideBar">
        <TextField
          placeholder="Search"
          className="textfield_SelectCustomerSideBar"
          variant="standard"
          size="small"
          value={nric}
          onChange={handleSearch}
          InputProps={{
            disableUnderline: true,
            endAdornment: <SearchIcon color="action" className="inputPropStyle_SelectCustomerSideBar" />
          }}
        />
      </Grid>
      {showList && data != '' && (
        <Paper
          style={{
            padding: '2%',
            margin: '0.5px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            width: '100%',
            position: 'absolute',
            zIndex: 4,
            maxHeight: '30%',
            overflowY: 'auto'
          }}
        >
          {data?.map((item, index) => (
            <Grid item onClick={() => handleNameClick(item)} style={{ width: '100%' }}>
              <Typography key={index} style={{ width: '100%', cursor: 'pointer' }}>
                {item?.nricNo}
              </Typography>
              <hr style={{ width: '100%', border: 'none', borderBottom: '1px solid lightgray' }} />
            </Grid>
          ))}
        </Paper>
      )}

      <Grid className="stylinghrtags_SelectCustomerSideBar">
        <Grid style={{ width: '20%', marginRight: '2%' }}>
          <hr />
        </Grid>
        <p style={{ marginTop: '1%' }}>Or</p>
        <Grid style={{ width: '20%', marginLeft: '2%' }}>
          <hr />
        </Grid>
      </Grid>

      <Grid className="addCustomersGridStyling">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          Add Customer
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className="allinputpositioningstyling">
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>First Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>
            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.firstName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, firstName: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Last Name</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.lastName}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, lastName: e.target.value })}
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Phone Number</InputLabel>
              {/* <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel> */}
            </div>

            <TextField
              size="small"
              variant="standard"
              value={customerDetails?.mobile}
              onKeyPress={handleNumericKeyPress}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, mobile: e.target.value })}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Email Address</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              type="email"
              size="small"
              variant="standard"
              value={customerDetails?.email}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={handleEmailChange}
            />
          </Grid>

          {/* Last Row */}
          <Grid item xs={6} sm={6} md={6}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>NRIC</InputLabel>
              <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
            </div>

            <TextField
              size="small"
              variant="standard"
              placeholder="e.g. S3272118D"
              value={customerDetails?.nricNo}
              InputProps={{ disableUnderline: true }}
              className="txtinputs_styling"
              onChange={(e) => setCustomerDetails({ ...customerDetails, nricNo: e.target.value })}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        {/* <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium', marginBottom: '2%' }} variant="h5">
          Select Payment Mode
        </Typography>
        <div>
          <Button
            size="small"
            variant="contained"
            // className="creditNoteButton"
            style={{
              borderRadius: '8px',
              color: '#fff',
              backgroundColor: '#00c7b1',
              minWidth: '50px',
              marginRight: '10px',
              padding: '2%',
              fontFamily: 'Medium'
            }}
            onClick={() => alert('Please Make a function to handle the left button action')}
          >
            Credit Term
          </Button> */}
        <div>
          <Button
            size="small"
            variant="contained"
            disabled={isButtonDisabled}
            // className="ProceedToPayButton"
            style={{
              borderRadius: '8px',
              color: isButtonDisabled ? '#000' : '#fff',
              backgroundColor: isButtonDisabled ? '#dfe6e9' : '#002D8C',
              minWidth: '100%',
              marginRight: '10px',
              padding: '2%',
              marginTop: '6%',
              fontFamily: 'Medium'
            }}
            onClick={() => handleSubmitCustomer()}
          >
            Complete Purchase
          </Button>
        </div>
      </Grid>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
          <OverlayLoader show={true} />
        </div>
      )}
      {/* <PaymentRedirectModal isOpen={isOpen} setIsOpen={setIsOpen} handleCloseModal={handleClosePaymentRedirect} />
      <Successmodal
        isOpen={show}
        setIsOpen={setShow}
        handleCloseModal={handleThankYouButton}
        title={'Policy Purchased Successfully'}
        subtitle={"Congratulations! Your policy purchase is now complete and we've thrilled to have you as part of our community"}
      /> */}
    </Grid>
  );
};

export default ForeignAddCustomerSidebar;
