import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from './components/Theme/ThemeProvider';

const App = () => {
  return (
    <ThemeCustomization>
      <ThemeProvider >
        <ScrollTop>
          <ToastContainer />
          <Routes />
        </ScrollTop>
      </ThemeProvider>
    </ThemeCustomization>
  );
};

export default App;
