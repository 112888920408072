import React from 'react';
import { Grid } from '@mui/material';
import useIncorporateFinanceTable from '../hooks/useIncorporateFinanceTable';
import OverlayLoader from 'components/loader/OverlayLoader';
import BasicTable from 'pages/dashboard/BasicTable';
const columns = [
//   { id: 'sr', label: 'Sr.', minWidth: 80 },
  { id: 'transaction', label: 'Transaction Date', minWidth: 140, align: 'center' },
  {
    id: 'invoice',
    label: 'Invoice No.',
    minWidth: 110,
    align: 'center'
  },
  {
    id: 'pno',
    label: 'Policy No.',
    minWidth: 110,
    align: 'center'
  },
  {
    id: 'insured',
    label: 'Insured Description',
    minWidth: 110,
    align: 'center'
  },
  {
    id: 'insurer',
    label: 'Insurer',
    minWidth: 110,
    align: 'center'
  },
  {
    id: 'premium',
    label: 'Premium',
    minWidth: 110,
    align: 'center'
  },
  {
    id: 'tsa',
    label: 'TSA Fee',
    minWidth: 110,
    align: 'center'
  },
  {
    id: 'outstanding',
    label: 'Outstanding',
    minWidth: 110,
    align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 110,
    align: 'center'
  }
];

const IncorporateFinanceTable = () => {
    const { data, handleVerifyClick } = useIncorporateFinanceTable();
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
  
    const handleDateChange = () => {
      handleVerifyClick(startDate, endDate);
    };
   
  return (
    <Grid width={400} item xs={12} md={12} lg={12}>
      <BasicTable
        columns={columns}
        rows={data}
        searchOn={['cname', 'psd', 'ped']}
        showSearchBar={false}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleVerifyClick={handleDateChange}
      />
    </Grid>
  );
};

export default IncorporateFinanceTable;
