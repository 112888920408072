import React from 'react';
import { Grid, Typography } from '@mui/material';
import ReusableCard from 'components/cards/ResuableCard';
import { useTheme } from 'components/Theme/ThemeProvider';
import '../../myprofile/styles/Profile.css';
import ProfileView from '../components/ProfileView';

const AgentProfile = () => {
  const { currentTheme } = useTheme();
  return (
    <Grid className="top_container" style={{ color: currentTheme.textColor }}>
      <div className="headerText_Styling">
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }} variant="h5">
          My Profile
        </Typography>
        <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium' }}>
          Contact support staff for profile update and changes
        </Typography>
      </div>

      <ReusableCard
        backgroundColor={currentTheme.bodyColor}
        boxShadow="0 0 0px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1)"
        border="#f8f8f8"
        padding="1%"
        borderRadius={15}
        content={<ProfileView />}
      />

      {/*  Code commented because SOA is not decided yet */}
      {/* <Typography style={{ color: currentTheme.textColor, fontFamily: 'Medium', marginTop: '2%' }} variant="h5">
        SOA
      </Typography>
      <Grid mt={2}>
        <ProfileTable />
      </Grid> */}
      {/* <div style={{ height: "40vh", display: "flex", justifyContent: "center", alignItems: "center" }} >
    <Grid container alignItems="center" justifyContent="center" >
      <Grid item xs={12} >
        <Typography variant="h5" style={{ textAlign: 'center' }}>Coming Soon.....</Typography>
      </Grid>
    </Grid>
  </div> */}
    </Grid>
  );
};

export default AgentProfile;
