import React, { useState, useEffect } from 'react';
import { Grid, Button, IconButton, InputLabel } from '@mui/material';
import ReusableCard from 'components/cards/ResuableCard';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DropDown from 'components/dropdown/DropDown';
import AddIcon from '@mui/icons-material/Add';
import RiderSectionTable from 'modules/packages & premium/components/RiderSectionTable';
import AddRiderModal from 'components/modals/contentmodal/AddRiderContent';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { addOtherIndividualState, removeOtherIndividualState1 } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';

const CommonDropdown = ({ label, item, value, onValueChange, placeholder }) => (
  <>
    <InputLabel style={{ fontFamily: 'Thin', fontSize: '14px', marginLeft: '2%', color: '#B8B9BF', fontWeight: 600 }}>{label}</InputLabel>
    <DropDown
      marginTop="8px"
      width="88%"
      heightsss="45px"
      placeholder={placeholder}
      height="40px"
      options={item?.ddoptions || []}
      onValueChange={(selectedItem) => onValueChange(item.value, selectedItem.label)}
      defaultValue={value}
      style={{ minWidth: '200px' }}
    />
  </>
);

const ContainerContent = ({ handleNextButton, handlePreviousButton }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [data, setData] = useState([]);

  const {CustomerriderDataSection} = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const formData = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);

  const handleClickOnEdit = (rider, action) => {
    setOpen(true);
    setSelectedRow(rider);
  };

  const handleDelete = (id) => {
    try {
      dispatch(removeOtherIndividualState1(id));
    } catch (e) {
      console.error(e);

    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const rows = Object?.values(CustomerriderDataSection)?.map((rider, index) => ({
      sr: index + 1,
      id: rider?.id,
      RiderName: `${rider?.firstName} ${rider?.lastName}`,
      dob: rider?.dob,
      gender: rider?.gender,
      experience: rider?.experience,
      Action: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              backgroundColor: '#4cb748',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '55px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium'
            }}
            onClick={() => handleClickOnEdit(rider, 'edit')}
          >
            <EditIcon style={{ fontSize: '15px', marginRight: '2.5px' }} />
            Edit
          </div>
          <div
            style={{
              backgroundColor: '#0174BF',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '75px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium',
              marginLeft: '10px'
            }}
            onClick={() => handleDelete(rider.id)}
          >
            <DeleteOutlineOutlinedIcon style={{ fontSize: '15px' }} />
            Delete
          </div>
        </div>
      )
    }));
    setData(rows);
  }, [CustomerriderDataSection]);
  const handleInputChange = (field, value) => {
    store.dispatch(addOtherIndividualState([field, value]));
  };
  const dropdownOptions = {
    ridingExp: [
      { label: 'Male', id: 1 },
      { label: 'Female', id: 2 }
    ],
    ncd: [
      { label: 'Single', id: 1 },
      { label: 'Married', id: 2 },
      { label: 'Widow/Divorced', id: 3 }
    ],
    claimscount: [
      { label: 'Accountant', id: 1 },
      { label: 'Profession 2', id: 2 }
    ]
  };
  const dropdownItems = [
    { label: 'How many years have you been riding?', value: 'ridingExp', ddoptions: dropdownOptions.ridingExp },
    { label: 'What is your No-claim Discount (NCD)?', value: 'ncd', ddoptions: dropdownOptions.ncd },
    {
      label: 'How many claims were field in the past 3 years by you and the additional named rider(s),if any?',
      value: 'claimscount',
      ddoptions: dropdownOptions.claimscount
    }
  ];
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={2} xs={12} alignItems={'center'}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {dropdownItems.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <InputLabel style={{ fontFamily: 'Thin', fontSize: '14px', marginLeft: '2%', color: '#B8B9BF', fontWeight: 600 }}>
                  {item.label}
                </InputLabel>
                <CommonDropdown item={item} state={formData} onValueChange={handleInputChange} />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} mt={1} alignItems="center">
            <Grid item xs={8}>
              <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '2%', color: '#000', fontWeight: 600 }}>
                Riding Section
              </InputLabel>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Button
                size="small"
                onClick={handleOpen}
                startIcon={<AddIcon sx={{ marginLeft: '5px' }} />}
                style={{ backgroundColor: '#00C7B1', color: 'white', fontFamily: 'Medium', borderRadius: '8px' }}
              >
                Add Rider
              </Button>
              <AddRiderModal isOpen={open} onClose={handleClose} selectedRow={selectedRow} />
            </Grid>
          </Grid>
          <RiderSectionTable columns={MotorAddRiderTableColumns} rows={data} />
          <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
            <Grid item xs={8} sm={6} md={6} lg={5}>
              <Button
                size="small"
                variant="contained"
                style={{ backgroundColor: '#e4e4e4', width: '100%', padding: '3%', color: '#000000', height: '100%' }}
                onClick={handlePreviousButton}
              >
                <IconButton>
                  <WestIcon />
                </IconButton>
                Previous
              </Button>
            </Grid>
            <Grid item xs={8} sm={6} md={6} lg={5}>
              <Button
                size="small"
                variant="contained"
                style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff', height: '100%' }}
                onClick={handleNextButton}
              >
                Next
                <IconButton style={{ marginLeft: '5px', color: '#ffffff' }}>
                  <EastIcon />
                </IconButton>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const MotorVehicleRidingExperienceContainer = ({ handleNextButton, handlePreviousButton }) => {
  return (
    <ReusableCard
      backgroundColor="#fff"
      border="1px solid #ddd"
      padding="3%"
      borderRadius={15}
      content={<ContainerContent handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />}
    />
  );
};

export default MotorVehicleRidingExperienceContainer;
const MotorAddRiderTableColumns = [
  { id: 'sr', label: 'Sr.', minWidth: 40, align: 'center' },
  { id: 'RiderName', label: 'Rider Name', minWidth: 50, align: 'center' },
  { id: 'gender', label: 'Gender', align: 'center' },
  { id: 'dob', label: 'DOB', align: 'center' },
  { id: 'experience', label: 'Experience', align: 'center' },
  { id: 'Action', label: '', align: 'center' }
];
