import React, { useState } from 'react';
import CardLayout from '../CardLayout';
import { Grid, TextField, InputLabel } from '@mui/material';
import '../../styles/InformationForm.css';
import { usePackagePremiumState } from 'modules/packages & premium/provider/PackagePremiumStateProvider';
import { useProductDetails } from 'modules/packages & premium/hooks/useVehicle';

const MotorVehicleProductInformation = React.memo((props) => {
  const { params  } = usePackagePremiumState();
  const { data } = useProductDetails(params);
  const [isOpen, setIsOpen] = useState(true);
  const toggleForm = () => {
    setIsOpen(!isOpen);
  };


  return (
    <CardLayout isOpen={isOpen} setIsOpen={setIsOpen} toggleForm={toggleForm} heading="Product Information">
      {data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Grid item xs={12} md={4} alignItems={'center'}>
              <InputLabel className="inputs_styless_">{item.label}</InputLabel>
              <TextField
                disabled={item?.isEnable}
                //onChange={(txt) => onChangeText(txt.target.value, item?.value)}
                required={item?.isRequired}
                size="small"
                variant="standard"
                InputProps={{ disableUnderline: true }}
                className="txtinputs_stylingg"
                value={item?.value}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </CardLayout>
  );
});

export default MotorVehicleProductInformation;
