import {Button } from '@mui/material';
import React from 'react';
import ReusableModal from '../routemodal/ResuableModal';
import orderplaced from '../../../assets/images/users/orderplaced.png';
import '../../../assets/fonts/Fonts.css';
import { Orderplaced_style } from '../modalcss/Orderplaced_style';

export default function OTPResetModal(props) {
     
    return (
      <div>
        <ReusableModal
          showCloseButton={false}
          isOpen={props?.isOpen}
          setIsOpen={props?.setIsOpen}
          disbleBackdropClick={false}
          height="100%"
          width="100%"
          borderRadius="10px"
          backgroundColor="#F4FDFE"
          ModalContent={
            <div style={Orderplaced_style.modalContainer}>
              <div style={Orderplaced_style.modalContent}>
                <img src={orderplaced} alt="warehouse" style={Orderplaced_style.image} />
                <div style={Orderplaced_style.textContainer}>
                  <span style={Orderplaced_style.title}>Successfull Password Reset</span>
                  <span style={Orderplaced_style.subtitle}>Your password reset request has been successfully completed</span>
                </div>
              </div>
              <Button size="normal" sx={Orderplaced_style.button} variant="contained" onClick={props?.handleCloseModal}>
                Acknowledge
              </Button>
              <div style={{height:30}}/>
            </div>
          }
        />
      </div>
    );
  }
  