import React from 'react';
import PropTypes from 'prop-types';
import ReusableModal from '../routemodal/ResuableModal';
import { Button } from '@mui/material';
import '../../../assets/fonts/Fonts.css';
import { successModalStyles } from '../modalcss/Successmodal_style';
import download_image from '../../../assets/images/ANDA-Icons/39.png';

const SuccessModal = (props) => {
  return (
    <div style={successModalStyles.modalContainer}>
      <ReusableModal
        showCloseButton={false}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        height="315px"
        width="325px"
        borderRadius="10px"
        ModalContent={
          <div style={successModalStyles.contentContainer}>
            <img src={download_image} alt="confirmation" style={successModalStyles.image} />
            <div style={successModalStyles.textContainer}>
              <span style={successModalStyles.title}>{props.title}</span>
              <span style={successModalStyles.subtitle}>{props.subtitle}</span>
            </div>
            <Button size="normal" sx={successModalStyles.button} variant="contained" onClick={props.handleCloseModal}>
              Thank You
            </Button>
          </div>
        }
      />
    </div>
  );
};

SuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default SuccessModal;
