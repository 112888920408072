import { useEffect, useState } from 'react';
import { DomesticEmployerData, DomesticHelperInsuranceData, DomesticWorkerInformationData } from '../static';

//hook for domestic employeer information
const useEmployeeInformation = (params) => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  useEffect(() => {
    setData(DomesticEmployerData);
  }, []);

  return { data, isLoading, isError };
};

//A Hook to getDomesticWorkerInformationDetails
const useDomesticWorkerInformation = () => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  useEffect(() => {
    setData(DomesticWorkerInformationData);
  }, []);

  return { data, isLoading, isError };
};
//A Hook to getDomesticHelperInsuranceDetails
const useDomesticHelperInsurance = () => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  useEffect(() => {
    setData(DomesticHelperInsuranceData);
  }, []);

  return { data, isLoading, isError };
};


export { useDomesticWorkerInformation, useDomesticHelperInsurance, useEmployeeInformation };
