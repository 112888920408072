
import React,{useState,useEffect} from 'react';
import { Grid } from '@mui/material';
import '../../policies/styles/Policies.css';
import useGetPoliciesTable from '../hooks/useGetPoliciesTable';
import OverlayLoader from 'components/loader/OverlayLoader';
import BasicTable from 'pages/dashboard/BasicTable';

const columns = [
  { id: 'sr', label: 'Sr.',minWidth:50 },
  { id: 'nricno', label: 'NRIC',minWidth:50 },
  { id: 'cname', label: 'Customer Name',minWidth:130  },
  { id: 'psd', label: 'Policy Start',minWidth:130  },
  { id: 'ped', label: 'Policy End',minWidth:102 },
  { id: 'pa', label: 'Premium Amt.',minWidth:130 },
  { id: 'commision', label: 'Commission',minWidth:115 },
  { id: 'createdat', label: 'Created at',minWidth:130 },
  { id: 'Pno', label: 'Policy No.' },
  { id: 'policystatus', label: 'Status' },
  { id: 'Action', label: 'Action' }
];


const PoliciesTable = () => {
  const [data, loading] = useGetPoliciesTable();
  const [showLoader, setShowLoader] = useState(true);
  const timeoutDuration = 5000;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, timeoutDuration);

    return () => clearTimeout(timeout);
  }, []);


  if (loading && showLoader) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
        <OverlayLoader show={true} />
      </div>
    );
  }

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case 'Active':
        return '#d8efd7';
      case 'Inactive':
        return '#f9dfdf';
      default:
        return '';
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Active':
        return '#6fc56b';
      case 'Inactive':
        return '#dc4a4a';
      default:
        return '';
    }
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <BasicTable
        rows={data}
        columns={columns}
        searchOn={['cname', 'Pno', 'nricno','policystatus']}
        getStatusBackgroundColor={getStatusBackgroundColor}
        getStatusColor={getStatusColor}
        showDatePicker={false}
      />
    </Grid>
  );
};

export default PoliciesTable;
