import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { usePackagePremiumState } from 'modules/packages & premium/provider/PackagePremiumStateProvider';
import { useSelector } from 'react-redux';
import OverlayLoader from 'components/loader/OverlayLoader';
import { useNavigate } from 'react-router-dom';
import { getLoginDetailsId, getUserId } from 'helper/utils';
import { showDanger, showWarning } from 'components/nudges/Alert';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import _ from 'lodash';
import { clearRiderDataSection, removeOtherState } from '../../../../store/reducers/premiumPolicy';
import { store } from 'store/index';
import useNetworkStatus from 'hooks/useNetworkStatus';

const MotorVehicleBottomPortion = (props) => {
  const { params } = usePackagePremiumState();
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const isCompanyRegistered = otherState.isCompanyRegistered;
  const { riderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  let riderLength = Object?.keys(riderDataSection)?.length;
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { makeRequest } = useApiCall();
  const navigate = useNavigate();
  const network = useNetworkStatus();
  const compareDates = (date1, date2) => {
    return new Date(date1) - new Date(date2);
  };

  const findTheYoungest = () => {
    try {
      let d = Object.values(riderDataSection);
      d.sort((a, b) => compareDates(a.age, b.age));
      return d[0] || null;
    } catch (e) {
      console.error("Error in fincTheYoungest"+e);

    }
  };

  useEffect(() => {
    if (isCompanyRegistered) {
      store.dispatch(clearRiderDataSection());
    }
  }, [isCompanyRegistered]);

  const handleClick = async () => {
    try {
      if (!network) {
        showDanger('No internet connection.');
        return;
      }
      let userId = await getUserId();
      let loginDetailsId = await getLoginDetailsId();
      let mergedStates = { ...otherState };
      let ncd = mergedStates?.NCD;
      let selectedOwner = Object.values(riderDataSection).find((owner) => owner.isCheckboxChecked);
      let selectedOwnerRider = Object.values(riderDataSection).find((owner) => owner.isRiderCheckboxChecked);
      let ownerGender = selectedOwner?.gender || Object.values(riderDataSection).find((rider) => rider.gender)?.gender;

      let { vehicleRegistration, vehicleId, ageOfVehicle, claimamount, isFoodParcel, claimcount } = otherState;
      if (vehicleRegistration === '') {
        setIsButtonDisabled(false);
        showWarning('Please enter vehicle registration number');
        return;
      }

      if (claimcount === '') {
        setIsButtonDisabled(false);
        showWarning('Please select the claim count.');
        return;
      }
      if (claimcount === 1 && !claimamount) {
        setIsButtonDisabled(false);
        showWarning('Please fill the claim amount.');
        return;
      }

      if (vehicleId === '') {
        setIsButtonDisabled(false);
        showWarning('Please select the vehicle from vehicle list');
        return;
      }

      if (ageOfVehicle === '') {
        setIsButtonDisabled(false);
        showWarning('Please select the age of vehicle');
        return;
      }
     

      if (claimcount === '1' && claimamount > 20000 && !isFoodParcel) {
        setIsButtonDisabled(false);
        showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
        return;
      }
      if (claimcount === '1' && claimamount > 15000 && isFoodParcel) {
        setIsButtonDisabled(false);
        showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
        return;
      }

      if (claimcount === '2 or more') {
        setIsButtonDisabled(false);
        showWarning('Quotation could not be generated due to claims experience. Please contact ANDA for further assistance.');
        return;
      }
      if (Object.values(riderDataSection).length > 0) {
        const existingOwner = Object.values(riderDataSection).some((rider) => rider.isCheckboxChecked);
        if (!existingOwner && !otherState?.isCheckboxChecked) {
          showWarning('At least one rider must be selected as the owner');
          return;
        }
      }

      let riderAge;
      let ridingExperience;
      let young;
      if (!isCompanyRegistered) {
        young = findTheYoungest();
        riderAge = parseInt(young?.age) || 0;
        if (selectedOwner && selectedOwner.isRiderCheckboxChecked) {
          ridingExperience = selectedOwner.experience;
        } else {
          ridingExperience = '13 months';
        }
      }
      let countLessthan1YearExp = 0;
      let riderCount = 0;

      Object.values(riderDataSection).forEach((rider) => {
        const isOwner = rider === selectedOwner;
        const isOwnerRider = rider === selectedOwnerRider;

        if (!isOwner) {
          const totalMonths = parseInt(rider.experience, 10);
          if (totalMonths < 12) {
            countLessthan1YearExp++;
          }
          riderCount++;
        }
      });

      if (selectedOwner && selectedOwner.isRiderCheckboxChecked) {
      }

      if (isCompanyRegistered) {
        riderCount = 0;
      }

      setIsLoading(true);
      let body = {
        userId: userId || '',
        productMasterID: params?.productID || '',
        businessType: 0,
        vehicleId: mergedStates?.vehicleId || '',
        capacity: mergedStates?.vehicleCapacity || '',
        ageOfMotorCycle: mergedStates?.ageOfVehicle || '',
        isCompanyRegisted: mergedStates?.isCompanyRegistered,
        isFoodDelivery: mergedStates?.isFoodParcel,
        riderAge: riderAge || 0,
        ridingExperience: ridingExperience,
        gender: ownerGender || '',
        claimsIn3Year: mergedStates?.claimcount,
        amountOfClaimsIn3Year: mergedStates?.claimamount || '0',
        ncdPercentage: ncd || 0,
        riderCount: riderCount,
        vehicleRegistrationNumber: mergedStates?.vehicleRegistration || '',
        chassisNumber: mergedStates?.chasisNumber || '',
        engineNumber: mergedStates?.engineNumber || '',
        nameofHirePurchase: mergedStates?.nameOfHirePurchase || '',
        claimsDetails: '0',
        registrationDate: mergedStates?.RegistrationDate || '',
        loginDetailsId: loginDetailsId || '',
        poiStartDate: mergedStates?.POIStartDate || '',
        poiEndDate: mergedStates?.POIEndDate || '',
        uen: mergedStates?.UEM || '',
        companyName: mergedStates?.cm || '',
        email: mergedStates?.email || '',
        phone: mergedStates?.phone || '',
        countLessthan1YearExp: countLessthan1YearExp
      };
      let res = await makeRequest({ method: 'POST', url: API.PackagesAndPremiumCalculation, body });
      if (res?.code === 200) {
        setIsButtonDisabled(true);
        setIsLoading(false);
        navigate('/dashboard/PremiumCalculation', { state: { data: params, apiData: res?.data[0], young: young } });
      } else {
        showDanger(res?.message);
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    } catch (e) {
      showDanger(e);
      setIsLoading(false);
    }
  };
  const handleResetClick = () => {
    store.dispatch(removeOtherState());
    window.location.reload();
  };

  return (
    <>
      <OverlayLoader show={isLoading} />
      <Grid
        container
        xs={12}
        sm={6}
        md={4}
        lg={4}
        mt={2}
        ml={2}
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
      >
        <Button
          variant="contained"
          disabled={riderLength === 0 && !isCompanyRegistered}
          style={{
            backgroundColor: isCompanyRegistered || riderLength > 0 ? '#002D8C' : 'grey',
            borderRadius: '5px',
            fontFamily: 'Medium',
            color: isCompanyRegistered ? '#FFFFFF' : '#fff',
            width: '48%'
          }}
          onClick={handleClick}
        >
          Calculate Premium
        </Button>
        <Button
          onClick={() => handleResetClick()}
          variant="contained"
          style={{
            backgroundColor: '#fff',
            borderRadius: '5px',
            color: '#002D8C',
            fontFamily: 'Medium',
            width: '48%',
            border: '1px solid #002D8C'
          }}
        >
          Reset
        </Button>
      </Grid>
    </>
  );
};

export default MotorVehicleBottomPortion;
