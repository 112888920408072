import { useEffect, useState } from 'react';
import { ForeignWorkerPackageData } from '../static';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { store } from 'store/index';
import { removeOtherIndividualState } from 'store/reducers/premiumPolicy';

// hook for the package Information Details

const usePackageDetails = () => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  useEffect(() => {
    setData(ForeignWorkerPackageData);
  }, []);

  return { data, isLoading, isError };
};

const useWorkerDetails = (handleClickOnEdit) => {
  const [data, setData] = useState([]);
  const [isLoading] = useState(false);
  const [isError] = useState(false);
  const { workerSection } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const handleDelete = (id) => {
    try {
      store.dispatch(removeOtherIndividualState(id));
    } catch (e) {
      console.error("Error in useWorkerDetails"+e);

    }
  };

  useEffect(() => {
    const rows = Object.values(workerSection).map((worker, index) => ({
      sr: index + 1,
      id: worker?.id,
      firstName: worker?.firstName,
      lastName: worker?.lastName,
      workPermit: worker?.workPermit,
      age: worker?.age,
      nationality: worker?.nationality,
      nric: worker?.nric,
      gender: worker?.gender,
      contactNumber: worker?.contactNumber,
      email: worker?.email,
      passport: worker?.passport,
      Action: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              // backgroundColor: '#4cb748',
              // color: '#fff',
              // borderRadius: 20,
              // fontSize: 12,
              // cursor: 'pointer',
              // width: '80px',
              // display: 'flex',
              // justifyContent: 'space-evenly',
              // alignItems: 'center',
              // padding: '3px',
              // height:'20px',
              // fontFamily: 'Roman', 
              // fontWeight:800,
              backgroundColor: '#4cb748',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
                width: '55px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium'
              
            }}
            onClick={() => handleClickOnEdit(worker, 'edit')}
          >
            <EditIcon style={{ fontSize: '15px'}} />
            Edit
          </div>
          <div
            style={{
              backgroundColor: '#0174BF',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
                width: '75px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium',
              marginLeft: '10px',
            }}
            onClick={() => handleDelete(worker.id)}
          >
            <DeleteOutlineOutlinedIcon style={{ fontSize: '15px' }} />
            Delete
          </div>
        </div>
      )
    }));
    setData(rows);
  }, [workerSection]);

  return { data, isLoading, isError };
};

///////////////subcategory and duration dropdown ////////////////
const useDropdown = (item, dropdownData) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!dropdownData) {
          setIsLoading(false);
          return;
        }
        let res = item.map((dropdownItem) => {
          let obj = { ...dropdownItem };
          switch (dropdownItem?.label) {
            case 'Sub Category':
              obj['ddoptions'] =
                dropdownData.subCategoryList?.map((subCategory) => ({
                  id: subCategory?.subCategory,
                  label: subCategory?.subCategory,
                  value: 'subcategory'
                })) || [];
              // obj['ddoptions'].unshift({ id: 'None', label: 'None', value: 'subcategory' });
              break;
            case 'Duration In Months':
              obj['ddoptions'] =
                dropdownData.durationInMonthList?.map((duration) => ({
                  id: duration.durationInMonthShow,
                  label: duration.durationInMonthShow,
                  value: 'durationInMonths'
                })) || [];
              // obj['ddoptions'].unshift({ id: '0', label: 'None', value: 'durationInMonths' });
              break;
            default:
              obj['ddoptions'] = [];
              break;
          }
          return obj;
        });

        setData(res);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dropdownData]);

  return { data, isLoading, isError };
};

export { usePackageDetails, useWorkerDetails, useDropdown };
