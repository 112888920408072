import React, { useState } from 'react';
import { Grid } from '@mui/material';
import GRNTabel from 'components/GRNTable/GRNTabel';
import ProfileInformationForm from '../components/ProfileInformationForm';
import useGetPoliciesTable from 'modules/policies/hooks/useGetPoliciesTable';
import useCustomerPolicesGet from '../hooks/useCustomerPolicesGet';
import RaiseEndorsementModal from '../components/RaiseEndorsementModal';

const headings = [{ name: 'Profile Information' }, { name: 'My Policies' }, { name: 'Endorsements' }];

const LoginCustomer = () => {
  const [data, loading] = useCustomerPolicesGet();

  const [selectedHeading, setSelectedHeading] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const handleRaiseEndorseMent = () => {
    setIsOpen(true);
  };

  const handleHeadingClick = (index) => {
    setSelectedHeading(index);
  };
  const columns1 = [
    { id: 'sr', label: 'Sr.', minWidth: 120, align: 'left' },
    { id: 'Pt', label: 'Policy type', minWidth: 120, align: 'left' },
    {
      id: 'pd',
      label: 'Purchase date',
      align: 'center',
      minWidth: 160
    },
    {
      id: 'rd',
      label: 'Renewal date',
      align: 'center',
      minWidth: 160
    },
    {
      id: 'premium',
      label: 'Premium',
      align: 'center',
      minWidth: 160
    },

    {
      id: 'Action',
      align: 'left'
    }
  ];
  const rows1 = [
    {
      sr: '1',
      Pt: 'ETIQA Private car motor',
      pd: '11 May 2023',
      rd: '12 May 2024',
      premium: '$524.76',
      Action: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            onClick={handleRaiseEndorseMent}
            style={{
              backgroundColor: '#0174BF',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '140px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px'
            }}
          >
            Raise Endorsements
          </div>
        </div>
      )
    }
  ];
  const columns = [
    { id: 'sr', label: 'Sr.', minWidth: 120, align: 'left' },
    { id: 'Pt', label: 'Product', minWidth: 120, align: 'left' },
    {
      id: 'et',
      label: 'Endorsements type',
      align: 'center',
      minWidth: 160
    },
    {
      id: 'rd',
      label: 'Raised date',
      align: 'center',
      minWidth: 160
    },
    {
      id: 'status',
      label: 'Status',
      align: 'center',
      minWidth: 160
    },

    {
      id: 'Action',
      align: 'left'
    }
  ];
  const rows = [
    {
      sr: '1',
      Pt: 'Motor',
      et: 'Add/Delete named order',
      rd: '12 May 2024',
      status: 'open',
      Action: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              backgroundColor: '#00C7B1',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '60px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px'
            }}
          >
            View
          </div>
        </div>
      )
    },
    {
      sr: '2',
      Pt: 'Corporate',
      et: 'Add/Delete named order',
      rd: '12 May 2024',
      status: 'open',
      Action: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              backgroundColor: '#00C7B1',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '60px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px'
            }}
          >
            View
          </div>
        </div>
      )
    }
  ];

  const renderTable = () => {
    const tables = [<ProfileInformationForm />, <GRNTabel rows={rows1} columns={columns1} />, <GRNTabel rows={rows} columns={columns} />];

    return tables[selectedHeading];
  };

  return (
    <Grid container justifyContent="center" alignItems="center" mt={4}>
      <Grid item xs={12} md={8}>
        <Grid
          container
          justifyContent="center"
          style={{ width: '100%', backgroundColor: '#fff', borderTopLeftRadius: 10, borderTopRightRadius: 10, height: '60px' }}
        >
          {headings.map((item, index) => (
            <Grid
              key={index}
              item
              xs={4}
              style={{
                display: 'flex',
                height: '3.7rem',
                cursor: 'pointer',
                fontFamily: 'Medium',
                fontSize: '16px',
                color: selectedHeading === index ? '#002D8C' : '#000',
                borderBottom: selectedHeading === index ? '5px solid #002D8C' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
              }}
              onClick={() => handleHeadingClick(index)}
            >
              <Grid item style={{ margin: 'auto' }}>
                {item.name}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid style={{ width: '100%' }}>{renderTable()}</Grid>
        {<RaiseEndorsementModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => setIsOpen(false)} />}
      </Grid>
    </Grid>
  );
};

export default LoginCustomer;
