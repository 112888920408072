import React, { useState } from 'react';
import Navbar from '../../corporate/customerlandingpage/components/NavBar';
import { Grid } from '../../../../../node_modules/@mui/material/index';
import workers from '../../../../assets/images/OneDrive_2024-05-10/png file/ANDA_ProductBanner_ForeignWorker.jpg';
import Footer from '../../corporate/customerlandingpage/components/Footer';
import Stepper from '../../domestic helper policy view/components/Stepper';
import { useNavigate } from 'react-router-dom';
import DomesticHelperPolicyAddOn from '../../domestic helper policy view/components/DomesticHelperPolicyAddOn';
import ForeignWorkerCustomerPackageInformation from './ForeignWorkerCustomerPackageInformation';
import ForeignWorkerCustomerReview from './ForeignWorkerCustomerReview';
import ForeignWorkerDetailsCustomer from './ForeignWorkerDetailsCustomer';
import ForeignWorkerPremiumAmount from './ForeignWorkerPremiumAmount';

const ForeignWorkerPolicyCoverages = () => {
  const [activeSteps, setActiveSteps] = useState(0);
  const navigate = useNavigate();

  const handleNextButton = () => {
    setActiveSteps((prev) => prev + 1);
    window.scrollTo(0, 0);
  };

  const handlePreviousButton = () => {
    if (activeSteps === 0) {
      navigate('/DomesticHelper');
      window.scrollTo(0, 0);
    } else {
      setActiveSteps((prev) => prev - 1);
      window.scrollTo(0, 0);
    }
  };

  const handleCancelButton = () => {
    navigate('/DomesticHelper');
  };

  const handleProceedPayment = () => {
    navigate('/DomesticHelper');
  };

  const renderPageComponent = () => {
    switch (activeSteps) {
      case 0:
        return <ForeignWorkerCustomerPackageInformation handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 1:
        return <ForeignWorkerDetailsCustomer handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 2:
        return <ForeignWorkerPremiumAmount handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      case 3:
        return <ForeignWorkerCustomerReview handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />;
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      xs={12}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative' }}
    >
      <Navbar />
      <div style={{ position: 'relative', width: '100%', height: '35vh' }}>
        <img src={workers} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '35%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            color: 'white',
            zIndex: 1
          }}
        >
          <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium', marginBottom: '5px' }}>Foreign Workers</p>
          <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>ANDA Insurance: Tailored Reliable Always Ready</p>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '220px',
            left: '15%',
            width: '70%'
          }}
        >
          <Stepper width="100%" steps={StepperSteps} activeSteps={activeSteps} boxwidth={'100%'} />
        </div>
      </div>

      <Grid item xs={12} md={12} lg={12} mt={4} sx={{ width: '70%', marginTop: '5rem' }}>
        {renderPageComponent()}
        {/* <DomesticHelperPolicyPage handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} /> */}
      </Grid>
      <Grid item xs={12} md={7} lg={12} mt={4} sx={{ width: '100%', marginTop: '5rem' }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default ForeignWorkerPolicyCoverages;

const StepperSteps = ['Add-ons Benefits', 'Worker Details', 'Calculate Premium', 'Review and Confirm'];
