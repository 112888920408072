// import React, { useState } from 'react';
// import '../../../../assets/fonts/Fonts.css';
// import ReusableModal from 'components/modals/routemodal/ResuableModal';
// import DropDown from 'components/dropdown/DropDown';
// import { TextField } from '../../../../../node_modules/@mui/material/index';
// import { Grid, Button, InputLabel } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { addWorkerSection } from 'store/reducers/premiumPolicy';
// import { showSuccess } from 'components/nudges/Alert';

// export default function ForeignWorkerAddWorkerModalCustomer(props) {
//   //   const [options, setOptions] = useState([]);
//   const selectedRowData = props.selectedRow;
//   const dispatch = useDispatch();
//   const [firstName, setFirstName] = useState(selectedRowData ? selectedRowData.firstName : '');
//   const [lastName, setLastName] = useState(selectedRowData ? selectedRowData.lastName : '');
//   const [nationality, setNationality] = useState(selectedRowData ? selectedRowData.nationality : '');
//   const [dob, setDob] = useState(selectedRowData ? selectedRowData.dob : '');
//   const [fin, setFin] = useState(selectedRowData ? selectedRowData.fin : '');
//   const [workPermit, setWorkPermit] = useState(selectedRowData ? selectedRowData.workPermit : '');

//   //   let key = new Date().toString();
//   const handleAddWorker = () => {
//     let key = selectedRowData ? (selectedRowData?.id ? selectedRowData?.id : new Date().toString()) : new Date().toString();
//     const workerData = {
//       id: key,
//       firstName,
//       lastName,
//       nationality,
//       dob,
//       fin,
//       workPermit
//     };
//     let obj = {};
//     obj[key] = workerData;
//     dispatch(addWorkerSection([key, workerData]));
//     setFirstName('');
//     setLastName('');
//     setNationality('');
//     setDob('');
//     setFin('');
//     setWorkPermit('');
//     props.onClose(false);
//     if (props.getEditText === 'edit') {
//       showSuccess('Worker Edited Successfully!');
//     } else {
//       showSuccess('Worker Added Successfully!');
//     }
//   };

//   const options = [
//     { label: 'India', id: 'India' },
//     { label: 'China', id: 'China' },
//     { label: 'Russia', id: 'Russia' },
//     { label: 'Singapore', id: 'Singapore' },
//     { label: 'Thailand', id: 'Thailand' }
//   ];

//   return (
//     <ReusableModal
//       showCloseButton={false}
//       isOpen={props.isOpen}
//       onClose={props.onClose}
//       height="100%"
//       width="405px"
//       borderRadius="10px"
//       ModalContent={
//         <Grid
//           container
//           spacing={1}
//           xs={12}
//           ml={1}
//           style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', width: '400px', padding: '2%' }}
//         >
//           <h4 style={{ fontFamily: 'Medium', margin: '10% 0% 5% 0%' }}>{props.getEditText === 'edit' ? 'Edit Worker' : 'Add Worker'}</h4>
//           <hr />
//           <p style={{ fontFamily: 'Medium' }}>{'Name of Worker(s)'}</p>
//           <Grid container xs={12} spacing={1}>
//             <Grid item xs={6}>
//               <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>First Name</InputLabel>
//               <TextField
//                 size="small"
//                 variant="standard"
//                 value={firstName}
//                 onChange={(e) => setFirstName(e.target.value)}
//                 onClick={(e) => e.stopPropagation()}
//                 InputProps={{ disableUnderline: true }}
//                 style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Last Name</InputLabel>
//               <TextField
//                 size="small"
//                 variant="standard"
//                 value={lastName}
//                 onChange={(e) => setLastName(e.target.value)}
//                 onClick={(e) => e.stopPropagation()}
//                 InputProps={{ disableUnderline: true }}
//                 style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//               />
//             </Grid>
//           </Grid>
//           <div style={{ width: '400px', padding: '5% 8% 5% 0%' }}>
//             <DropDown
//               width="100%"
//               heightsss="45px"
//               options={options}
//               height="auto"
//               placeholder="Select Nationality"
//               value={nationality}
//               onValueChange={(i) => {
//                 setNationality(i.label);
//               }}
//             />
//           </div>
//           <Grid container xs={12} spacing={1}>
//             <Grid item xs={6}>
//               <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Date of Birth</InputLabel>
//               <TextField
//                 size="small"
//                 variant="standard"
//                 value={dob}
//                 onChange={(e) => setDob(e.target.value)}
//                 onClick={(e) => e.stopPropagation()}
//                 InputProps={{ disableUnderline: true }}
//                 style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>FIN</InputLabel>
//               <TextField
//                 size="small"
//                 variant="standard"
//                 value={fin}
//                 onChange={(e) => setFin(e.target.value)}
//                 InputProps={{ disableUnderline: true }}
//                 onClick={(e) => e.stopPropagation()}
//                 style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//               />
//             </Grid>
//           </Grid>

//           <Grid item xs={12}>
//             <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>
//               Work Permit / S Pass No.
//             </InputLabel>
//             <TextField
//               size="small"
//               variant="standard"
//               value={workPermit}
//               onChange={(e) => setWorkPermit(e.target.value)}
//               onClick={(e) => e.stopPropagation()}
//               InputProps={{ disableUnderline: true }}
//               style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//             />
//           </Grid>
//           {/* <div style={{ width: '100%', padding: '5% 5% 5% 5%' }}>
//             <TextField
//               size="small"
//               placeholder="Remarks"
//               variant="standard"
//               multiline
//               rows={4}
//               InputProps={{ disableUnderline: true }}
//               style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
//             />
//           </div> */}
//           <div style={{ width: '100%', padding: '5% 5% 5% 5%' }}>
//             <Button
//               variant="contained"
//               style={{
//                 backgroundColor: '#002D8C',
//                 borderRadius: '5px',
//                 fontFamily: 'Medium',
//                 color: '#FFF',
//                 width: '48%'
//               }}
//               onClick={() => handleAddWorker()}
//             >
//               {props.getEditText === 'edit' ? 'Edit Worker' : 'Add Worker'}
//             </Button>
//             <Button
//               onClick={() => props.onClose(false)}
//               variant="contained"
//               style={{
//                 backgroundColor: '#fff',
//                 borderRadius: '5px',
//                 color: '#002D8C',
//                 fontFamily: 'Medium',
//                 width: '48%',
//                 border: '1px solid #002D8C',
//                 margin: '2%'
//               }}
//             >
//               Cancel
//             </Button>
//           </div>
//         </Grid>
//       }
//     />
//   );
// }

import React, { useState, useEffect } from 'react';
import '../../../../assets/fonts/Fonts.css';
import ReusableModal from 'components/modals/routemodal/ResuableModal';
import DropDown from 'components/dropdown/DropDown';
import { TextField, Grid, Button, InputLabel } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addWorkerSection } from 'store/reducers/premiumPolicy';
import { showSuccess } from 'components/nudges/Alert';

export default function ForeignWorkerAddWorkerModalCustomer(props) {
  const { isOpen, onClose, getEditText, selectedRow } = props;
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nationality, setNationality] = useState('');
  const [dob, setDob] = useState('');
  const [fin, setFin] = useState('');
  const [workPermit, setWorkPermit] = useState('');

  useEffect(() => {
    if (isOpen) {
      if (getEditText === 'edit' && selectedRow) {
        setFirstName(selectedRow.firstName);
        setLastName(selectedRow.lastName);
        setNationality(selectedRow.nationality);
        setDob(selectedRow.dob);
        setFin(selectedRow.fin);
        setWorkPermit(selectedRow.workPermit);
      } else {
        setFirstName('');
        setLastName('');
        setNationality('');
        setDob('');
        setFin('');
        setWorkPermit('');
      }
    }
  }, [isOpen, getEditText, selectedRow]);

  const handleAddWorker = () => {
    const key = selectedRow?.id || new Date().toString();
    const workerData = {
      id: key,
      firstName,
      lastName,
      nationality,
      dob,
      fin,
      workPermit
    };
    dispatch(addWorkerSection([key, workerData]));
    onClose();
    showSuccess(getEditText === 'edit' ? 'Worker Edited Successfully!' : 'Worker Added Successfully!');
  };

  const options = [
    { label: 'India', id: 'India' },
    { label: 'China', id: 'China' },
    { label: 'Russia', id: 'Russia' },
    { label: 'Singapore', id: 'Singapore' },
    { label: 'Thailand', id: 'Thailand' }
  ];

  return (
    <ReusableModal
      showCloseButton={false}
      isOpen={isOpen}
      onClose={onClose}
      height="100%"
      width="405px"
      borderRadius="10px"
      ModalContent={
        <Grid container spacing={1} style={{ display: 'flex', flexDirection: 'column', padding: '2%' }}>
          <h4 style={{ margin: '10% 0% 5% 0%' }}>{getEditText === 'edit' ? 'Edit Worker' : 'Add Worker'}</h4>
          <hr />
          <p>{'Name of Worker(s)'}</p>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <InputLabel>First Name</InputLabel>
              <TextField
                size="small"
                variant="standard"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                InputProps={{ disableUnderline: true }}
                style={{ backgroundColor: '#F8F8F8', margin: '2px', padding: '5px', borderRadius: '8px' }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Last Name</InputLabel>
              <TextField
                size="small"
                variant="standard"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                InputProps={{ disableUnderline: true }}
                style={{ backgroundColor: '#F8F8F8', margin: '2px', padding: '5px', borderRadius: '8px' }}
              />
            </Grid>
          </Grid>
          <div style={{ padding: '5% 8% 5% 0%' }}>
            <DropDown
              width="100%"
              heightsss="45px"
              options={options}
              placeholder="Select Nationality"
              value={nationality}
              onValueChange={(i) => setNationality(i.label)}
            />
          </div>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <InputLabel>Date of Birth</InputLabel>
              <TextField
                size="small"
                variant="standard"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                InputProps={{ disableUnderline: true }}
                style={{ backgroundColor: '#F8F8F8', margin: '2px', padding: '5px', borderRadius: '8px' }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>FIN</InputLabel>
              <TextField
                size="small"
                variant="standard"
                value={fin}
                onChange={(e) => setFin(e.target.value)}
                InputProps={{ disableUnderline: true }}
                style={{ backgroundColor: '#F8F8F8', margin: '2px', padding: '5px', borderRadius: '8px' }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Work Permit / S Pass No.</InputLabel>
            <TextField
              size="small"
              variant="standard"
              value={workPermit}
              onChange={(e) => setWorkPermit(e.target.value)}
              InputProps={{ disableUnderline: true }}
              style={{ backgroundColor: '#F8F8F8', margin: '2px', padding: '5px', borderRadius: '8px' }}
            />
          </Grid>
          <div style={{ padding: '5% 5% 5% 5%' }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#002D8C',
                borderRadius: '5px',
                color: '#FFF',
                width: '48%'
              }}
              onClick={handleAddWorker}
            >
              {getEditText === 'edit' ? 'Edit Worker' : 'Add Worker'}
            </Button>
            <Button
              onClick={() => onClose(false)}
              variant="contained"
              style={{
                backgroundColor: '#fff',
                borderRadius: '5px',
                color: '#002D8C',
                border: '1px solid #002D8C',
                margin: '2%',
                width: '48%'
              }}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      }
    />
  );
}
    