import { showSuccess, showWarning } from 'components/nudges/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { addRiderData } from 'store/reducers/premiumPolicy';

export default function useHandleRider(
  buttonLabel,
  selectedRowData,
  setShow,
  firstName,
  lastName,
  nric,
  dob,
  gender,
  license,
  nationality,
  contactNumber,
  email,
  isCheckboxChecked,
  isRiderCheckboxChecked,
  isCheckboxDisabled,
  mode,
  setIsCheckboxDisabled
) {
  const dispatch = useDispatch();
  const riderDataSection = useSelector((state) => state.persistedReducer.premiumPolicy.otherState.riderDataSection);
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);

  const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const yearsDifference = end.getFullYear() - start.getFullYear();
    const monthsDifference = end.getMonth() - start.getMonth();
    let totalMonths = yearsDifference * 12 + monthsDifference;
    return totalMonths;
  };

  const calculateLicenseExp = (license) => {
    const currentDate = new Date();
    return calculateDuration(license, currentDate);
  };

  const calculateAge = (dob) => {
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - new Date(dob);
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return Math.floor(ageInYears);
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const validateNric = (nric) => {
    const nricPattern = /^[STFG]\d{7}[A-Z]$/;
    return nricPattern.test(nric);
  };

  const handleAddRider = () => {
    if (!firstName || !lastName || !nric || !dob || !gender) {
      showWarning('Please fill in all required fields');
      return;
    }

    if (!license) {
      if (!isCheckboxChecked) {
        showWarning('Please fill Driving License Date!');
        return;
      }
    }

    const dobDate = new Date(dob);
    const licenseDate = new Date(license);

    if (dobDate >= licenseDate) {
      showWarning('Date of birth must be earlier than the driving license issue date');
      return;
    }

    if (!email) {
      if (isCheckboxChecked) {
        showWarning('Please fill email address!');
        return;
      }
    }

    if (isCheckboxChecked && isRiderCheckboxChecked) {
      if (!license) {
        showWarning('Please fill Driving License Date!');
        return;
      }
    }

    if (email && !validateEmail(email)) {
      showWarning('Please enter a valid email address');
      return;
    }

    const age = calculateAge(dob);
    if (age < 18) {
      showWarning('Age must be 18 or more');
      return;
    }

    if (age > 75) {
      showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
      return;
    }

    if (nric && !validateNric(nric)) {
      showWarning('Please enter a valid NRIC Number');
      return;
    }

    if (otherState?.isFoodParcel === true && age > 65) {
      showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
      return;
    }

    const riders = Object.values(riderDataSection);
    if (riders.length > 0) {
      const existingOwner = riders.some((rider) => rider.isCheckboxChecked);
      if (!existingOwner && !isCheckboxChecked) {
        showWarning('First rider must be selected as the owner');
        return;
      }
    }


    const totalExperienceMonths = calculateLicenseExp(license);
    const experienceString = `${totalExperienceMonths} month${totalExperienceMonths !== 1 ? 's' : ''}`;
   
    let key = selectedRowData ? (selectedRowData?.id ? selectedRowData?.id : new Date().toString()) : new Date().toString();
    const riderData = {
      id: key,
      firstName,
      lastName,
      nric,
      gender,
      nationality,
      contactNumber,
      email,
      dob,
      license,
      age: age,
      experience: experienceString,
      isCheckboxChecked,
      isRiderCheckboxChecked
    };

    dispatch(addRiderData([key, riderData]));

    if (mode === 'edit') {
      showSuccess('Rider edited successfully');
    } else {
      showSuccess('Rider added successfully');
    }

    setShow(false);
  };

  return [handleAddRider];
}