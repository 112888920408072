
import React, { useState,useEffect } from 'react';
import { Grid } from '@mui/material';
import useGetPremiumTableData from '../hooks/useGetPremiumTableData';
import OverlayLoader from 'components/loader/OverlayLoader';
import Successmodal from 'components/modals/contentmodal/Successmodal';
import BasicTable from 'pages/dashboard/BasicTable';

const PremiumTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, loading] = useGetPremiumTableData(() => setIsOpen(true));
  const [showLoader, setShowLoader] = useState(true);
  const timeoutDuration = 3000;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, timeoutDuration);

    return () => clearTimeout(timeout);
  }, []);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  if (loading && showLoader) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '65vh' }}>
        <OverlayLoader show={true} />
      </div>
    );
  }
  return (
    <Grid width={400} item xs={12} md={12} lg={12}>
      <BasicTable rows={data} columns={columns} searchOn={['Pn', 'pt']} showDatePicker={false} />
      <Successmodal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={'Download'}
        subtitle={'Download Started'}
        handleCloseModal={handleCloseModal}
      />
    </Grid>
  );
};

export default PremiumTable;

const columns = [
  { id: 'sr', label: 'Sr.', align: 'left' ,minWidth:60},
  // { id: 'id', label: 'Product Id', minWidth: 120, align: 'center' },
  { id: 'Pn', label: 'Product Name', minWidth:900, align: 'left' },
  // {
  //   id: 'class',
  //   label: 'Class',
  //   align: 'center',
  //   minWidth: 160
  // },
  // {
  //   id: 'pt',
  //   label: 'Product type',
  //   align: 'center',
  //   minWidth: 160
  // },
  // {
  //   id: 'insurer',
  //   label: 'Insurer',
  //   align: 'center',
  //   minWidth: 160
  // },

  {
    id: 'Action',
    label: 'Action',
    align: 'left',
    flex: 1
  }
];
