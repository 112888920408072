import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import useApiCall from 'api/useApiCall';
import { API } from 'api/API';
import { getUserId } from 'helper/utils';
import { store } from 'store/index';
import { showWarning } from 'components/nudges/Alert';
import { addPolicyData, removeOtherState } from '../../../../store/reducers/premiumPolicy';

export default function useCustomerPolicesGet() {
  const { makeRequest } = useApiCall();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let UserId = await getUserId();
        let params = { UserId };
        let res = await makeRequest({ method: 'POST', url: API.AgentSpecificPoliciesGet, params });
       
        if (res?.code == 200) {
          let arr = [];
          res?.data?.forEach((item, index) => {
            arr.push({
              sr: index + 1,
              Pt: `${item?.data?.customer?.nricfin || '-'}`,
              pd: `${item?.data?.customer?.customerName || '-'}`,
              rd: `${item?.data?.policy?.periodOfInsuranceStartDate || '-'}`,
              premium: `${item?.data?.policy?.premiumAmount || '-'}`,
              Action:
                item?.data?.policy?.policyNumber == null ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        backgroundColor: 'grey',
                        color: '#fff',
                        borderRadius: 20,
                        fontSize: '12px',
                        cursor: 'pointer',
                        width: '55px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        padding: '3px',
                        fontFamily: 'Medium'
                      }}
                    
                    >
                      <VisibilityIcon style={{ fontSize: '15px' }} />
                      View
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        backgroundColor: '#4cb748',
                        color: '#fff',
                        borderRadius: 20,
                        fontSize: '12px',
                        cursor: 'pointer',
                        width: '55px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        padding: '3px',
                        fontFamily: 'Medium'
                      }}
                      onClick={() => {
                  
                        store.dispatch(removeOtherState());
                        store.dispatch(addPolicyData(item));
                        navigate('/dashboard/viewpolicy');
                      }}
                    >
                      <VisibilityIcon style={{ fontSize: '15px' }} />
                      View
                    </div>
                  </div>
                )
              
            });
            setData(arr);
            setIsLoading(false);
          });
        } else if (res?.code == 404) {
         
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);

      }
    })();
  }, []);

  return [data, loading];
}
