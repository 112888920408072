import React from 'react';
import { Grid } from '@mui/material';
import SubHeader from 'components/subHeader/SubHeader';
import { useTheme } from 'components/Theme/ThemeProvider';
import PoliciesTable from '../components/PoliciesTable';
import '../../packages & premium/styles/InformationForm.css';
const ActiveComponents = () => {
  const { currentTheme } = useTheme();

  return (
    <Grid className="css-1gnspwu-MuiGrid-root">
      <Grid
        container
        // rowSpacing={2}
        columnSpacing={2.75}
        alignItems="center"
        justifyContent="space-between"
        style={{ backgroundColor: currentTheme.cardColor }}
      >
        <SubHeader
          title="Policies"
        />
        {/* <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} /> */}
        <PoliciesTable />
      </Grid>
    </Grid>
  );
};

const Policies = () => {
  return <ActiveComponents />;
};

export default Policies;
