import React from 'react';
import ResponsiveAppBar from '../../customer/corporate/customerlandingpage/components/NavBar';
import { Grid } from '../../../../node_modules/@mui/material/index';
import Footer from 'modules/customer/corporate/customerlandingpage/components/Footer';
import LoginCustomer from 'modules/customer/customer login/pages/LoginCustomer';
import Login_img from '../../../assets/images/login_img.png';


const CustomerLogin = () => {
  return (
    <div>
      <ResponsiveAppBar />
    
      <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100%', height: '100%', zIndex: -1 }}>
        <img src={Login_img} alt="Background" style={{ width: '90%', height: '35%', objectFit: 'cover' }} />
        <div style={{ display: 'flex', flexDirection: 'row',position: 'absolute',justifyContent:'flex-start',left:350,top:60}}>
        <p style={{ fontSize: '20px', fontFamily: 'Medium', color: 'white', marginRight: '5%' }}>Our</p>
        <p style={{ fontSize: '20px', fontFamily: 'Heavy', color: 'white', }}>Products</p>
      </div>
      </div>
      
      <Grid item xs={12} md={9} lg={12} mt={19}>
        <Grid container alignItems="center" justifyContent="space-evenly">
          <Grid item></Grid>
        </Grid>
        <LoginCustomer /> 
      </Grid>
      <Grid item xs={12} md={7} lg={12} mt={20}>
        <Grid container alignItems="center" justifyContent="space-evenly">
          <Grid item></Grid>
        </Grid>
        <Footer />
      </Grid>
    </div>
  );
};

export default CustomerLogin;
