import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePackagePremiumState } from 'modules/packages & premium/provider/PackagePremiumStateProvider';
import useApiCall from 'api/useApiCall';
import { useSelector } from 'react-redux';
import { API } from 'api/API';
import { showDanger, showWarning } from 'components/nudges/Alert';
import { getLoginDetailsId, getUserId } from 'helper/utils';
import { store } from 'store/index';
import { removeOtherState } from 'store/reducers/premiumPolicy';
import OverlayLoader from 'components/loader/OverlayLoader';
import useNetworkStatus from 'hooks/useNetworkStatus';

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const DomesticHelperBottomPortion = () => {
  const navigate = useNavigate();
  const { params } = usePackagePremiumState();
  const { makeRequest } = useApiCall();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { addOn } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const { otherState } = useSelector((state) => state?.persistedReducer?.premiumPolicy);
  const network = useNetworkStatus();
  let workerLength = Object?.keys(addOn)?.length;
  let mergedStates = { ...otherState };



  const validateNric = (nric) => {
    const nricPattern = /^[STFG]\d{7}[A-Z]$/;
    return nricPattern.test(nric);
  };

  const handleClick = async () => {
    try {
      if (!network) {
        showDanger('No internet connection.');
        return;
      }
      if (isButtonDisabled) return;

      setIsButtonDisabled(true);
      let userId = await getUserId();
      let loginDetailsId = await getLoginDetailsId();
      if (otherState.UEMSTATUS === false && otherState.UENDHInsurance != '' && otherState.compnaynameDHInsurance == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter company name');
      } else if (otherState.UEMSTATUS === false && otherState.UENDHInsurance != '' && otherState.emailDHInsurance == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter email');
      } else if (otherState.UEMSTATUS === false && otherState.UENDHInsurance != '' && !validateEmail(otherState.emailDHInsurance)) {
        setIsButtonDisabled(false);
        showWarning('Please enter valid email address');
      } else if (mergedStates?.ndw == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter Name of Domestic Helper');
      } else if (mergedStates?.fin == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter F I N');
      } else if (mergedStates?.passportno == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter Passport Number');
      } else if (mergedStates?.dob == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter Date of Birth');
      } else if (mergedStates?.workpermit == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter Work Permit Number');
      } else if (mergedStates?.nationalityDHInformation == '') {
        setIsButtonDisabled(false);
        showWarning('Please enter Nationality');
      } else if (!validateNric(otherState?.fin)) {
        setIsButtonDisabled(false);
        showWarning('Please Enter Correct FIN');
      } else {
        setIsLoading(true);
        let body = {
          userId: userId,
          productId: params?.productID,
          loginDetailsId: loginDetailsId,
          basisOfCover: '',
          nameOfMainContractor: '',
          uenNumber: otherState.UENDHInsurance,
          companyName: otherState?.cm,
          email: otherState?.email,
          phone: otherState?.phone,
          cpfSubmissionNo: '',
          dischargeDate: '',
          transmissionStatus: '',
          bondCallStatus: '',
          bondDuration: '',
          loiStatus: '',
          loiType: '',
          fromDate: otherState.fromdateDHInsurance,
          toDate: '',
          durationInMonth: otherState?.durationInMonths,
          durationInDays: otherState.durationInDays,
          subCategory: otherState.subcategory,
          addonFWDHProductPricingListId: Object?.keys(addOn)?.length > 0 ? Object?.keys(addOn)?.map((key) => key) : ['0']
        };

        let res = await makeRequest({ method: 'POST', url: API.FWDHCalculationPost, body });
        if (res?.code == 200) {
          setIsLoading(false);
          setIsButtonDisabled(true);
          setIsLoading(false);
          navigate('/dashboard/PremiumCalculation', { state: { data: params, apiData: res?.data } });
        } else {
          setIsButtonDisabled(false);
          showDanger('Error');
          setIsLoading(false);
        }
      }
    } catch (e) {
      showDanger(e);
    }
  };

  const handleResetClick = () => {
    store.dispatch(removeOtherState());
    window.location.reload();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
      <OverlayLoader show={isLoading} />
      <Grid
        container
        xs={12}
        sm={6}
        md={4}
        lg={4}
        mt={2}
        ml={3}
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
      >
        <Button
          variant="contained"
          disabled={isButtonDisabled}
          // disabled={workerLength == 0 ? true : false}
          style={{
            // backgroundColor: workerLength == 0 ? 'grey' : '#002D8C',
            backgroundColor: isButtonDisabled ? 'grey' : '#002D8C',
            borderRadius: '5px',
            fontFamily: 'Medium',
            color: workerLength > 0 && '#FFF',
            width: '48%'
          }}
          onClick={() => handleClick()}
        >
          Calculate Premium
        </Button>
        <Button
          onClick={() => handleResetClick()}
          variant="contained"
          style={{
            backgroundColor: '#fff',
            borderRadius: '5px',
            color: '#002D8C',
            fontFamily: 'Medium',
            width: '48%',
            border: '1px solid #002D8C'
          }}
        >
          Reset
        </Button>
      </Grid>
    </div>
  );
};

export default DomesticHelperBottomPortion;
