import React from 'react';

import Logo_RGB from '../../assets/images/ANDA Logo_Logotype_CMYK.jpg';
const Logo = () => {
  return (
    <div style={{ width: '100%' }}>
      <img
        src={Logo_RGB}
        alt="ANDA Logo_RGB"
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '150px',
          marginTop: '10px'
        }}
      />
    </div>
  );
};

export default Logo;
