import React, { useState } from 'react';
import backgroundView from '../../../../../assets/images/Anda New Images/ANDA_Category_Motor.png';
import ReusableCard from 'components/cards/ResuableCard';
import { Grid, IconButton, useMediaQuery } from '../../../../../../node_modules/@mui/material/index';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import '../styles/Product.css';
import DomesticHelper from '../../../../../assets/images/OneDrive_2024-05-10/png file/ANDA_CategorySlider_Domestic.png';
import MotorVehicle from '../../../../../assets/images/OneDrive_2024-05-10/png file/ANDA_CategorySlider_Motor.png';
import ForeignWorker from '../../../../../assets/images/OneDrive_2024-05-10/png file/ANDA_CategorySlider_FW.png';

const Products = () => {
  const [descriptionText, setDescriptionText] = useState(0);

  const handleClick = (index) => {
    setDescriptionText(index);
  };

  const productsData = [
    {
      id: 1,
      productname: 'Domestic Helper',
      description: 'lorem akdn amksmf akfmma fmied akmiimsc smmvimmc kimvmf ksmvmv kjnkjnd kjsdncjkd uidquid ihduihd  cimwf',
      image: DomesticHelper
    },
    {
      id: 2,
      productname: 'Motor Vehicle',
      description: 'lorem akdn amksmf akfmma fmied akmiimsc smmvimmc kimvmf ksmvmv jkdhjkdh skjdcndjkchd skjncdjkc  cimwf',
      image: MotorVehicle
    },
    {
      id: 3,
      productname: 'Foreign Workers',
      description: 'lorem akdn amksmf akfmma fmied akmiimsc smmvimmc jdkjnd kjdkjd ksjncdkc kjscdj  kimvmf ksmvmv cimwf',
      image: ForeignWorker
    }
    /* {
      id: 4,
      productname: 'Lifestyle',
      description: 'lorem akdn amksmf akfmma fmied sndkcjn cjnckj cdajalicj akjncklajc  akmiimsc smmvimmc kimvmf ksmvmv cimwf'
    } */
  ];

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:1279px');

  const selectedProductImage = productsData[descriptionText].image;

  return (
    <Grid container spacing={2} className="main_mainContainer_products">
      <Grid item xs={isMobile ? 11 : 6} className="allTextStylingProducts">
        <div className="our_product_heading_container_products">
          <p className="our_heading_products">Our</p>
          <p className="products_heading_products">Products</p>
        </div>

        {productsData.map((item, index) => (
          <div className="Products_Heading_Styling" onClick={() => handleClick(index)}>
            {descriptionText === index ? (
              <>
                <div className="productname_icon_styling">
                  <p className="productname_styling_products_after">{item.productname}</p>
                  <IconButton className="icon_styling_products_after">
                    <ArrowForwardIcon />
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <div className="productname_icon_styling">
                  <p className="productname_styling_products">{item.productname}</p>
                  <IconButton className="icon_styling_products">
                    <ArrowForwardIcon />
                  </IconButton>
                </div>
              </>
            )}

            {descriptionText === index && (
              <div className="product_description_styling">
                <p className="description_textStyling">{item.description}</p>
              </div>
            )}

            {descriptionText === index && isMobile && <img src={selectedProductImage} alt="Background" className="image____products" />}

            <div style={{ height: '0.2px', backgroundColor: '#ffffff', width: '100%', zIndex: 2, display: 'flex' }}></div>
          </div>
        ))}
      </Grid>
      {!isMobile && (
        <Grid item xs={isMobile ? 0 : 6} className="imageContainer_products">
          <img src={selectedProductImage} alt="Background" className="image____products" />
        </Grid>
      )}
      ;
    </Grid>
  );
};

export default Products;
