import React, { useState } from 'react';
import { Grid } from '@mui/material';
import '../authentication/Login.css';
import { useTheme } from 'components/Theme/ThemeProvider';
import { SERVER_URL } from 'helper/utils';
// import logo from '../../assets/images/ANDA Logo_RGB.png';
import loginpageimg from '../../assets/images/OneDrive_2024-05-10/png file/ANDA_AgentLogin.jpg';
import ResetPassword from './auth-forms/ResetPassword';

// ================================|| LOGIN ||================================ //

const ForgotPassword = () => {
  return (
    <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={7}  container justifyContent="center" alignItems="center">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '325px' }}>
          <ResetPassword />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={5} container justifyContent="center" alignItems="center">
        <img src={loginpageimg} alt="Login" style={{ width: '100%', height: '100%', maxWidth: '500px' }} />
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
