import ReusableCard from 'components/cards/ResuableCard';
import React, { useState } from 'react';
import { Grid, Button, TextField, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DropDown from 'components/dropdown/DropDown';
import CustomerLoginModal from 'modules/customer/corporate/customerlandingpage/components/CustomerLoginModal';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import CustomerResetPassword from 'modules/customer/customerpasswordreset/CustomerResetPassword';
import CustomerOtpModal from 'modules/customer/customerOTP/CustomerOtpModal';
import OTPResetModal from 'components/modals/contentmodal/OTPResetModal';

const ForeignWorkerCustomerReview = ({ handleNextButton, handlePreviousButton }) => {
  const navigate = useNavigate();
  const keysforplan = Object.keys(planDetail);
  const valuesforplan = Object.values(planDetail);
  const [show, setShow] = useState(false);
  const [resetShow, setResetShow] = useState(false);
  const [otpshow, setOtpShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [emailId, setEmailId] = useState('');
  const { makeRequest } = useApiCall();
  const network = useNetworkStatus();

  const handleCancelButton = () => {
    navigate('/ForeignWorkers');
  };

  const handleProceedPayment = () => {
    // navigate('/DomesticHelper');
    let userInformation = localStorage.getItem('@user');
    if (userInformation === null) {
      setShow(true);
    } else {
      setShow(false);
      alert('Redirected To Payment Page!');
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleResetPasswordModalOpen = () => {
    setResetShow(true);
    setShow(false);
  };

  const handleCloseResetModal = () => {
    setIsOpen(false);
    setShow(true);
  };

  const handleOTP = ({ OTP, UserId, emailId }) => {
    setEmailId(emailId);
    setUserId(UserId);
    setResetShow(false);
    setOtpShow(true);
  };

  const handleVerifyOtp = () => {
    setOtpShow(false);
    setIsOpen(true);
  };

  const handleClickOnResendOTP = async () => {
    if (network) {
      try {
        let params = {
          UserId: userId,
          emailId: emailId
        };
        let res = await makeRequest({ method: 'POST', url: API.PasswordChangeOTPSent, params });
        if (res?.code == 200) {
          showSuccess('OTP sent successfully');
        } else {
          showWarning(res?.message);
        }
      } catch (e) {
        showDanger(`${e}`);
      }
    } else {
      showDanger('No internet');
    }
  };

  return (
    <div>
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'1%'}
        boxShadow={' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <Grid container p={2}>
            {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '500' }}>Basic Plan (With Co-Payment)</span>
              <Button
                size="small"
                variant="contained"
                style={{ backgroundColor: '#002D8C', width: 'auto' }}
                onClick={() => {
                  navigate('/DomesticHelper');
                }}
              >
                Change Plan
              </Button>
            </Grid>

            <div
              style={{
                width: '100vw',
                border: '1px solid #d8d8d8',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '2%',
                marginTop: '2rem',
                borderRadius: '12px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {keysforplan.map((item, index) => (
                  <Grid key={index} item xs={4} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ fontFamily: 'Heavy', fontSize: '14px', display: 'flex', alignItems: 'center' }}>{item}</span>
                  </Grid>
                ))}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {valuesforplan.map((item, index) => (
                  <Grid key={index} item xs={4} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ fontFamily: 'Medium', fontSize: '14px' }}>{item}</span>
                  </Grid>
                ))}
              </div>
            </div> */}

            <Grid item xs={12}>
              <Grid container spacing={2} xs={12}>
                {/* <Grid item xs={12}>
                  <p
                    style={{
                      display: 'flex',
                      fontFamily: 'Medium',
                      fontSize: '18px',
                      fontWeight: '500',
                      marginTop: '2rem',
                      color: '#002d8c'
                    }}
                  >
                    Employer Information
                  </p>
                </Grid> */}

                <Grid item xs={4}>
                  <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Name</InputLabel>
                  <TextField
                    size="small"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>Date</InputLabel>
                  <TextField
                    size="small"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <InputLabel style={{ fontFamily: 'Medium', fontSize: '14px', marginLeft: '1%', color: '#9fa1a9' }}>
                    Designation
                  </InputLabel>
                  <TextField
                    size="small"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
                  />
                </Grid>
              </Grid>
              <hr />

              <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff' }}
                    onClick={handleProceedPayment}
                    // onClick={handleNextButton}
                  >
                    Proceed to Purchase
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#e4e4e4', width: '100%', padding: '3%', color: '#000000' }}
                    onClick={handleCancelButton}
                    // onClick={handlePreviousButton}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>

              <CustomerLoginModal
                isOpen={show}
                setShow={setShow}
                onClose={handleClose}
                handleResetPasswordModalOpen={handleResetPasswordModalOpen}
              />
              <CustomerResetPassword isOpen={resetShow} onClose={() => setResetShow(false)} handleOTP={handleOTP} />
              <CustomerOtpModal
                isOpen={otpshow}
                onClose={() => setOtpShow(false)}
                handleVerifyOtp={handleVerifyOtp}
                handleClickOnResendOTP={handleClickOnResendOTP}
              />
              <OTPResetModal isOpen={isOpen} handleCloseModal={handleCloseResetModal} />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default ForeignWorkerCustomerReview;

const planDetail = { 'Plan Premium': 'Basic Plan (With Co-Payment)', '26 months': '$490.50', '14 Months': '$367.88' };
