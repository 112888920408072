import { useState, useEffect } from 'react';
import { API } from 'api/API'; // Assuming API is defined here
import useApiCall from 'api/useApiCall';
// import { makeRequest } from 'api/useApiCall';

const useProductPlansData = (tsaTypeParam = 'Domestic Helper') => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { makeRequest } = useApiCall();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await makeRequest({
          method: 'GET',
          url: `${API.CustomerPackagesAndPremiumGet}?UserId=&TSAType=${tsaTypeParam}`
        });
        setData(response?.data || []);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [tsaTypeParam]);

  return { data, isLoading, error };
};

export default useProductPlansData;
