import React from 'react';
import { showDanger } from 'components/nudges/Alert';
import { SERVER_URL } from 'helper/utils';
import { updateAgendAuth } from 'store/reducers/auth';
import { useNavigate } from 'react-router-dom';
import { store } from 'store/index';

const useApiCall = () => {
  const navigate = useNavigate();

  const makeRequest = async ({ method = 'GET', url, params, body, token = false }) => {
    let appToken = null;
    try {
      const config = {
        method: method.toUpperCase(),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*'
        }
      };

      // Add Authorization header with bearer token
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        appToken = localStorage.getItem('AppToken');
        const storedToken = JSON.parse(appToken);
        config.headers['Authorization'] = `Bearer ${storedToken}`;
      }

      const serverUrl = SERVER_URL();
      let url_with_params = `${serverUrl}${url}`;
      if (params) {
        const encoded_params = await serializeQuery(params);
        url_with_params += encoded_params;
      }
      if (body) {
        config.body = JSON.stringify(body);
      }

      const response = await fetch(url_with_params, config);
      const result = await response.json();
      if (result) {
        if (result?.code == 500) {
          showDanger(result?.code?.error?.message);
        } else if (result?.code == 401) {
          // If Authorization is Required or token expired
          showDanger('You have been logged out because your session expired due to inactivity');
          localStorage.removeItem('AppToken');
          localStorage.removeItem('@user');
          localStorage.removeItem('@login');
          store.dispatch(updateAgendAuth({ status: false, data: {} }));
          navigate('/', { state: {}, replace: false });
        } else {
          return result;
        }
      }
    } catch (err) {
      if (!appToken) {
        showDanger(`${url} : ${err}`);
        }
      return null;
    }
  };

  const makeRequestWithoutLogin = async ({ method = 'GET', url, params, body }) => {
    try {
      const config = {
        method: method.toUpperCase(),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*'
        }
      };
      let url_with_params = `${SERVER_URL()}${url}`;
      if (params) {
        const encoded_params = await serializeQuery(params);
        url_with_params += encoded_params;
      }
      if (body) {
        config.body = JSON.stringify(body);
      }

      const response = await fetch(url_with_params, config);
      const result = await response.json();

      if (result) {
        if (result?.code == 500) {
          showDanger(result?.code?.error?.message);
        } else if (result?.code == 401) {
          // If Authorization is Required or token expired
          showDanger('You have been logged out because your session expired due to inactivity');
          localStorage.removeItem('AppToken');
          localStorage.removeItem('@user');
          localStorage.removeItem('@login');
          store.dispatch(updateAgendAuth({ status: false, data: {} }));
          navigate('/', { state: {}, replace: false });
        } else {
          return result;
        }
      }
    } catch (err) {
      showDanger(`${url} : ${err}`);
      return null;
    }
  };

  const serializeQuery = async (query) => {
    return Object.keys(query)
      .map((key, index) => {
        const separator = index === 0 ? '?' : '&';
        return `${separator}${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
      })
      .join('');
  };

  return { makeRequest, makeRequestWithoutLogin };
};

export default useApiCall;
