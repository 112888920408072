import React from 'react';
import { Grid, Typography } from '@mui/material';
import Footer from '../customerlandingpage/components/Footer';
import corporate from '../../../../assets/images/corporate.png'
import ResponsiveAppBar from '../customerlandingpage/components/NavBar';
import CalculateContainer from '../components/CalculateContainer';
import ImageCard from '../customerlandingpage/components/ImageCard';
import ProductCorporateTab from '../components/CorporateProductTab';
export default function Corporate() {
  return (
    <Grid Container xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div>
        <ResponsiveAppBar />

        <div style={{ position: 'relative', width: '100%', height: '35vh' }}>
          <img src={corporate} alt="Background" style={{ width: '100vw', height: '100%', objectFit: 'cover' }} />
          <div
            style={{ position: 'absolute', top: '20%', left: '35%', transform: 'translateX(-50%)', textAlign: 'center', color: 'white',zIndex:1 }}
          >
            <p style={{ fontSize: 'clamp(16px, 5vw, 30px)', fontFamily: 'Medium', marginBottom: '5px' }}>Corporate</p>
            <p style={{ fontSize: 'clamp(10px, 2vw, 16px)', fontFamily: 'Thin' }}>ANDA Insurance: Tailored Reliable Always Ready</p>
          </div>
        </div>
      </div>

      <div style={{ position: 'relative', zIndex: 2,marginTop:'-50px' }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={7} lg={12}>
            <CalculateContainer />
          </Grid>
        </Grid>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%', marginTop: '5%' }}>
        <ProductCorporateTab />
      </div>
      <Grid item xs={12} md={7} lg={12} mt={5}>
        <Grid container alignItems="center" justifyContent="space-evenly">
          <Grid item></Grid>
        </Grid>
        <ImageCard />
      </Grid>
      <Grid item xs={12} md={7} lg={12} mt={4} sx={{ width: '100%' }}>
        <Footer />
      </Grid>
    </Grid>
  );
}
