import React, { useContext, createContext, useState, useMemo } from 'react';
import { useTheme } from 'components/Theme/ThemeProvider';

const createPackagePremiumContext = createContext();

const PackagePremiumStateProvider = ({ children, params, apiData }) => {
  const { currentTheme } = useTheme();

  // const [foreignWorkerPackageInfromation, setForeignWorkerPackageInfromation] = useState({
  //   foreignWorkerPackageInfromation: '',
  //   maincontractor: '',
  //   UEN: '',
  //   CPF: '',
  //   dischargeDate: '',
  //   transmissionstatus: '',
  //   bondcall: '',
  //   bondduration: '',
  //   LOIstatus: '',
  //   LOItype: '',
  //   fromdate: '',
  //   durationmonths: '',
  //   durationdays: '',
  //   todate: ''
  // });

  // const [selectedAddon, setSelectedAddon] = useState([]);

  // const [domesticHelperEmployerInformation, setDomesticHelperEmployerInformation] = useState({
  //   name: '',
  //   gender: '',
  //   nationality: '',
  //   nric: '',
  //   dob: '',
  //   transmissionstatus: '',
  //   sbTransmission: '',
  //   phone: '',
  //   email: '',
  //   address: ''
  // });

  // const [domesticWorkerInformation, setDomesticWorkerInformation] = useState({
  //   namedomestichelper: '',
  //   fin: '',
  //   passportno: '',
  //   dob: '',
  //   workpermit: '',
  //   nationality: ''
  // });

  // const [domesticWorkerInsurance, setDomesticWorkerInsurance] = useState({
  //   productname: '',
  //   fromdate: '',
  //   noofmonths: '',
  //   noofdays: '',
  //   todate: ''
  // });

  const contextValue = useMemo(
    () => ({
      currentTheme,
      params,
      apiData
    }),
    [currentTheme, params, apiData]
  );

  return <createPackagePremiumContext.Provider value={contextValue}>{children}</createPackagePremiumContext.Provider>;
};

export const usePackagePremiumState = () => {
  const context = useContext(createPackagePremiumContext);
  if (!context) {
    throw new Error('usePackagePremiumState must be used within a PackagePremiumStateProvider');
  }
  return context;
};

export default PackagePremiumStateProvider;
