import React, { useState, useEffect } from 'react';
import CardLayout from '../CardLayout';
import { Grid, TextField, InputLabel, Button, Typography } from '@mui/material';
import { usePolicyDetails } from 'modules/packages & premium/hooks/useVehicle';
import '../../styles/InformationForm.css';
import DropDown from 'components/dropdown/DropDown';
import { useSelector } from 'react-redux';
import { store } from 'store/index';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import useUenVerification from '../../hooks/useUenVerification';

function RenderFunction({ item, index, verificationMessage, otherState, handleVerifyClick }) {
  const [value, setValue] = useState(otherState[item?.value]);

  const onChangeText = (txt, value) => {
    try {
      store.dispatch(addOtherIndividualState([value, txt]));
      setValue(txt);
    } catch (e) {
      console.error('Error during change text inside motorVehiclePolicyInformation ->', e);
    }
  };

  const onValueChange = (selectedItem) => {
    try {
      store.dispatch(addOtherIndividualState([selectedItem?.value, selectedItem?.id]));
      setValue(selectedItem?.id);
    } catch (e) {
      console.error('Error during onChange --', e);
    }
  };

  useEffect(() => {
    try {
      if (otherState['UEM'].length === 0) {
        store.dispatch(addOtherIndividualState(['UEMSTATUS', null]));
      }
      if (item.value === 'POIEndDate') {
        let newDate = new Date(otherState['POIStartDate']);
        newDate.setFullYear(newDate.getFullYear() + 1);
        store.dispatch(addOtherIndividualState(['POIEndDate', newDate.toISOString().split('T')[0]]));
        setValue(newDate.toISOString().split('T')[0]);
      }
    } catch (error) {
      console.error('An error occurred in useEffect:', error);
    }
  }, [otherState, item]);

  // let isFlagm;
  // if (otherState['UEMSTATUS'] === false) {
  //   isFlagm = true;
  // } else {
  //   isFlagm = item.isFlag;
  // }
  return (
    <>
      {/* {isFlagm && ( */}
        <Grid item xs={12} md={4} alignItems="center">
          {/* {isFlagm ? ( */}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <InputLabel className="inputs_styless_">{item.label}</InputLabel>
              {item?.astric && <div style={{ color: 'red', fontSize: '20px', marginLeft: '5px', marginTop: '-10px' }}>*</div>}
            </div>
          {/* ) : (
            <></>
          )} */}
          {item.type === 'textField'  ? (
            <TextField
              type={item?.texttype}
              disabled={ item?.value == 'UEM' && otherState['navigateFrom'] == 'renewal'  ? true :item?.isEnable }
              onChange={(txt) => onChangeText(txt.target.value, item?.value)}
              required={item?.isRequired}
              size="small"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              className="txtinputs_stylingg"
              value={value}
              style={{ marginTop: '5px' }}
              inputProps={{
                min: new Date().toISOString().split('T')[0],
                max: (() => {
                  const maxDate = new Date();
                  maxDate.setFullYear(maxDate.getFullYear() + 10);
                  return maxDate.toISOString().split('T')[0];
                })()
              }}
            />
          ) : (
            <></>
          )}
          {/* {index === 0 && value.length > 1 && (
            <Typography variant="body2" style={{ color: otherState['UEMSTATUS'] ? 'lightgreen' : 'red', marginLeft: '4px' }}>
              {otherState['UEMSTATUS'] == null ? '' : otherState['UEMSTATUS'] ? ' This UEN is verified.' : 'This UEN is not registered.'}
            </Typography>
          )} */}

          {item.type === 'dropdown' && (
            <DropDown
              width={'96%'}
              heightsss={'38px'}
              options={item?.ddoptions || []}
              onValueChange={onValueChange}
              defaultValue={otherState[item?.value]}
            />
          )}
        </Grid>
      {/* )} */}

      {/* {index === 0 && otherState['navigateFrom'] == undefined &&  (
        <Grid item xs={12} md={1} mt={verificationMessage ? 0 : 3}>
          <Button
            variant="contained"
            size="small"
            disabled={value == 0 ? true : false}
            style={{
              backgroundColor: value == 0 ? 'grey' : '#4CB748',
              color: value > 0 && '#FFF'
            }}
            onClick={() => handleVerifyClick()}
          >
            Verify
          </Button>
        </Grid>
      )} */}
    </>
  );
}

const MotorVehiclePolicyInformation = React.memo((props) => {
  const { data } = usePolicyDetails();
  const [showErrorFields, setShowErrorFields] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);
  const { handleVerifyClick } = useUenVerification();
  const [isOpen, setIsOpen] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setShowErrorFields(false);
  }, []);

  return (
    <CardLayout isOpen={isOpen} setIsOpen={setIsOpen} toggleForm={toggleForm} heading="Policy Details">
      {data.map((item, index) => {
        return (
          <RenderFunction
            key={index}
            item={item}
            index={index}
            showErrorFields={showErrorFields}
            verificationMessage={verificationMessage}
            setVerificationMessage={setVerificationMessage}
            otherState={otherState}
            handleVerifyClick={handleVerifyClick}
          />
        );
      })}
    </CardLayout>
  );
});

export default MotorVehiclePolicyInformation;
