import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';
import useNetworkStatus from 'hooks/useNetworkStatus';
import { showDanger } from 'components/nudges/Alert';

const DataChart = ({ filterBy }) => {
  const { makeRequest } = useApiCall();
  const [chartDataComission, setChartDataComission] = useState({ categories: [], data: [] });
  const [loading, setLoading] = useState(true);
  const network = useNetworkStatus();

  const fetchChartCommisionBreakUpData = useCallback(async () => {
    const loginData = localStorage.getItem('@login');

    if (loginData) {
      const loginDataObj = JSON.parse(loginData);
      const userMasterId = loginDataObj?.userMasterId;

      try {
        const response = await makeRequest({
          method: 'GET',
          url: `${API.CommissionBreakUpDetailsGet}?UserId=${userMasterId}&filterBy=${filterBy}`
        });
        if (response?.data) {
          setChartDataComission(response?.data);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [filterBy]);

  useEffect(() => {
    if (!network) {
      showDanger('No internet connection.');
      setLoading(false);
      return;
    }

    fetchChartCommisionBreakUpData();
  }, [fetchChartCommisionBreakUpData, filterBy]);

  const formatNumber = (number) => {
    if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + 'M';
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + 'k';
    } else {
      return number;
    }
  };

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: 365,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '45px',
        borderRadius: 4
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#2f3640']
      },
      formatter: function (val) {
        return formatNumber(val);
      }
    },
    xaxis: {
      categories: chartDataComission?.categories,
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: true,
      min: 0,
      labels: {
        style: {
          fontFamily: 'Medium',
          fontSize: 13,
          color: '#76777D'
        }
      }
    },
    grid: {
      show: true
    }
  };

  const series = [
    {
      data: chartDataComission?.data
    }
  ];

  return (
    <>
      {loading ? (
        <Grid item xs={12} sm={6} md={12} lg={12}>
          <Skeleton variant="rectangular" width="100%" height={350} animation="wave" style={{ borderRadius: '10px' }} />
        </Grid>
      ) : (
        <div id="chart">
          <ReactApexChart options={barChartOptions} series={series} type="bar" height={350} />
        </div>
      )}
    </>
  );
};

DataChart.propTypes = {
  filterBy: PropTypes.string.isRequired
};

export default DataChart;
