// import React from 'react'

// function ForeignWorkerDetailsCustomer() {
//   return (
//     <div>ForeignWorkerDetailsCustomer</div>
//   )
// }

// export default ForeignWorkerDetailsCustomer

import ReusableCard from 'components/cards/ResuableCard';
import React, { useEffect, useState } from 'react';
import { Grid, Button, TextField, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DropDown from 'components/dropdown/DropDown';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RiderSectionTable from 'modules/packages & premium/components/RiderSectionTable';
import ForeignWorkerAddWorkerModalCustomer from './ForeignWorkerAddWorkerModalCustomer';
import { useSelector } from 'react-redux';
import { store } from 'store/index';
import { removeOtherIndividualState } from 'store/reducers/premiumPolicy';

const ForeignWorkerDetailsCustomer = ({ handleNextButton, handlePreviousButton }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [getEditText, setEditText] = useState('');
  const [selectedRow, setSelectedRow] = useState({});
  const { workerSection } = useSelector((state) => state?.persistedReducer?.premiumPolicy.otherState);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setEditText('');
    setIsOpen(false);
  };

  function handleClickOnEdit(rider, action) {
    setIsOpen(true);
    setSelectedRow(rider);
    setEditText(action);
  }

  useEffect(() => {
    const rows = Object?.values(workerSection)?.map((worker, index) => ({
      sr: index + 1,
      id: worker?.id,
      name: worker?.firstName + ' ' + worker?.lastName,
      nationality: worker?.nationality,
      dob: worker?.dob,
      fin: worker?.fin,
      wp: worker?.workPermit,
      price: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              backgroundColor: '#4cb748',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '55px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium'
            }}
            onClick={() => handleClickOnEdit(worker, 'edit')}
          >
            <EditIcon style={{ fontSize: '15px', marginRight: '2.5px' }} />
            Edit
          </div>
          <div
            style={{
              backgroundColor: '#0174BF',
              color: '#fff',
              borderRadius: 20,
              fontSize: 12,
              cursor: 'pointer',
              width: '75px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium',
              marginLeft: '10px'
            }}
            onClick={() => handleDelete(worker.id)}
          >
            <DeleteOutlineOutlinedIcon style={{ fontSize: '15px' }} />
            Delete
          </div>
        </div>
      )
    }));
    setData(rows);
  }, [workerSection]);

  const handleDelete = (id) => {
    try {
      store.dispatch(removeOtherIndividualState(id));
    } catch (e) {
      console.error(e);

    }
  };

  return (
    <div>
      <ReusableCard
        backgroundColor={'#ffffff'}
        border={'1px solid #f2f2f2'}
        padding={'1%'}
        boxShadow={' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
        borderRadius={'15px'}
        width="100%"
        height="auto"
        marginTop={'2%'}
        content={
          <Grid container p={2}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontFamily: 'Medium', fontSize: '16px', fontWeight: '500' }}>Workers</span>
              <Button
                size="small"
                variant="contained"
                style={{ backgroundColor: '#00C7B1', width: 'auto', borderRadius: '5px', padding: '10px' }}
                onClick={handleOpen}
              >
                + Add Worker
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} xs={12} mt={2} mb={2}>
                <RiderSectionTable columns={ForeignAddWorkerTableColumns} rows={data} />
              </Grid>

              <Grid container spacing={2} xs={12} sm={12} md={12} lg={6} mt={2}>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#e4e4e4', width: '100%', padding: '3%', color: '#000000' }}
                    onClick={handlePreviousButton}
                  >
                    <IconButton style={{ marginRight: '5px' }}>
                      <WestIcon />
                    </IconButton>
                    Previous
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={3}>
                  <Button
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff' }}
                    onClick={handleNextButton}
                  >
                    Next
                    <IconButton style={{ marginLeft: '5px', color: '#ffffff' }}>
                      <EastIcon />
                    </IconButton>
                  </Button>
                </Grid>
              </Grid>

              {
                <ForeignWorkerAddWorkerModalCustomer
                  isOpen={isOpen}
                  onClose={handleClose}
                  getEditText={getEditText}
                  selectedRow={selectedRow}
                />
              }
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default ForeignWorkerDetailsCustomer;

const ForeignAddWorkerTableColumns = [
  { id: 'sr', label: 'Sr.', minWidth: 60 },
  { id: 'name', label: 'Name of Worker(s)', minWidth: 150 },
  { id: 'nationality', label: 'Nationality', minWidth: 150 },
  { id: 'dob', label: 'Date of Birth', minWidth: 150 },
  { id: 'fin', label: 'FIN', minWidth: 150 },
  { id: 'wp', label: 'Work Permit / S Pass No.', minWidth: 150 },
  { id: 'price', label: '', minWidth: 150 }
];

const data = [
  {
    sr: 1,
    name: 'Ravi Shankar',
    nationality: 'Indian',
    dob: '12-12-1990',
    fin: '122378',
    wp: '478838',
    price: (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            backgroundColor: '#4cb748',
            color: '#fff',
            borderRadius: 20,
            fontSize: 12,
            cursor: 'pointer',
            width: '55px',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '3px',
            fontFamily: 'Medium'
          }}
          //   onClick={() => handleClickOnEdit(worker, 'edit')}
        >
          <EditIcon style={{ fontSize: '15px' }} />
          Edit
        </div>
        <div
          style={{
            backgroundColor: '#0174BF',
            color: '#fff',
            borderRadius: 20,
            fontSize: 12,
            cursor: 'pointer',
            width: '75px',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '3px',
            fontFamily: 'Medium',
            marginLeft: '10px'
          }}
          //   onClick={() => handleDelete(worker.id)}
        >
          <DeleteOutlineOutlinedIcon style={{ fontSize: '15px' }} />
          Delete
        </div>
      </div>
    )
  }
];
