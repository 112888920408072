import useBodyScrollLock from 'hooks/useBodyScrollLock';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import '../modalcss/ResuableModal.css';

const ReusableModal = (props) => {
  const modalRef = useRef();
  const [, setIsLocked] = useBodyScrollLock();

  useEffect(() => {
    const handleCloseOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && props.isOpen) {
        if (!props.disableBackdropClick) {
          props.onClose();
        }
      }
    };

    document.addEventListener('mousedown', handleCloseOutside);

    if (props.isOpen) {
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }

    return () => {
      document.removeEventListener('mousedown', handleCloseOutside);
      setIsLocked(false);
    };
  }, [props.isOpen, props.onClose, props.disableBackdropClick, setIsLocked]);

  useEffect(() => {
    if (props.isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [props.isOpen]);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && props.onClose) {
      props.onClose();
    }
  };

  return (
    <>
      {props.isOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 3,
          }}
        >
          <div
            ref={modalRef}
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                handleClose();
              }
            }}
            role="dialog"
            aria-modal="true"
            tabIndex={-1}
          >
            {props.showCloseButton && (
              <div
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  borderRadius: 30,
                  width: 40,
                  height: 39,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: props.top,
                }}
                onClick={handleClose}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleClose();
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <div style={{ fontSize: 26, fontWeight: '700', color: '#949494' }}>&times;</div>
              </div>
            )}
            <div
              style={{
                backgroundColor: props.backgroundColor,
                padding: props.padding,
                borderRadius: props.borderRadius,
                width: props.width,
                height: props.height,
                maxWidth: props.maxWidth,
                margin: props.margin,
                border: props.border,
                maxHeight: props.maxHeight,
                boxShadow: props.boxShadow,
                marginTop: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div style={{ marginBottom: props.marginBottom }}>{props.ModalContent}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ReusableModal.defaultProps = {
  isOpen: false,
  showCloseButton: true,
  disableBackdropClick: true,
  height: '320px',
  width: '400px',
  maxWidth: '1500px',
  maxHeight: '1000px',
  border: '1px solid #ddd',
  backgroundColor: '#fff',
  borderRadius: '13px',
  ModalContent: <div>ModalContent</div>,
  top: '-10%',
};

ReusableModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  top: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  border: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  ModalContent: PropTypes.node,
  padding: PropTypes.string,
  margin: PropTypes.string,
  marginBottom: PropTypes.string,
  boxShadow: PropTypes.string,
};

export default ReusableModal;
