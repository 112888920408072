import SubHeader from 'components/subHeader/SubHeader';
import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { useTheme } from 'components/Theme/ThemeProvider';
import IncorporateFinanceTable from '../components/IncorporateFinanceTable';
import InputLabel from '@mui/material/InputLabel';
const ActiveComponents = () => {
  const { currentTheme } = useTheme();

  return (
    <Grid className="css-1gnspwu-MuiGrid-root">
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2.75}
        alignItems="center"
        justifyContent="space-between"
        style={{ backgroundColor: currentTheme.cardColor }}
      >
        <SubHeader title="Finance" />
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'flex-end',alignItems:'flex-end' }}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>Start Date</InputLabel>
                  <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
                </div>
                <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
                  // value={dob}
                  // onChange={(e) => handleDOBChange(e.target.value)}
                  required
                  onClick={(e) => e.stopPropagation()}
                  inputProps={{
                    max: new Date().toISOString().split('T')[0]
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px' }}>End Date</InputLabel>
                  <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
                </div>
                <TextField
                  type="date"
                  size="small"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  style={{ backgroundColor: '#F8F8F8', width: '90%', margin: '2px', padding: '5px', borderRadius: '8px' }}
                  // value={dob}
                  // onChange={(e) => handleDOBChange(e.target.value)}
                  required
                  onClick={(e) => e.stopPropagation()}
                  inputProps={{
                    max: new Date().toISOString().split('T')[0]
                  }}
                />
              </Grid>
            </div>
          </div>
        </Grid> */}

        <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />
        <IncorporateFinanceTable />
      </Grid>
    </Grid>
  );
};

const IncorporateFinance = () => {
  return <ActiveComponents />;
};

export default IncorporateFinance;
