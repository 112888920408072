import React from 'react';
import { Grid, Typography } from '@mui/material';
import { HorizontalContainer } from '../corporate/components/HorizontalContainer';
import { useNavigate } from 'react-router-dom';

export default function CalculateForeignContainer({ data }) {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="center" alignItems="center" mt={2}>
      <Grid item xs={11.5} md={9} lg={9}>
        <Grid
          container
          sx={{
            width: '100%',
            backgroundColor: '#F2F9FF',
            borderRadius: 5,
            padding: '10px',
            maxHeight: '396px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '10px' // Change the width of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#002D8C', // Change the color of the scrollbar thumb
              borderRadius: '10px' // Add some rounding to the thumb
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#F2F9FF' // Change the color of the scrollbar track
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#8e8e8e' // Darker shade on hover
            }
          }}
        >
          <Typography variant="h5" style={{ fontFamily: 'Bold', fontSize: 20, padding: 5 }}>
            Get your quote
          </Typography>
          {data.map((data, index) => {
            let obj = { Plan: data.productName, Price: data.grossPremium };
            return (
              <HorizontalContainer
                key={index}
                data={obj}
                sizes={[4, 4, 4]}
                onClick={() => {
                  navigate('/ForeignWorkerPolicyCoverages');
                }}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
