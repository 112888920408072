import React from 'react';
import Typography from '@mui/material/Typography';
import Anda_Insurance from '../../../../../assets/images/product_plan_image/anda_insurance.png';

const AboutAndaInsurance = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '50vh',
        padding: '16px'
      }}
    >
      <Typography sx={{ color: '#000', fontSize: '18px', fontFamily: 'Medium', mb: 5 }}>About ANDA Insurance</Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          marginBottom: '10px'
        }}
      >
        <img
          src={Anda_Insurance}
          alt=""
          style={{
            width: '28%',
            height: 'auto',
            marginRight: '20px',
            borderRadius: '10px',
            marginBottom: '1%'
          }}
        />
        <div style={{ width: '37%' }}>
          <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium', width: '100%', mb: 1 }}>
            ANDA is a leading insurance intermediary who has been delighting clients with unparalleled level of service for three
            decades.Leveraging on its strong and established relationships with industry partners,ANDA has built its reputation on providing
            a wide array of insurance solutions at competitive prices.
          </Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium', width: '100%', mb: 1, mt: 1 }}>
            We deliver value in creating bespoke insurance solutions that provide the necessary coverage for clients' businesses to operate
            optimally. We ensure you have the cover you really need.
          </Typography>
          <span style={{ fontSize: '12px', fontWeight: '700', fontFamily: 'Medium', width: '100%' }}>Why ANDA Insurance</span>
          <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: '500', fontFamily: 'Medium', width: '100%', mt: 1, mb: 2 }}>
            With more than 30 years of experience in securing our customers' insurance needs, we are committed to providing you with
            unparalled service!
          </Typography>
          <ul style={{ paddingLeft: '15px', fontSize: '12px', fontWeight: '500', fontFamily: 'Medium', width: '100%' }}>
            <li>Competitive Premiums</li>
            <li>Get your Policy Documents issued instantly</li>
            <li>Assured and Dedicated Service</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutAndaInsurance;
