import React from 'react';
import { Grid, Button, TextField, IconButton } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import ReusableCard from 'components/cards/ResuableCard';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DropDown from 'components/dropdown/DropDown';
import { addOtherIndividualState } from 'store/reducers/premiumPolicy';
import { store } from 'store/index';
import { useSelector } from 'react-redux';
const CommonDropdown = ({ item, onValueChange, value }) => {
  return (
    <DropDown
      width={'96%'}
      marginTop={'10px'}
      heightsss={'37px'}
      height={'40px'}
      backgroundColor="#F8FAFB"
      options={item?.ddoptions || []}
      onValueChange={(selectedItem) => onValueChange(item.value, selectedItem.label)}
      value={value}
    />
  );
};
const ContainerContent = ({ handleNextButton, handlePreviousButton }) => {
  const formData = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const dropdownOptions = {
    bikeMake: [
      { label: 'Male', id: 1 },
      { label: 'Female', id: 2 }
    ],
    bikeModal: [
      { label: 'Single', id: 1 },
      { label: 'Married', id: 2 },
      { label: 'Widow/Divorced', id: 3 }
    ],
    bikeRegistered: [
      { label: 'Accountant', id: 1 },
      { label: 'Profession 2', id: 2 }
    ]
  };
  const handleInputChange = (field, value) => {
    store.dispatch(addOtherIndividualState([field, value]));
    // setFormData((prevState) => ({
    //   ...prevState,
    //   [field]: value
    // }));
  };

  const dropdownItems = [
    { label: 'What is your bike make?', value: 'bikeMake', ddoptions: dropdownOptions.bikeMake },
    { label: 'What is your bike model?', value: 'bikeModal', ddoptions: dropdownOptions.bikeModal },
    { label: 'Which year was your bike registered?', value: 'bikeRegistered', ddoptions: dropdownOptions.bikeRegistered }
  ];

  return (
    <Grid container rowSpacing={1} columnSpacing={2} xs={12} alignItems={'center'}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {dropdownItems.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <InputLabel style={{ fontFamily: 'Thin', fontSize: '14px', marginLeft: '2%', color: '#B8B9BF', fontWeight: 600 }}>
                {item.label}
              </InputLabel>
              <CommonDropdown item={item} state={formData} onValueChange={handleInputChange} />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={8} sm={6} md={6} lg={2.5}>
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#f7f7f7',
                width: '100%',
                padding: '3%',
                color: '#000000',
                height: '100%',
                border: '1px solid #ddd'
              }}
              onClick={handlePreviousButton}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={8} sm={6} md={6} lg={2.5}>
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#002D8C', width: '100%', padding: '3%', color: '#ffffff', height: '100%' }}
              onClick={handleNextButton}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MotorVehicleDetails = ({ handleNextButton, handlePreviousButton }) => {
  return (
    <ReusableCard
      backgroundColor={'#fff'}
      border="1px solid #ddd"
      padding={'3%'}
      borderRadius={15}
      content={<ContainerContent handleNextButton={handleNextButton} handlePreviousButton={handlePreviousButton} />}
    />
  );
};

export default MotorVehicleDetails;
