const currentDate1 = new Date().toISOString().split('T')[0];
const currentDate = new Date();
const oneYearLaterDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate());
const formattedDate = oneYearLaterDate.toISOString().split('T')[0];

const MotarPackageData = [
  { label: 'Product name', value: 'productName', isEnable: true, type: 'textField' }
  // { label: 'Product Description', value: 'productDescription', isEnable: true, type: 'textField' },
  // { label: 'Product Code', value: 'productID', isEnable: true, type: 'textField' },
  // { label: 'Policy Class', value: 'policyClass', isEnable: true, type: 'textField' },
  // { label: 'Insurer', value: 'insurer', isEnable: true, type: 'textField' }
];

const MotorPolicyDetails = [
  // { label: 'UEN', value: 'UEM', type: 'textField', texttype: 'text', isFlag: true },
  // { label: 'Company Name', value: 'cm', type: 'textField', texttype: 'text', isFlag: false, astric: true, isRequired: true },
  // { label: 'Email', value: 'email', type: 'textField', texttype: 'text', isFlag: false, astric: true, isRequired: true },
  // { label: 'Phone', value: 'phone', type: 'textField', texttype: 'text', isFlag: false, astric: true, isRequired: true },
  {
    label: 'Period of Insurance (Start Date)',
    value: 'POIStartDate',
    type: 'textField',
    isRequired: true,
    texttype: 'date',
    isFlag: true,
    astric: true
  },
  {
    label: 'Period of Insurance (End Date)',
    value: 'POIEndDate',
    type: 'textField',
    isRequired: true,
    texttype: 'date',
    isFlag: true,
    // astric: true,
    isEnable: true
  },
  {
    label: 'Company Registered Motorcycle',
    value: 'isCompanyRegistered',
    type: 'dropdown',
    isFlag: true,
    defaultValue: false,
    ddoptions: [
      { label: 'Yes', id: true, value: 'isCompanyRegistered' },
      { label: 'No', id: false, value: 'isCompanyRegistered' }
    ]
  },
  {
    label: 'Food and Parcel Delivery Extension',
    value: 'isFoodParcel',
    type: 'dropdown',
    isFlag: true,
    defaultValue: false,
    ddoptions: [
      { label: 'Yes', id: true, value: 'isFoodParcel' },
      { label: 'No', id: false, value: 'isFoodParcel' }
    ]
  }
];

const MotorVehiclesDetails = [
  {
    id: 1,
    label: 'Vehicle List',
    value: 'vehicleId',
    type: 'autocomplete',
    isRequired: true,
    ddoptions: [],
    astric: true,
    widthVehcle: '40%'
  },
  // { id: 2, label: 'Vehicle Capacity', value: 'vehicleCapacity', type: 'dropdown', isRequired: true, ddoptions: [], astric: true },
  { id: 3, label: 'Age of MotorCycle', value: 'ageOfVehicle', type: 'dropdown', isRequired: true, ddoptions: [], astric: true },
  {
    id: 4,
    label: 'Vehicle Registration Number',
    value: 'vehicleRegistration',
    type: 'textField',
    isRequired: true,
    astric: true,
    widthVehcle: '92%'
  },
  { id: 5, label: 'Chassis Number', value: 'chasisNumber', type: 'textField' },
  { id: 6, label: 'Engine Number', value: 'engineNumber', type: 'textField' },
  { id: 7, label: 'Name of Hire Purchase', value: 'nameOfHirePurchase', type: 'textField' },
  { id: 8, label: 'Workshop Type', value: 'workShopType', type: 'dropdown', isRequired: true, ddoptions: [] },
  { id: 9, label: 'No Claim Discount (NCD)', value: 'NCD', type: 'dropdown', ddoptions: [] },
  {
    id: 10,
    label: 'Claims Count',
    value: 'claimcount',
    type: 'dropdown',
    ddoptions: [
      { label: '0', id: 0, value: 'claimcount' },
      { label: '1', id: 1, value: 'claimcount' },
      { label: '2 or more', id: 2, value: 'claimcount' }
    ],
    astric: true
  },
  { id: 10, label: 'Claims amount', value: 'claimamount', type: 'textField', astric: true },

  // { id: 11, label: 'Vehicle Tonnage', value: 'vehicleTonnage', type: 'textField' },
  { id: 12, label: 'Registration/Manufacture Date', value: 'RegistrationDate', type: 'textField', texttype: 'date' }
  // {
  //   label: 'NCD Protector',
  //   value: 'NCDPROTECTOR',
  //   // defaultValue:'Yes',
  //   type: 'dropdown',
  //   // astric: true,
  //   ddoptions: [
  //     { label: 'Yes', id: 'Yes', value: 'NCDPROTECTOR' },
  //     { label: 'No', id: 'No', value: 'NCDPROTECTOR' }
  //   ]
  // }
];

const MotorAddRiderTableColumns = [
  { id: 'sr', label: 'Sr.', minWidth: 50, align: 'center' },
  { id: 'firstName', label: 'First Name', minWidth: 50, align: 'center' },
  { id: 'lastName', label: 'Last Name', minWidth: 50, align: 'center' },
  { id: 'nric', label: 'NRIC/FIN No.', minWidth: 50, align: 'center' },
  { id: 'age', label: 'Age', minWidth: 50, align: 'center' },
  { id: 'gender', label: 'Gender', minWidth: 50, align: 'center' },
  // { id: 'experience', label: 'Experience', minWidth: 100, align: 'center' },
  { id: 'nationality', label: 'Nationality', minWidth: 50, align: 'center' },
  { id: 'contactNumber', label: 'Contact No.', minWidth: 50, align: 'center' },
  { id: 'email', label: 'Email', minWidth: 110, align: 'center' },
  { id: 'Action', label: 'Action' }
];
const DomesticEmployerData = [
  { label: 'Name', value: 'name', type: 'textField', astric: true },
  {
    label: 'Gender',
    value: 'gen',
    type: 'dropdown',
    ddoptions: [
      { label: 'Male', id: 'Male', value: 'Male' },
      { label: 'Female', id: 'Female', value: 'Female' }
    ]
  },
  { label: 'Nationality', value: 'nationality', type: 'dropdown', ddoptions: [] },
  { label: 'NRIC No/FIN', value: 'nric', type: 'textField', astric: true },
  { label: 'Date of Birth', value: 'dob', type: 'textField', texttype: 'date' },
  { label: 'SB Transamission Ref No', value: 'strn', type: 'textField' },
  { label: 'Phone', value: 'phone', type: 'textField' },
  { label: 'Email', value: 'email', type: 'textField', astric: true },
  { label: 'Address', value: 'address', type: 'textField' }
];
const DomesticWorkerInformationData = [
  { label: 'First Name', value: 'ndw', type: 'textField', astric: true },
  { label: 'Last Name', value: 'lastname', type: 'textField', astric: true },
  { label: 'FIN', value: 'fin', type: 'textField', astric: true },
  { label: 'Passport No.', value: 'passportno', type: 'textField', astric: true },
  { label: 'DOB', value: 'dob', type: 'textField', texttype: 'date', astric: true },
  { label: 'Work Permit No.', value: 'workpermit', type: 'textField', astric: true },
  { label: 'Nationality', value: 'nationalityDHInformation', type: 'dropdown', ddoptions: [], astric: true }
];
const DomesticHelperInsuranceData = [
  { label: 'UEN', value: 'UENDHInsurance', type: 'textField', texttype: 'text', isFlag: true },
  {
    label: 'Company Name',
    value: 'compnaynameDHInsurance',
    type: 'textField',
    texttype: 'text',
    isFlag: false,
    astric: true,
    isRequired: true
  },
  { label: 'Email', value: 'emailDHInsurance', type: 'textField', texttype: 'text', isFlag: false, astric: true, isRequired: true },
  { label: 'Phone', value: 'phoneDHInsurance', type: 'textField', texttype: 'text', isFlag: false, isRequired: true },
  { label: 'Product Name', value: 'productname', type: 'textField', isEnable: true, isFlag: true },
  { label: 'From date', value: 'fromdateDHInsurance', type: 'textField', texttype: 'date', isFlag: true, astric: true },
  // { label: 'Duration In Months', value: 'durationInMonths', type: 'dropdown', ddoptions: [], isFlag: true },
  // { label: 'Sub Category', value: 'subcategory', type: 'dropdown', ddoptions: [], isFlag: true },
  { label: 'To date', value: 'todateDHinsurance', type: 'textField', texttype: 'date', isEnable: true, isFlag: true }
];

const ForeignWorkerPackageData = [
  { label: 'Basis of Cover', value: 'basisofcover', isEnable: false, type: 'textField', isFlag: true, canAcceptSpecialChar: false },
  {
    label: 'Name of Main Contractor',
    value: 'maincontractor',
    isEnable: false,
    type: 'textField',
    isFlag: true,
    canAcceptSpecialChar: false
  },
  { label: 'UEN Number', value: 'uen', type: 'textField', isFlag: true, canAcceptSpecialChar: false },
  {
    label: 'Company Name',
    value: 'cm',
    type: 'textField',
    texttype: 'text',
    isFlag: false,
    astric: true,
    isRequired: true,
    canAcceptSpecialChar: false
  },
  {
    label: 'Email',
    value: 'email',
    type: 'textField',
    texttype: 'text',
    isFlag: false,
    astric: true,
    isRequired: true,
    canAcceptSpecialChar: true
  },
  { label: 'Phone', value: 'phone', type: 'textField', texttype: 'text', isFlag: false, isRequired: true, canAcceptSpecialChar: false },
  { label: 'CPF Submission No.', value: 'CPF', isEnable: false, type: 'textField', isFlag: true, canAcceptSpecialChar: false },
  {
    label: 'Discharge Date',
    value: 'dischargeDate',
    isEnable: false,
    type: 'textField',
    texttype: 'date',
    isFlag: true,
    canAcceptSpecialChar: false
  },
  // { label: 'Transmission Status', value: 'transmissionstatus', isEnable: false, type: 'textField',isFlag: true },
  // { label: 'Bond Call Status', value: 'bondcall', isEnable: false, type: 'textField',isFlag: true },
  // { label: 'Bond Duration', value: 'bondduration', isEnable: false, type: 'textField',isFlag: true },
  // { label: 'LOI Status', value: 'LOIstatus', isEnable: false, type: 'textField',isFlag: true },
  // { label: 'LOI Type', value: 'LOItype', isEnable: false, type: 'textField',isFlag: true },
  {
    label: 'From date',
    value: 'fromDate',
    isEnable: false,
    type: 'textField',
    texttype: 'date',
    isRequired: true,
    isFlag: true,
    canAcceptSpecialChar: false
  },
  // { label: 'Duration In Months', value: 'durationInMonths', type: 'dropdown', ddoptions: [], isFlag: true, canAcceptSpecialChar: false },
  // { label: 'Sub Category', value: 'subcategory', type: 'dropdown', ddoptions: [], isFlag: true, canAcceptSpecialChar: false },
  {
    label: 'To date',
    value: 'todate',
    isEnable: true,
    type: 'textField',
    texttype: 'date',
    isRequired: true,
    isFlag: true,
    canAcceptSpecialChar: false
  }
];

const ForeignAddWorkerTableColumns = [
  { id: 'sr', label: 'Sr.', minWidth: 10 },
  { id: 'firstName', label: 'First Name', minWidth: 82 },
  { id: 'lastName', label: 'Last Name', minWidth: 82 },
  { id: 'workPermit', label: 'Work Permit No', minWidth: 114 },
  { id: 'age', label: 'Age', minWidth: 40 },
  { id: 'nationality', label: 'Nationality', minWidth: 90 },
  { id: 'nric', label: 'NRIC / FIN No.', minWidth: 107 },
  { id: 'gender', label: 'Gender', minWidth: 60 },
  { id: 'contactNumber', label: 'Contact Number', minWidth: 120 },
  { id: 'email', label: 'Email', minWidth: 125 },
  { id: 'passport', label: 'Passport Number', minWidth: 65 },
  { id: 'Action', label: 'Action' }
];

const DomesticCustomerEmployeeInformation = [
  { label: 'Name as per NRIC', value: 'dhcustomernamenric', type: 'textField', astric: true },
  { label: 'Gender', value: 'dhcustomergender', type: 'textField', astric: true },
  { label: 'Date of Birth', value: 'dhcustomerdob', type: 'textField', astric: true },
  { label: 'Address 1', value: 'dhcustomeraddress1', type: 'textField', astric: true },
  { label: 'Address 2', value: 'dhcustomeraddress2', type: 'textField', astric: true },
  { label: 'Postal Code', value: 'dhcustomerpostal', type: 'textField', astric: true },
  { label: 'Security Bond Transmission Ref No.', value: 'dhcustomersecurity', type: 'textField', astric: true },
  { label: 'NRIC No./FIN', value: 'dhcustomernric', type: 'textField', astric: true },
  { label: 'Mobile No.', value: 'dhcustomermobile', type: 'textField', astric: true },
  { label: 'Email Address', value: 'dhcustomeremail', type: 'textField', astric: true },
  { label: 'Tel No.(Home)', value: 'dhcustomertelno', type: 'textField', astric: true },
  { label: 'Name', value: 'dhcustomername', type: 'textField', astric: true },
  { label: 'Passport No.', value: 'dhcustomerpassport', type: 'textField', astric: true },
  { label: 'Date of Birth', value: 'dhcustomerdobdetails', type: 'textField', astric: true },
  { label: 'Nationality', value: 'dhcustomernationality', type: 'textField', astric: true },
  { label: 'Work Permit No.', value: 'dhcustomerworkpermit', type: 'textField', astric: true },
  { label: 'FIN', value: 'dhcustomerfin', type: 'textField', astric: true }
];
const DomesticCustomerAddons = [
  {
    title: 'A. Policy Type',
    label: 'Policy Type',
    value: 'policytype',
    type: 'dropdown',
    ddoptions: [
      { label: 'Individual', id: 'Individual', value: 'policytype' },
      { label: 'Company', id: 'Company', value: 'policytype' }
    ],
    astric: true
  },

  {
    label: 'Expected Policy Duration',
    value: 'fromDate',
    isEnable: false,
    type: 'textField',
    texttype: 'date',
    isRequired: true,
    isFlag: true,
    canAcceptSpecialChar: false
  },
  {
    value: 'todate',
    isEnable: true,
    type: 'textField',
    texttype: 'date',
    isRequired: true,
    isFlag: true,
    canAcceptSpecialChar: false
  }
];

const CustomerMotorVehiclePersonalDetailsData = [
  {
    label: 'Gender',
    value: 'gen',
    type: 'dropdown',
    ddoptions: [
      { label: 'Male', id: 'Male', value: 'Male' },
      { label: 'Female', id: 'Female', value: 'Female' }
    ]
  },
  {
    label: 'Martial',
    value: 'Martial',
    type: 'dropdown',
    ddoptions: [
      { label: 'Single', id: 'Single', value: 'Single' },
      { label: 'Married', id: 'Married', value: 'Married' },
      { label: 'Widow/Divorced', id: 'Widow/Divorced', value: 'Widow/Divorced' }
    ]
  }
];

export {
  MotarPackageData,
  MotorPolicyDetails,
  MotorVehiclesDetails,
  MotorAddRiderTableColumns,
  currentDate1,
  formattedDate,
  ForeignWorkerPackageData,
  ForeignAddWorkerTableColumns,
  DomesticHelperInsuranceData,
  DomesticWorkerInformationData,
  DomesticEmployerData,
  DomesticCustomerEmployeeInformation,
  DomesticCustomerAddons,
  CustomerMotorVehiclePersonalDetailsData
};
